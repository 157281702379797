import React from "react";

interface PageHeaderProps {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  mobileTitle: React.ReactNode | string;
  buttons?: React.ReactNode;
  wrap?: boolean;
  children?: React.ReactNode;
  verticalStack?: boolean;
  variant?: "default" | "secondary";
  className?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  mobileTitle,
  subtitle = "",
  buttons,
  wrap = true,
  children,
  verticalStack = false,
  variant = "default",
  className,
}) => {
  return (
    <div
      className={`sticky top-0 z-10 flex ${verticalStack ? "flex-col" : ""} ${
        variant === "secondary" ? "bg-secondary-1200" : "bg-primary"
      } justify-between px-5 py-3 gap-4 border-b border-secondary-1000  ${className}`}
    >
      <div className="flex flex-col my-auto w-full sm:w-auto ">
        <span className="hidden sm:block text-xl font-medium text-secondary-100 ">
          {title}
        </span>
        <span className="block sm:hidden text-xl font-medium text-secondary-100 ">
          {mobileTitle}
        </span>

        {subtitle && subtitle.toString().trim() !== "" && (
          <span className="text-sm font-light text-secondary-100 ">
            {subtitle}
          </span>
        )}
      </div>
      {buttons && (
        <div
          className={`hidden sm:flex py-2 h-full sm:py-0 sm:justify-end justify-between items-center gap-2  w-full sm:w-fit ${
            wrap ? "flex-wrap" : ""
          } `}
        >
          {buttons}
        </div>
      )}
      {children}
    </div>
  );
};

export default PageHeader;
