import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Warning } from "@mui/icons-material";
import { classNames } from "../../utils/FormatFunctions";

interface GenericModalProps {
  title: string;
  item: any;
  handleFunction: (item: any) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  color?: string;
  secondaryColor?: string;
  buttonText?: string;
  ModalIcon?: React.ElementType;
  warningText?: string;
}

const GenericModal: React.FC<GenericModalProps> = ({
  title,
  item,
  handleFunction,
  showModal,
  setShowModal,
  color = "reds-500",
  secondaryColor = "reds-100",
  buttonText = " Delete",
  ModalIcon = Warning,
  warningText,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setShowModal(false)}
      >
        <Transition.Child
          as={Fragment}

          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-500/15 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-primary text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div className="bg-primary px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={classNames(
                        `mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${secondaryColor} sm:mx-0 sm:h-10 sm:w-10`
                      )}
                    >
                      <ModalIcon
                        className={`h-6 w-6 text-${color}`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-secondary-100"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-secondary-500">
                          Are you sure you want to {buttonText?.toLowerCase()}{item?.title}? {warningText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-secondary-1100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={classNames(
                      `inline-flex w-full justify-center rounded-lg bg-${color} px-3 py-2 text-sm font-semibold text-primary shadow-sm hover:bg-${color} sm:ml-2 sm:w-auto`
                    )}
                    onClick={() => handleFunction(item)}
                  >

                    {buttonText}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-lg bg-primary px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default GenericModal;
