import React from "react";
import {
  DeleteOutline,
  EditOutlined,
  MapOutlined,
  SkipNextOutlined,
  UndoOutlined,
} from "@mui/icons-material";
import { Tooltip } from "react-tooltip";
import { Map } from "../../types/Map";
import { capitalizeString } from "../../utils/FormatFunctions";
import { useBuilding } from "../../hooks/useBuilding";
import {
  ArrowRightIcon,
  ForwardIcon,
  InformationCircleIcon,
  MapIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

interface MapListProps {}

const MapList: React.FC<MapListProps> = ({}) => {
  const { allMaps, maps, setSelectedMap, handleShowMap, updateShowState } =
    useBuilding();
  const { isMobile } = useDeviceWidth();

  const ActionButtons = ({ map }: { map: Map }) => {
    if (map.required === "true") {
      return (
        <>
          <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-reds-500  ring-1 ring-inset ring-reds-500">
            {capitalizeString("Missing")}
          </span>

          {isMobile ? (
            <Menu as="div" className="relative">
              <Menu.Button className="bg-secondary-1100 border border-secondary-900 p-2 rounded-lg flex justify-center items-center text-secondary-100">
                <EllipsisVerticalIcon className="h-5 w-5" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-lg bg-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-secondary-1100" : ""
                        } flex w-full items-center px-4 py-2 text-sm text-secondary-100`}
                        onClick={() => handleShowMap(map)}
                      >
                        <PencilSquareIcon className="h-4 w-4 mr-2" />
                        Create
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-secondary-1100" : ""
                        } flex w-full items-center px-4 py-2 text-sm text-secondary-400`}
                        onClick={() => {
                          setSelectedMap(map);
                          updateShowState("showSkipTemplate", true);
                        }}
                      >
                        <ForwardIcon className="h-4 w-4 mr-2" />
                        Skip
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <>
              <button
                className="bg-secondary-1100 border border-secondary-900 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100  hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                onClick={() => handleShowMap(map)}
              >
                <PencilSquareIcon className="h-4 w-4" />
                Edit
              </button>
              <button
                className="bg-secondary-1100 border border-secondary-900 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-400 hover:bg-secondary-1200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                onClick={() => {
                  setSelectedMap(map);
                  updateShowState("showSkipTemplate", true);
                }}
              >
                Skip
                <ForwardIcon className="h-4 w-4" />
              </button>
            </>
          )}
        </>
      );
    }

    return (
      <>
        {isMobile ? (
          <Menu as="div" className="relative">
            <Menu.Button className="bg-secondary-1100 border border-secondary-900 p-2 rounded-lg flex justify-center items-center text-secondary-100">
              <EllipsisVerticalIcon className="h-5 w-5" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-lg bg-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-secondary-1100" : ""
                      } flex w-full items-center px-4 py-2 text-sm text-secondary-100`}
                      onClick={() => handleShowMap(map)}
                    >
                      <PencilSquareIcon className="h-4 w-4 mr-2" />
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-secondary-1100" : ""
                      } flex w-full items-center px-4 py-2 text-sm text-reds-500`}
                      onClick={() => {
                        setSelectedMap(map);
                        updateShowState("showDelete", true);
                      }}
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <>
            <button
              className="bg-secondary-1100 border border-secondary-900 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100  hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
              onClick={() => handleShowMap(map)}
            >
              <PencilSquareIcon className="h-4 w-4" />
              Edit
            </button>
            <button
              className="bg-secondary-1100 text-reds-500 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
              onClick={() => {
                setSelectedMap(map);
                updateShowState("showDelete", true);
              }}
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-y-2">
        <MapIcon className="h-8 w-8 text-secondary-100" />
        <h2 className="text-xl font-medium text-secondary-100">Maps</h2>
        <p className="text-sm text-secondary-500 font-light">
          View and manage your buildings assets
        </p>
      </div>
      <ul role="list" className=" gap-y-2 ">
        {allMaps.length > 0 ? (
          allMaps.map((map: Map) => (
            <li
              key={map._id}
              className="flex mt-2 items-center justify-between rounded-lg border border-secondary-900 px-3 py-4 bg-primary"
            >
              {/* Info section */}
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-medium text-secondary-100">
                    {capitalizeString(map.name)}
                  </p>
                  {map.description && (
                    <div data-tooltip-id={`map-tooltip-${map._id}`}>
                      <InformationCircleIcon className="h-4 w-4 text-secondary-500" />
                      <Tooltip
                        id={`map-tooltip-${map._id}`}
                        place="top"
                        className="max-w-xs !bg-secondary-1100 !text-secondary-100 !opacity-100 shadow-xl"
                        style={{
                          borderRadius: "0.375rem",
                          padding: "0.5rem",
                        }}
                      >
                        {map.description}
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              {/* Action Section */}
              <div className="flex flex-none items-center gap-x-4">
                <ActionButtons map={map} />
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No maps present
            </h2>
          </div>
        )}
      </ul>
    </div>
  );
};

export default MapList;
