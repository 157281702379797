import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close, SearchOffOutlined } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { formatDate } from "../../utils/FormatFunctions";
import { TaskInstance } from "../../types/TaskInstance";
import { useLog } from "../../hooks/useLog";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import { DocumentIcon } from "@heroicons/react/24/outline";
interface ViewLogProps {}

const ViewLog: React.FC<ViewLogProps> = ({}) => {
  const { handleViewInstance, selectedLog } = useLog();
  const { isMobile } = useDeviceWidth();

  const logEntries = selectedLog?.logEntries;

  return (
    <div className="min-h-32">
      {logEntries && logEntries.length > 0 ? (
        <table className="w-full">
          {!isMobile && (
            <thead className="bg-secondary-1200">
              <tr>
                <th colSpan={5} className="p-0">
                  <div className="flex border border-secondary-1000 rounded-lg overflow-hidden bg-secondary-1200">
                    <div className="w-[140px] py-4 px-3 text-left text-sm font-medium text-secondary-100 cursor-pointer">
                      Due Date
                    </div>
                    <div className="hidden lg:block w-[160px] py-4 px-3 text-left text-sm font-medium text-secondary-100 cursor-pointer">
                      Date Completed
                    </div>
                    <div className="hidden lg:block w-[140px] py-4 px-3 text-left text-sm font-medium text-secondary-100 cursor-pointer">
                      Date Closed
                    </div>
                    <div className="hidden lg:block w-[100px] py-4 px-3 text-left text-sm font-medium text-secondary-100 cursor-pointer">
                      Status
                    </div>
                    <div className="w-[100px] py-4 px-3 text-left text-sm font-medium text-secondary-100 cursor-pointer">
                      View
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          )}
          <tbody className="overflow-y-auto">
            {logEntries.map((logEntry: TaskInstance) => (
              <Fragment key={logEntry._id}>
                <tr key={`${logEntry._id}-gap`} className="h-2"></tr>
                <tr key={`${logEntry._id}-title`}>
                  <td colSpan={5}>
                    <div className="grid grid-cols-3 px-3 py-4 gap-y-4  gap-x-2 sm:gap-x-0 sm:flex w-full border border-secondary-1000 rounded-lg bg-primary">
                      <label className="sm:hidden text-sm  sm:font-medium font-light text-secondary-300">
                        Due Date
                      </label>
                      <div className="sm:w-[140px]  text-sm  text-secondary-100">
                        {formatDate(logEntry.date, "MMM Do YYYY", "utc")}
                      </div>
                      <div className="  flex sm:hidden w-full text-sm text-secondary-500 justify-end h-fit">
                        {<StatusBadge status={logEntry.status} />}
                      </div>
                      <label className="sm:hidden text-sm font-light text-secondary-300">
                        Date Completed
                      </label>
                      <div className=" sm:w-[160px]  text-sm text-secondary-100">
                        {formatDate(logEntry.dateCompleted, "MMM Do YYYY")}
                      </div>
                      <div />
                      <label className="sm:hidden text-sm   font-light text-secondary-300">
                        Date Closed
                      </label>
                      <div className=" sm:w-[140px]   text-sm text-secondary-100">
                        {formatDate(logEntry.dateClosed, "MMM Do YYYY")}
                      </div>

                      <div className="hidden lg:block w-[100px]   text-sm text-secondary-500">
                        {<StatusBadge status={logEntry.status} />}
                      </div>
                      <div className="flex justify-end items-center gap-2  text-sm">
                        <DocumentIcon className="w-4 h-4 text-accent-300" />
                        <button
                          className=" text-accent-300 hover:text-accent-400"
                          onClick={() => handleViewInstance(logEntry._id)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex items-center w-full justify-center text-secondary-100 text-center bg-secondary-1100 rounded-lg">
          <div className="flex flex-col w-full justify-center items-center  text-secondary-500 py-8 ">
            <SearchOffOutlined style={{ fontSize: "2rem" }} />
            <span className="text-sm">No logs completed yet</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLog;
