import React from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import ViewLog from "../../updatedcomponents/modals/ViewLog";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useLog } from "../../hooks/useLog";

const LogSelect: React.FC = () => {

  const { selectedLog, setSelectedLog } = useLog();


  return (
    <div className="flex flex-col h-full bg-secondary-1200">
      <PageHeader
        title={selectedLog?.title || "View Log"}
        mobileTitle={
            <button
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={() => setSelectedLog(null)}
          >
            <ChevronLeftIcon className="text-inherit h-4 w-4" />
            {selectedLog?.title}
          </button>
        }
      />
      <div className="flex-1 overflow-auto px-2 scrollbar-thin">
        <ViewLog />
      </div>
    </div>
  );
};

export default LogSelect; 