import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Logout,
  People,
  Business,
  Person,
  Close,
  Schedule,
  QueryStats,
  PieChartOutline,
  CalendarMonthOutlined,
  TaskOutlined,
  HomeRepairServiceOutlined,
  SummarizeOutlined,
  ManageAccountsOutlined,
  PeopleOutline,
  PlumbingOutlined,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { FacilityContext } from "../../context/FacilityContext";
import { useAuth } from "../../hooks/useAuth";
import { classNames } from "../../utils/FormatFunctions";
import Toast from "../modals/Toast";
import { CustomSelector } from "./CustomSelector";
import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  BuildingOfficeIcon,
  ChevronRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { BuildingOfficeIcon as BuildingOfficeIconSolid } from "@heroicons/react/24/solid";
import SliderContainer from "./SliderContainer";
import SearchInput from "./SearchBar";

type NavigationComponent = {
  name: string;
  href: string;
  icon: React.ElementType;
  access: string[];
  current?: boolean;
  children?: NavigationComponent[];
};

const ApplicationShell: React.FC = () => {
  const { currentUser, dispatch } = useAuth();

  const navigate = useNavigate();

  const { selectedFacility, handleFacilityChange } =
    useContext(FacilityContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedFacility");
    localStorage.removeItem("work-order-filters");

    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const facilityOptions = currentUser.facility.map((facility) => ({
    id: facility._id,
    name: facility.name,
    icon: BuildingOfficeIcon,
    bgColor: "bg-secondary-1200",
    textColor: "text-secondary-300",
  }));

  const [nestedButton, setNestedButton] = useState<null | string>(null);
  const [showSlider, setShowSlider] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFacilities, setFilteredFacilities] = useState(facilityOptions);

  useEffect(() => {
    const filtered = facilityOptions.filter((facility) =>
      facility.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFacilities(filtered);
  }, [searchTerm]);

  const navigation: NavigationComponent[] = [
    {
      name: "Dashboard",
      href: "/home",
      icon: PieChartOutline,
      current: false,
      access: [
        "administrator",
        "director",
        "organization-admin",
        "regional",
        "system-admin",
        "system-support",
      ],
    },
    {
      name: "Work Orders",
      href: "/work-orders",
      icon: HomeRepairServiceOutlined,
      access: [
        "worker",
        "administrator",
        "director",
        "organization-admin",
        "regional",
      ],
    },
    {
      name: "Tasks",
      href: "/tasks",
      icon: TaskOutlined,
      current: false,
      access: [
        "worker",
        "administrator",
        "director",
        "organization-admin",
        "regional",
      ],
      children: [
        {
          name: "Task Scheduler",
          href: "/task-scheduler",
          icon: CalendarMonthOutlined,
          access: [
            "administrator",
            "director",
            "organization-admin",
            "regional",
          ],
        },
      ],
    },

    {
      name: "Logs",
      href: "/logs",
      icon: SummarizeOutlined,
      current: false,
      access: ["administrator", "director", "organization-admin", "regional"],
    },

    {
      name: "Facilities",
      href: "/facilities",
      icon: Business,
      access: ["system-admin"],
    },
    {
      name: "Users",
      href: "/users",
      icon: People,
      access: ["system-admin"],
    },
    {
      name: "Task Catalog",
      href: "/task-catalog",
      icon: Schedule,
      access: ["system-admin", "system-support"],
    },

    {
      name: "Onboarding",
      href: "/support/onboarding",
      icon: Schedule,
      access: ["system-support"],
    },
  ];

  const permissions = currentUser.access;

  function useMatchStart(
    path: string,
    children: NavigationComponent[] | undefined
  ) {
    const location = useLocation();
    const isChildCurrentRoute = children?.some((child: { href: string }) =>
      location.pathname.startsWith(child.href)
    );

    const isCurrentRoute =
      !isChildCurrentRoute && location.pathname.startsWith(path);
    return isCurrentRoute;
  }

  const currentRoutes = navigation.map((item) => {
    const isCurrent = useMatchStart(item.href, item.children);

    return {
      ...item,
      current: isCurrent,
      children: item.children
        ?.filter((child) => child.access.includes(permissions))
        .map((child) => ({
          ...child,
          current: location.pathname.startsWith(child.href),
        })),
    };
  });

  const allowedRoutes = currentRoutes.filter((item) => {
    return item.access.includes(permissions);
  });

  const secondaryRoutes = [
    {
      name: "My Building",
      href: "/my-building",
      icon: Business,
      access: ["administrator", "director", "organization-admin", "regional"],
    },
    {
      name: "Team",
      href: "/team",
      icon: PeopleOutline,
      access: ["administrator", "director", "organization-admin", "regional"],
    },

    /*   {
      name: "Analytics",
      href: "/analytics",
      icon: QueryStats,
      access: ["regional", "organization-admin", "administrator"],
    }, */
    {
      name: "Vendors",
      href: "/vendors",
      icon: PlumbingOutlined,
      access: ["administrator", "director", "organization-admin", "regional"],
    },
    {
      name: "Settings",
      href: "/settings",
      icon: ManageAccountsOutlined,
      access: [
        "administrator",
        "director",
        "organization-admin",
        "regional",
        "worker",
        "system-admin",
        "system-support",
      ],
    },
  ];

  const allowedSecondaryRoutes = secondaryRoutes.filter((item) => {
    return item.access.includes(permissions);
  });

  const currentSecondaryRoutes = allowedSecondaryRoutes.map(
    (item: NavigationComponent) => ({
      ...item,
      current: useMatchStart(item.href, item.children),
    })
  );

  const userNavigation = [
    { name: "Your profile", href: "/settings", function: null },
    { name: "Sign out", href: "#", function: handleLogout },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="font-poppins">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            open={sidebarOpen}
            onClose={setSidebarOpen}
            className="relative z-50 lg:hidden"
            onClick={(e) => e.stopPropagation()}
            static
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Backdrop className="fixed z-50 inset-0 bg-secondary-500/50 transition-opacity duration-500 ease-in-out" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs transform transition duration-500 ease-in-out">
                      <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                        <div className="flex items-center gap-x-3 pl-6 mb-4 pb-4 border-b border-secondary-900">
                          {currentUser.avatar ? (
                            <img
                              src={currentUser.avatar}
                              alt=""
                              className="h-10 w-10 flex-shrink-0 rounded-full"
                            />
                          ) : (
                            <UserIcon className="w-10 h-10 bg-secondary-1000 p-1 rounded-full" />
                          )}
                          <span className="text-md font-body  ">
                            {currentUser.firstname}
                            <br />
                            {currentUser.lastname}
                          </span>
                        </div>

                        {/* <div className="flex shrink-0 items-center justify-center bg-primary border-b border-secondary-1000 h-16">
                          <img

                            className="w-auto h-8"
                            src="\sequra_logo__primary_black.png"
                            alt="Company logo"
                          />
                        </div> */}
                        {/* {currentUser.facility.length > 1 && (
                          <div className="px-5 py-2 border-b border-sescondary-1000 flex w-full ">
                            <CustomSelector
                              options={facilityOptions}
                              value={
                                facilityOptions.find(
                                  (opt) => opt.id === selectedFacility
                                ) || facilityOptions[0]
                              }
                              onChange={(option) =>
                                handleFacilityChange(option.id, currentUser._id)
                              }
                            />
                          </div>
                        )} */}
                        <nav className="flex flex-1 flex-col px-5 mt-2 font-body">
                          <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                          >
                            <li>
                              <ul role="list" className="space-y-2">
                                {allowedRoutes.map(
                                  (item: NavigationComponent) => (
                                    <li key={item.name}>
                                      <div
                                        onClick={() => {
                                          navigate(item.href);
                                          !item.children &&
                                            setNestedButton(null);
                                          setSidebarOpen(false);
                                        }}
                                        className={classNames(
                                          item.current
                                            ? "bg-secondary-100 text-primary"
                                            : "text-secondary-100  hover:bg-secondary-1000",
                                          "group cursor-pointer flex items-center gap-x-3  rounded-lg text-sm leading-6 font-normal justify-between py-2.5 px-5 "
                                        )}
                                      >
                                        <div className="flex items-center gap-x-3">
                                          <item.icon
                                            className="shrink-0"
                                            aria-hidden="true"
                                            style={{ fontSize: "1rem" }}
                                          />
                                          {item.name}
                                        </div>
                                        {item.children &&
                                          item.children.length > 0 && (
                                            <button
                                              className="border-l border-secondary-1000 pl-2"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                nestedButton === item.name
                                                  ? setNestedButton(null)
                                                  : setNestedButton(item.name);
                                              }}
                                            >
                                              <ChevronRightIcon
                                                className={`h-5 w-5 ${
                                                  item.current
                                                    ? "hover:bg-secondary-300 text-secondary-1000"
                                                    : "hover:bg-secondary-900 text-secondary-100"
                                                } rounded-full transition-transform duration-300 ${
                                                  nestedButton === item.name
                                                    ? "rotate-90"
                                                    : "rotate-0"
                                                }`}
                                                aria-hidden="true"
                                              />
                                            </button>
                                          )}
                                      </div>
                                      {item.children &&
                                        nestedButton === item.name && (
                                          <ul className="ml-4 border-l-2 pl-2 border-secondary-1000 mt-1">
                                            {item.children.map((child) => (
                                              <li key={child.name}>
                                                <button
                                                  onClick={() => {
                                                    navigate(child.href);
                                                    setSidebarOpen(false);
                                                  }}
                                                  className={classNames(
                                                    child.current
                                                      ? "bg-secondary-100 text-primary"
                                                      : "text-secondary-100  ",
                                                    "group cursor-pointer flex items-center gap-x-3  rounded-lg text-sm leading-6 font-normal justify-between py-2.5 px-5"
                                                  )}
                                                >
                                                  <child.icon
                                                    className="shrink-0"
                                                    aria-hidden="true"
                                                    style={{
                                                      fontSize: "1.1rem",
                                                    }}
                                                  />
                                                  {child.name}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                            <hr className="border-b h-px border-secondary-1000" />
                            <li>
                              <div className="text-xs mb-4 ml-5 text-secondary-400">
                                ADDITIONAL PAGES
                              </div>
                              <ul role="list" className="mt-2 space-y-1">
                                {currentSecondaryRoutes.map((item) => (
                                  <li key={item.name}>
                                    <div
                                      className={classNames(
                                        item.current
                                          ? "bg-secondary-100 text-primary"
                                          : "text-secondary-100  hover:bg-secondary-1000",
                                        "group cursor-pointer flex items-center gap-x-3 rounded-lg text-sm leading-6 font-normal justify-between p-2"
                                      )}
                                    >
                                      <div
                                        role="button"
                                        tabIndex={0}
                                        className="flex w-full items-center gap-x-3"
                                        onClick={() => {
                                          navigate(item.href);
                                          setSidebarOpen(false);
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" ||
                                            e.key === " "
                                          ) {
                                            navigate(item.href);
                                          }
                                        }}
                                      >
                                        <item.icon
                                          className="shrink-0"
                                          aria-hidden="true"
                                          style={{ fontSize: "1.1rem" }}
                                        />
                                        {item.name}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            <li className="mt-auto -mx-2  border-t border-secondary-1000 h-14">
                              <button
                                onClick={handleLogout}
                                className="mx-4 h-full group flex items-center cursor-pointer gap-x-3 rounded-sm p-2 text-sm font-semibold leading-6 text-reds-600  hover:text-reds-400"
                              >
                                <Logout
                                  className="shrink-0"
                                  aria-hidden="true"
                                  style={{ fontSize: "1rem" }}
                                />
                                Sign Out
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-60 lg:flex-col bg-primary">
          {/* Sidebar component */}
          <div className="flex grow flex-col overflow-y-auto bg-primary  border-r border-secondary-1000">
            <Link
              to={
                currentUser.access.includes("worker") ? "/work-orders" : "/home"
              }
              className=" border-b border-secondary-1000 flex cursor-pointer py-2 shrink-0 items-center justify-center bg-primary h-16"
            >
              <img
                className="w-auto h-8"
                src="\sequra_logo__primary_black.png"
                alt="Company logo"
              />
            </Link>
            {currentUser.facility.length > 1 && (
              <div className="border-b border-secondary-1000  px-4 h-[73px] flex items-center justify-center ">
                <CustomSelector
                  options={facilityOptions}
                  value={
                    facilityOptions.find(
                      (opt) => opt.id === selectedFacility
                    ) || facilityOptions[0]
                  }
                  onChange={(option) =>
                    handleFacilityChange(option.id, currentUser._id)
                  }
                />
              </div>
            )}

            <nav className="flex flex-1 flex-col px-6 mt-2">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="space-y-1 -mx-2">
                    {allowedRoutes.map((item) => (
                      <li key={item.name}>
                        <div
                          className={classNames(
                            item.current
                              ? "bg-secondary-100 text-primary"
                              : "text-secondary-100  hover:bg-secondary-1000",
                            "group cursor-pointer flex items-center gap-x-3  rounded-lg text-sm leading-6 font-normal justify-between"
                          )}
                          onClick={() => {
                            navigate(item.href);
                            !item.children && setNestedButton(null);
                          }}
                        >
                          <button className="p-2">
                            <div className="flex items-center gap-x-3">
                              <item.icon
                                className="shrink-0"
                                aria-hidden="true"
                                style={{ fontSize: "1.1rem" }}
                              />
                              {item.name}
                            </div>
                          </button>
                          {item.children && item.children.length > 0 && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                nestedButton === item.name
                                  ? setNestedButton(null)
                                  : setNestedButton(item.name);
                              }}
                              className="p-2"
                            >
                              <ChevronRightIcon
                                className={`h-5 w-5 ${
                                  item.current
                                    ? "hover:bg-secondary-300 text-secondary-1000"
                                    : "hover:bg-secondary-900 text-secondary-100"
                                } rounded-full transition-transform duration-300 ${
                                  nestedButton === item.name
                                    ? "rotate-90"
                                    : "rotate-0"
                                }`}
                                aria-hidden="true"
                              />

                              {/* {nestedButton !== item.name ? (
                                <ExpandMore
                                  className="mr-2 rounded-full hover:bg-secondary-300 "
                                  aria-hidden="true"
                                />

                              ) : (
                                <ExpandLess
                                  className="mr-2 rounded-full hover:bg-secondary-300 "
                                  aria-hidden="true"
                                />
                              )} */}
                            </button>
                          )}
                        </div>
                        {item.children && nestedButton === item.name && (
                          <ul className="ml-4 border-l-2 pl-2 border-secondary-1000 mt-1">
                            {item.children.map((child) => (
                              <li key={child.name}>
                                <button
                                  onClick={() => navigate(child.href)}
                                  className={classNames(
                                    child.current
                                      ? "bg-secondary-100 text-primary"
                                      : "text-secondary-100  hover:bg-secondary-1000",
                                    "group cursor-pointer flex items-center gap-x-3  rounded-lg text-sm leading-6 font-normal justify-between px-4 py-3"
                                  )}
                                >
                                  <child.icon
                                    className="shrink-0"
                                    aria-hidden="true"
                                    style={{ fontSize: "1.1rem" }}
                                  />
                                  {child.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs mb-2 text-secondary-400">
                    ADDITIONAL PAGES
                  </div>
                  <ul role="list" className="space-y-1 -mx-2">
                    {currentSecondaryRoutes.map((item) => (
                      <li key={item.name}>
                        <div
                          className={classNames(
                            item.current
                              ? "bg-secondary-100 text-primary"
                              : "text-secondary-100  hover:bg-secondary-1000",
                            "group cursor-pointer flex items-center gap-x-3 rounded-lg text-sm leading-6 font-normal justify-between p-2"
                          )}
                        >
                          <div
                            role="button"
                            tabIndex={0}
                            className="flex w-full items-center gap-x-3"
                            onClick={() => navigate(item.href)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                navigate(item.href);
                              }
                            }}
                          >
                            <item.icon
                              className="shrink-0"
                              aria-hidden="true"
                              style={{ fontSize: "1.1rem" }}
                            />
                            {item.name}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto border-t border-secondary-1000 -mx-6  px-6 h-14">
                  <button
                    onClick={handleLogout}
                    className="group  h-full cursor-pointer flex items-center gap-x-3 rounded-sm p-1 text-sm font-semibold leading-6 text-reds-500  hover:text-reds-400"
                  >
                    <Logout
                      className="shrink-0"
                      style={{ fontSize: "1rem" }}
                      aria-hidden="true"
                    />
                    Sign out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60">
          <div className="sticky top-0 z-20 flex h-16 shrink-0 items-center justify-between gap-x-4 border-b border-secondary-1000 bg-primary px-4 sm:gap-x-6 sm:px-6 lg:px-8">
            {/* <img
              src="/sequra_logo__icon_black.png"
              className="block sm:hidden  h-6 w-auto"
              alt="Company logo"
            /> */}
            <div className="sm:hidden flex items-start gap-x-2">
              <button
                className="p-2 bg-secondary-1100 shadow-sm rounded-md border border-secondary-1000"
                onClick={() =>
                  facilityOptions.length > 1 && setShowSlider(true)
                }
              >
                <BuildingOfficeIconSolid className="h-6 w-6 text-secondary-100" />
              </button>
              {/* Display the selected facility name */}
              <div className="flex items-center">
                <span className="text-secondary-100 text-sm max-w-48 line-clamp-2">
                  {
                    facilityOptions.find((opt) => opt.id === selectedFacility)
                      ?.name
                  }
                </span>
              </div>
            </div>
            {/* Mobile menu button */}

            <div
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-secondary-400 lg:hidden"
            >
              <button type="button">
                <Bars3BottomRightIcon className="h-8 w-8 sm:hidden" />
                <Bars3BottomLeftIcon className="h-8 w-8 hidden sm:block" />
              </button>
            </div>
            <SliderContainer
              show={showSlider}
              setShow={setShowSlider}
              title={
                <div className="flex items-center gap-2">
                  <BuildingOfficeIconSolid className="h-5 w-5 text-secondary-100" />
                  <span className="text-secondary-100 text-lg font-medium font-body">
                    Select Facility
                  </span>
                </div>
              }
            >
              <div className="flex flex-col relative gap-4 font-body w-full">
                <div className="px-3 sticky top-0 bg-primary py-2 border-y border-secondary-900 -mx-2 ">
                  <SearchInput
                    searchTerm={searchTerm}
                    handleSearchChange={setSearchTerm}
                  />
                </div>
                <div className="space-y-1">
                  {filteredFacilities.map((facility) => (
                    <button
                      key={facility.id}
                      onClick={() => {
                        handleFacilityChange(facility.id, currentUser._id);
                        setShowSlider(false);
                        setSearchTerm("");
                      }}
                      className={`w-full flex items-center text-left gap-3 px-3 py-2 text-md  `}
                    >
                      {facility.name}
                      {selectedFacility === facility.id && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-5 h-5 text-accent-500 ml-auto"
                        >
                          <path
                            fillRule="evenodd"
                            d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 0 1 1.04-.208Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </SliderContainer>
            {/* Separator */}

            {/* <div
              className="h-6 w-0 sm:w-px bg-secondary-100/10 lg:hidden"
              aria-hidden="true"
            /> */}

            <div className="hidden sm:flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1 items-center text-xl">
                {
                  // Dispaly the current  route name
                  currentRoutes.find((route) => route.current)?.name
                }
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-primary"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative ">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    {currentUser.avatar ? (
                      <img
                        src={currentUser.avatar}
                        alt=""
                        className="h-9 w-9 flex-shrink-0 rounded-full"
                      />
                    ) : (
                      <UserIcon className="w-9 h-9 bg-secondary-1000 p-1 rounded-full" />
                    )}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm leading-6 text-secondary-100"
                        aria-hidden="true"
                      >
                        {currentUser.firstname} {currentUser.lastname}
                      </span>
                    </span>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-sm bg-primary py-2 shadow-lg ring-1 ring-secondary-100/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={item.function ?? (() => {})}
                              href={item.href}
                              className={classNames(
                                active ? "bg-secondary-1000" : "",
                                "block px-3 py-1 text-sm leading-6 text-secondary-100"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            {/* <button onClick={() => setSidebarOpen(true)} className="lg:hidden">
              <Bars3BottomRightIcon className="h-6 w-6" />
            </button> */}
          </div>
          {/* <div className="flex sm:hidden h-16  items-center justify-center px-5 py-1.5 border-b border-secondary-1000">
            {currentUser.facility.length > 1 && (
              <CustomSelector
                options={facilityOptions}
                value={
                  facilityOptions.find((opt) => opt.id === selectedFacility) ||
                  facilityOptions[0]
                }
                onChange={(option) =>
                  handleFacilityChange(option.id, currentUser._id)
                }
              />
            )}
          </div> */}

          <main className="h-[calc(100vh-4rem)] sm:pb-0 bg-secondary-1100 sm:bg-primary overflow-y-auto scrollbar-thin relative">
            <Outlet />
            <Toast />
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplicationShell;
