import apiClient from "../apiClient";

export const fetchWorkOrderLogs = async (
  page: string,
  pageSize: string,
  filters: any,
  facility: string
) => {
  try {
    const queryString = new URLSearchParams({
      page,
      pageSize,
      facility,
      filters: JSON.stringify(filters),
    }).toString();

    const response = await apiClient.get(`/log-book/work-orders?${queryString}`);
    return {
      workOrders: response.data.workOrders,
      totalPages: response.data.totalPages,
      totalItems: response.data.totalItems,
    };
  } catch (error) {
    console.error("Error fetching work order logs:", error);
    throw error;
  }
};

export const fetchLogs = async (
  selectedTab: string,
  filters: object,
  selectedFacility: string,
  sortColumn: string,
  sortOrder: "asc" | "desc"
) => {
  try {
    const response = await apiClient.get(`/log-book/list/${selectedFacility}`, {
      params: {
        type: selectedTab,
        filters: JSON.stringify(filters),
        sortColumn,
        sortOrder,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching logs", error);
  }
};

export const fetchLogInstance = async (logId: string) => {
  try {
    const response = await apiClient.get(`/log-book/instance/${logId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching log instance", error);
  }
};

export const deleteLogInstance = async (logId: string) => {
  try {
    const response = await apiClient.delete(`/log-book/instance/${logId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting log instance", error);
    throw error;
  }
};

export const reopenLogInstance = async (entryId: string) => {
  try {
    const response = await apiClient.post(`/log-book/instance/reopen`, { entryId });
    return response.data;
  } catch (error) {
    console.error("Error reopening log instance", error);
    throw error;
  }
};

export const fetchAttachments = async (encodedLink: string) => {
  try {
    await apiClient
      .get("/log/file", {
        params: { encodedLink },
      })
      .then((response) => {
        window.open(response.data.url, "_blank");
      });
  } catch (error) {
    console.error("Error fetching file:", error);
  }
};

// Helper function to trigger file download
const downloadFile = (data: ArrayBuffer, filename: string, type: string) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export const printLog = async (
  startDate: string,
  endDate: string | null,
  logs: { [key: string]: any },
  selectedTab: string,
  selectedFacility: string,
  exportFormat: "pdf" | "excel" = "pdf"
) => {

  try {
    const response = await apiClient.post(
      "/log-book/log/print",
      {
        startDate,
        endDate,
        logs,
        printState: selectedTab,
        facilityId: selectedFacility,
        exportFormat,
      },
      {
        responseType: 'arraybuffer',
        timeout: 60000, // 60 seconds
      }
    );
    
    const extension = exportFormat === 'excel' ? 'xlsx' : 'pdf';
    const filename = `report-from-${startDate}${endDate ? `-to-${endDate}` : ''}.${extension}`;
    const type = exportFormat === 'excel' 
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'application/pdf';

    downloadFile(response.data, filename, type);
  } catch (error) {
    console.error("Error printing logs", error);
  }
};

export const unskipTask = async (taskId: string, date: string) => {
  try {
    const response = await apiClient.post(`/recurring-tasks/unskip/${taskId}`, {
      date,
    });
    return response.data;
  } catch (error) {
    console.error("Error unskipping task:", error);
    throw error;
  }
};
