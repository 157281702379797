import React, { useState } from "react";
import LogsList from "../../updatedcomponents/tables/Logslist";
import WorkOrderLogTable from "../../updatedcomponents/tables/WorkOrderLogTable";
import LogPrint from "../../updatedcomponents/modals/LogPrint";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import {
  BuildOutlined,
  EngineeringOutlined,
  HealthAndSafetyOutlined,
} from "@mui/icons-material";
import ViewLog from "../../updatedcomponents/modals/ViewLog";
import { classNames } from "../../utils/FormatFunctions";
import Button from "../../updatedcomponents/general/Button";
import { useLog } from "../../hooks/useLog";
import { TabType } from "../../context/LogContext";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useFacility } from "../../hooks/useFacility";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import { useAuth } from "../../hooks/useAuth";
import Selector from "../../updatedcomponents/general-ui/Selector";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import WorkOrderDetails from "./WorkOrderDetails";
import {
  AdjustmentsHorizontalIcon,
  ShareIcon,
  XMarkIcon,
  RectangleStackIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import Drawer from "../../updatedcomponents/general-ui/Drawer";
import LogSelect from "./LogSelect";

const Logs: React.FC = () => {
  const {
    selectedTab,
    filters,
    showFilter,
    setShowFilter,
    updateTab,
    applyFilters,
    resetFilters,
    applyIndividualFilter,
    showState,
    updateShowState,
    selectedLog,
    sortColumn,
    handleSort,
  } = useLog();

  const getFilterOptions = (selectedTab: TabType) => {
    switch (selectedTab) {
      case "work-orders":
        return [
          {
            label: "Date Reported From",
            type: "date",
            name: "dateReportedFrom",
            joinId: "reportRange",
          },
          {
            label: "Date Reported To",
            type: "date",
            name: "dateReportedTo",
            joinId: "reportRange",
          },
          {
            label: "Date Closed From",
            type: "date",
            name: "dateClosedFrom",
            joinId: "dateRange",
          },
          {
            label: "Date Closed To",
            type: "date",
            name: "dateClosedTo",
            joinId: "dateRange",
          },
          {
            label: "Location",
            type: "text",
            name: "location",
          },
          {
            name: "urgency",
            label: "Urgency",
            type: "selector",
            options: [
              { value: "", label: "All Urgencies" },
              { value: "standard", label: "Standard" },
              { value: "urgent", label: "Urgent" },
            ],
          },
        ];
      case "life-safety":
        return [
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All Frequencies", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All Sources", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      case "prev-maint":
        return [
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All Frequencies", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All Sources", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      default:
        return [];
    }
  };

  const sortOptions = [
    { value: "", label: "None" },
    { value: "title", label: "Title" },
    { value: "source", label: "Source" },
    { value: "recurrence.frequency", label: "Frequency" },
    { value: "taskType", label: "Task Type" },
  ];

  const navigation = [
    {
      name: "Work Orders",
      value: "work-orders",
      current: selectedTab === "work-orders",
      icon: <BuildOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Life Safety",
      value: "life-safety",
      current: selectedTab === "life-safety",
      icon: <HealthAndSafetyOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Preventative Maintenance",
      value: "prev-maint",
      current: selectedTab === "prev-maint",
      icon: <EngineeringOutlined style={{ fontSize: "1rem" }} />,
    },
  ];
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const facility = currentUser?.facility.find(
    (facility) => facility._id === selectedFacility
  );
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const { isMobile } = useDeviceWidth();

  if (isMobile && selectedLog && selectedLog.logEntries.length > 0) {
    return <LogSelect />;
  }

  return (
    <div className="flex flex-col h-full bg-primary">
      <PageHeader
        title={
          <>
            <span className="inline-flex gap-2">{facility?.name}</span>
          </>
        }
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Logs</span>
          </>
        }
        subtitle={
          <div className="flex gap-2 items-center">
            View all closed tasks and work orders for your facility.
          </div>
        }
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={filters.q || ""}
              handleSearchChange={(searchTerm) =>
                applyIndividualFilter("q", searchTerm)
              }
            />
            <Button
              styleString="primary"
              onClick={() => updateShowState("showPrintState", true)}
              icon="PrintOutlined"
              children=""
              overrideStyles="w-full"
            />
          </div>
        }
      />

      <div className={`sm:px-4 flex justify-between`}>
        <nav className="flex w-fit border sm:rounded-lg sm:my-4 bg-secondary-1100 divide-x divide-secondary-1000 overflow-x-auto scrollbar-none scroll-smooth snap-x snap-mandatory">
          {navigation.map((navItem, index) => (
            <div className="flex shrink-0 snap-center" key={index}>
              <button
                onClick={(e) => {
                  updateTab(
                    navItem.value as
                      | "work-orders"
                      | "life-safety"
                      | "prev-maint"
                  );
                  // Scroll the button into view smoothly
                  e.currentTarget.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
                className={classNames(
                  navItem.current
                    ? "bg-secondary-1100 text-secondary-100"
                    : "bg-primary text-secondary-100 hover:bg-secondary-1200",
                  "group flex items-center py-4 text-xs sm:text-sm leading-6  px-3 whitespace-nowrap",
                  "transition-all duration-300 ease-in-out",
                  "transform",
                  "hover:shadow-lg"
                )}
                style={{
                  transitionProperty: "all",
                  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                  transitionDuration: "300ms",
                }}
              >
                <span className="flex align-middle mr-2 transition-transform duration-300 ease-in-out transform group-hover:scale-110">
                  {navItem.icon}
                </span>
                <span className="transition-all duration-300 ease-in-out">
                  {navItem.name}
                </span>
              </button>
            </div>
          ))}
        </nav>

        <div className="hidden lg:flex gap-4 items-center px-4">
          <div className="flex gap-2 ">
            {getFilterOptions(selectedTab)
              .filter((option) => option.type === "selector")
              .map((filterOption) => (
                <Selector
                  key={filterOption.name}
                  placeholder={filterOption.label}
                  options={filterOption.options || []}
                  value={
                    filters[filterOption.name as keyof typeof filters] !==
                    undefined
                      ? filterOption.options?.find(
                          (option) =>
                            option.value ===
                            filters[filterOption.name as keyof typeof filters]
                        )
                      : filterOption.options?.[0]
                  }
                  onChange={(selectedOption) =>
                    applyIndividualFilter(
                      filterOption.name,
                      String(selectedOption.value)
                    )
                  }
                />
              ))}
          </div>
          <div className="w-fit">
            <Button
              styleString="secondary"
              children="Reset Filters"
              icon="Refresh"
              onClick={resetFilters}
            />
          </div>
        </div>
      </div>
      <div className="flex lg:hidden w-full justify-between items-center bg-primary shadow border-b-2 border-secondary-1000 py-2.5 px-4 text-secondary-400 gap-2">
        <div
          className={`flex gap-4 text-sm ${
            isActiveSearch ? "hidden" : "w-fit"
          }`}
        >
          <button
            onClick={() => setShowFilter(true)}
            className="py-2 px-3 border border-secondary-1000 rounded-lg flex items-center gap-2"
          >
            <AdjustmentsHorizontalIcon className="h-4 w-4" />
            <span> Filter</span>
          </button>
          <Selector
            icon={<RectangleStackIcon className="h-5 w-5" />}
            key={"sortOrder"}
            placeholder="Group By: None"
            options={sortOptions}
            value={sortOptions.find((option) => option.value === sortColumn)}
            onChange={(selectedOption) =>
              handleSort(String(selectedOption.value))
            }
          />
        </div>

        <div
          className={`flex items-center ${
            isActiveSearch ? "w-full" : "w-fit"
          } px-3 border border-secondary-1000 rounded-lg justify-end h-full`}
        >
          <div
            className={`${
              isActiveSearch
                ? "opacity-100 w-full flex justify-between items-center"
                : "opacity-0 w-0 overflow-hidden"
            } flex transition-all duration-200 ease-in-out`}
          >
            <input
              type="text"
              className={`flex flex-row-reverse py-1.5 px-2 border-none focus:outline-none focus:ring-transparent  transition-opacity duration-500 ease-in-out
                ${
                  isActiveSearch
                    ? "opacity-100 w-full items-center"
                    : "opacity-0 w-0"
                }
                `}
              value={filters.q || ""}
              onChange={(e) => applyIndividualFilter("q", e.target.value)}
              placeholder="Search..."
            />
            <button
              className={`transition-opacity duration-200 ease-in-out ${
                isActiveSearch
                  ? "opacity-100 w-fit items-center"
                  : "opacity-0 w-0"
              }`}
              onClick={() => {
                setIsActiveSearch(false);
                applyIndividualFilter("q", "");
              }}
            >
              <span className="sr-only">Clear search</span>
              <XMarkIcon className="h-5 w-5 text-secondary-400" />
            </button>
          </div>

          <button
            onClick={() => setIsActiveSearch(!isActiveSearch)}
            className={` ${isActiveSearch ? "opacity-0 w-0" : "w-5"}`}
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      <FilterModal
        show={showFilter}
        setShow={setShowFilter}
        filters={JSON.stringify(filters)}
        filterOptions={getFilterOptions(selectedTab)}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
      />
      {selectedTab !== "work-orders" && <LogsList />}

      {selectedTab === "work-orders" && (
        <>
          <WorkOrderLogTable />
        </>
      )}
      <Modal
        title={selectedLog?.title || "View Log"}
        open={showState.showLogState}
        setOpen={() => updateShowState("showLogState", false)}
        content={<ViewLog />}
      />

      <Modal
        title="Print Logs"
        open={showState.showPrintState}
        setOpen={() => updateShowState("showPrintState", false)}
        content={<LogPrint />}
      />
      <Drawer
        open={showState.showWorkOrder}
        setOpen={() => updateShowState("showWorkOrder", false)}
        title={
          <>
            <button
              onClick={() => {}}
              data-tooltip-id="share-tooltip"
              data-tooltip-content="Work order copied to clipboard"
              onMouseEnter={(e) => {
                e.currentTarget.setAttribute("data-tooltip-show", "false");
              }}
            >
              <ShareIcon
                className="h-6 w-6 text-secondary-300"
                aria-hidden="true"
              />
            </button>
            <Tooltip
              id="share-tooltip"
              place="top"
              delayHide={2000}
              openOnClick={true}
            />
          </>
        }
        content={<WorkOrderDetails />}
      />
    </div>
  );
};

export default Logs;
