
import React from "react";
import { UserManagementProvider } from "../../context/UserManagementContext";
import UsersContent from "./UsersContent";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";

const Users = () => {
  return (
      <div className="flex flex-col h-full bg-primary">
        <UsersContent />
      </div>
  );
};

export default Users;