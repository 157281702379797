import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useVendor } from "../../hooks/useVendor";
import Input, { InputType } from "../general-ui/Input";
import Button from "../general/Button";

type Input = {
  id: string;
  label: string;
  type: InputType;
  placeholder: string;
  required: boolean;
  options?: { label: string; value: string }[];
};

const inputs: Input[] = [
  {
    id: "name",
    label: "Company Name",
    type: "text",
    placeholder: "Company Name",
    required: true,
  },

  {
    id: "contact",
    label: "Contact Person",
    type: "text",
    placeholder: "(i.e. John Doe)",
    required: true,
  },
  {
    id: "email",
    label: "Email Address",
    type: "email",
    placeholder: "email@example.com",
    required: false,
  },

  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "(i.e. 123 Sesame Street Court, NY, NY)",
    required: false,
  },
  {
    id: "phone",
    label: "Phone #",
    type: "text",
    placeholder: "(123) 456-7890",
    required: true,
  },
  {
    id: "type",
    label: "Type",
    type: "select",
    placeholder: "Please select a value",
    required: true,
    options: [
      { label: "Electrician", value: "electrician" },
      { label: "HVAC", value: "HVAC" },
      { label: "Plumber", value: "plumber" },
      { label: "Sprinkler", value: "sprinkler" },
      { label: "Other", value: "other" },
    ],
  },
];

type FormDataType = {
  name: string;
  contact: string;
  phone: string;
  email: string;
  address: string;
  type: string;
};

const NewVendorForm: React.FC = ({}) => {
  const { updateShowModalState, onCreateVendor } = useVendor();

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    contact: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    address: yup.string().required(),
    type: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormDataType>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onCreateVendor)}
      className="flex h-full flex-col overflow-y-auto bg-white shadow-xl"
    >
      <div className="flex-1">
        {/* Divider container */}
        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
          {/* Inputs */}
          {inputs.map((input) => (
            <Input
              key={input.id}
              label={input.label}
              name={input.id}
              type={input.type}
              placeholder={input.placeholder}
              register={register}
              errors={errors[input.id as keyof FormDataType]}
              options={input.options}
            />
          ))}
        </div>
      </div>
      {/* Action buttons */}
        <div className="flex w-full gap-2 justify-between border-t border-secondary-1000 mt-4">
          <Button
            type="button"
            styleString="secondary"
            onClick={() => updateShowModalState("showVendor", false)}
            children="Cancel"
            overrideStyles="w-full"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Submit"
            disabled={isSubmitting}
            overrideStyles="w-full"
          />
        </div>
    </form>
  );
};

export default NewVendorForm;
