
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { useUserManagement } from "../../hooks/useUserManagement";
import Button from "../../updatedcomponents/general/Button";
import { User } from "../../types/User";

// Make sure this matches the interface in UserManagementContext
interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  facility: string[];
}

// Helper function to extract facility IDs
const extractFacilityIds = (facilityData: any): string[] => {
  if (!facilityData) return [];
  
  // If it's already an array
  if (Array.isArray(facilityData)) {
    // Handle the case where facility is an array of objects with _id property
    return facilityData.map(facility => 
      typeof facility === "string" ? facility : facility?._id || ""
    ).filter(id => id !== "");
  }
  
  // If it's a single item (string or object)
  if (typeof facilityData === "string") return [facilityData];
  if (facilityData._id) return [facilityData._id];
  
  return [];
};

const EditUserForm: React.FC = () => {
  const { selectedUser, updateExistingUser, updateModalShowState, facilities, isLoading } = useUserManagement();

  // Create options for the Select component
  const facilityOptions = facilities.map(facility => ({
    value: facility._id,
    label: facility.name
  }));

  const validationSchema = yup.object().shape({
    firstname: yup.string().required("*Required field"),
    lastname: yup.string().required("*Required field"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("*Required field"),
    phone: yup.string().required("*Required field"),
    access: yup.string().required("*Required field"),
    facility: yup.array().of(yup.string().required()).required("At least one facility is required").min(1, "At least one facility is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: selectedUser?.firstname || "",
      lastname: selectedUser?.lastname || "",
      email: selectedUser?.email || "",
      phone: selectedUser?.phone || "",
      access: selectedUser?.access || "worker",
      facility: extractFacilityIds(selectedUser?.facility) || [],
    },
  });

  // Update form values when selectedUser changes
  useEffect(() => {
    if (selectedUser) {
      setValue("firstname", selectedUser.firstname || "");
      setValue("lastname", selectedUser.lastname || "");
      setValue("email", selectedUser.email || "");
      setValue("phone", selectedUser.phone || "");
      setValue("access", selectedUser.access || "worker");
      setValue("facility", extractFacilityIds(selectedUser.facility));
    }
  }, [selectedUser, setValue]);

  const inputs = [
    {
      id: "firstname",
      label: "First Name",
      type: "text",
      placeholder: "First Name",
    },
    {
      id: "lastname",
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
    },
    {
      id: "email",
      label: "Email",
      type: "text",
      placeholder: "email@example.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "tel",
      placeholder: "(123) 456-7890",
    },
  ];

  return (
    <div>
      <form
        onSubmit={handleSubmit(updateExistingUser)}
        className="flex h-full flex-col overflow-y-scroll hide-scrollbar-arrows"
        noValidate
      >
        <div className="grid grid-cols-2 gap-4">
          {inputs.map((input) => (
            <div key={input.id}>
              <label
                htmlFor={input.id}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {input.label}
              </label>
              <input
                type={input.type}
                placeholder={input.placeholder}
                className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
                {...register(input.id as keyof FormValues)}
              />
              {errors && errors[input.id as keyof FormValues] && (
                <p className="text-reds-500 text-xs">
                  {errors[input.id as keyof FormValues]?.message}
                </p>
              )}
            </div>
          ))}
        </div>
        
        {/* Facility Multi-select Dropdown */}
        <div key={"facility-selector"} className="mt-4">
          <label
            htmlFor="facility"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Facilities
          </label>
          <div className="sm:col-span-2">
            <Controller
              name="facility"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={facilityOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={isLoading ? "Loading facilities..." : "Select facilities"}
                  isLoading={isLoading}
                  onChange={(selectedOptions) => {
                    // Extract facility IDs from selected options
                    const facilityIds = selectedOptions
                      ? selectedOptions.map(option => option.value)
                      : [];
                    setValue('facility', facilityIds);
                  }}
                  value={facilityOptions.filter(option => 
                    field.value && field.value.includes(option.value)
                  )}
                  styles={{
                    input: (base) => ({
                      "input:focus": {
                        boxShadow: "none",
                      },
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                      primary25: "lightgray",
                    },
                  })}
                />
              )}
            />
            {errors.facility && (
              <p className="text-reds-500 text-xs mt-1">
                {errors.facility.message?.toString()}
              </p>
            )}
          </div>
        </div>
        
        <div key={"access"} className="mt-4">
          <label
            htmlFor="access"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Access
          </label>

          <div className="sm:col-span-2">
            <select
              id={"access"}
              className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
              {...register("access")}
            >
              <option value={"worker"}>Worker</option>
              <option value={"regional"}>Regional</option>
              <option value={"director"}>Director</option>
              <option value={"administrator"}>Administrator</option>
              <option value={"organization-admin"}>Organization Admin</option>
              <option value={"system-admin"}>System Admin</option>
            </select>
          </div>
        </div>

        <div className="flex w-full gap-2 justify-between border-t border-secondary-1000 mt-4 pt-4">
          <Button
            type="button"
            styleString="secondary"
            onClick={() => updateModalShowState("showEditUser", false)}
            children="Cancel"
            overrideStyles="w-full"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Save Changes"
            disabled={isSubmitting}
            overrideStyles="w-full"
          />
        </div>
      </form>
    </div>
  );
};

export default EditUserForm;