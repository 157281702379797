import React, { useEffect, useState, useRef } from "react";
import { useRecurring } from "../../hooks/useRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import { File as FileType } from "../../types/File";
import Modal from "../../updatedcomponents/general-ui/Modal";
import useMultiForm from "../../hooks/useMultiForm";
import TaskInstanceForm from "../../updatedcomponents/forms/TaskInstanceForm";
import Button from "../../updatedcomponents/general/Button";
import AssignmentSelector from "../../updatedcomponents/general-ui/AssignmentSelector";
import { useNavigate, useParams } from "react-router-dom";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../../updatedcomponents/general/Spinner";
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PaperClipIcon,
  InformationCircleIcon,
  TagIcon,
  FlagIcon,
  ChevronDownIcon,
  ShareIcon,
  UserIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

const InfoField: React.FC<{
  label: string;
  value: React.ReactNode;
  icon?: React.ReactNode;
}> = ({ label, value, icon }) => (
  <div className="flex flex-col">
    <span className="text-secondary-500 flex items-center gap-2">
      {icon && <span className="text-secondary-500">{icon}</span>}
      {label}
    </span>
    <span className="text-secondary-100">{value}</span>
  </div>
);

const RecurringTaskInstance = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { section } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDetails, setShowDetails] = useState(!isMobile);
  const [isDirty, setIsDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [targetSectionIndex, setTargetSectionIndex] = useState<number | null>(
    null
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    instanceToEdit,
    navigateBack,
    closeOutValidation,
    canCloseOut,
    handleCloseOut,
    showState,
    updateShowState,
    users,
    handleTaskAssignment,
    handleViewFile,
    handleSkipTask,
    handleUnskipTask,
  } = useRecurring();

  const {
    currentIndex,
    goToSection,
    goToNextSection,
    goToPreviousSection,
    isFirstSection,
    isLastSection,
  } = useMultiForm(instanceToEdit?.parentTask?.map?.fields ?? []);

  useEffect(() => {
    if (instanceToEdit) {
      closeOutValidation();
    }
  }, [closeOutValidation, currentIndex, instanceToEdit]);

  useEffect(() => {
    if (section !== undefined) {
      const sectionIndex = parseInt(section);
      if (!isNaN(sectionIndex)) {
        goToSection(sectionIndex);
      }
    }
  }, [section, goToSection]);

  const checkScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);
    return () => window.removeEventListener("resize", checkScrollButtons);
  }, []);

  const handleScroll = () => {
    checkScrollButtons();
  };

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 400; // Adjust this value to control scroll distance
      const newScrollLeft =
        direction === "left"
          ? scrollContainerRef.current.scrollLeft - scrollAmount
          : scrollContainerRef.current.scrollLeft + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  // Add effect to keep current item in view
  useEffect(() => {
    if (scrollContainerRef.current && currentIndex >= 0) {
      const container = scrollContainerRef.current;
      const currentElement = container.children[currentIndex] as HTMLElement;

      if (currentElement) {
        const containerWidth = container.offsetWidth;
        const elementOffset = currentElement.offsetLeft;
        const elementWidth = currentElement.offsetWidth;

        // Calculate the scroll position that will center the element
        const scrollPosition =
          elementOffset - containerWidth / 2 + elementWidth / 2;

        container.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [currentIndex]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
    setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    if (scrollContainerRef.current) {
      const x = e.pageX - (scrollContainerRef.current.offsetLeft || 0);
      const walk = (x - startX) * 2;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleSectionClick = (index: number) => {
    if (isDirty) {
      setTargetSectionIndex(index);
      setShowUnsavedModal(true);
    } else {
      goToSection(index);
    }
  };

  const handleNavigateUnsaved = () => {
    if (targetSectionIndex !== null) {
      setShowUnsavedModal(false);
      setIsDirty(false);
      goToSection(targetSectionIndex);
      setTargetSectionIndex(null);
    } else {
      setShowUnsavedModal(false);
      setIsDirty(false);
    }
  };

  return (
    <div className="flex flex-col h-full bg-primary overflow-y-auto thin-scrollbar">
      <PageHeader
        title={
          <button
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={() => navigateBack()}
          >
            <ChevronLeftIcon className="text-inherit h-4 w-4" />
            Back to Tasks
          </button>
        }
        mobileTitle={
          <button
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={() => navigateBack()}
          >
            <ChevronLeftIcon className="text-inherit h-4 w-4" />
            Back to Tasks
          </button>
        }
        buttons={
          !currentUser.access.includes("worker") && (
            <div className="flex gap-2">
              {instanceToEdit?.parentTask.skippable &&
                instanceToEdit.status !== "skipped" && (
                  <Button
                    styleString="secondary"
                    onClick={() => updateShowState("showSkip", true)}
                  >
                    Skip
                  </Button>
                )}
              <Button
                children="Close Out"
                disabled={!canCloseOut}
                onClick={() => handleCloseOut()}
              />
            </div>
          )
        }
        className="sm:py-2"
      />
      {!instanceToEdit ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row h-full">
          <div
            className={`flex flex-col border-r border-secondary-1000 bg-primary md:w-2/5 w-full h-full p-4 pr-4 ${
              !showDetails && isMobile ? "hidden" : ""
            }`}
          >
            <span className="font-medium mb-4">Details</span>

            <div className="grid grid-cols-2 gap-y-4 text-sm">
              <label className="text-secondary-400 font-light flex gap-2 items-center">
                <CalendarDaysIcon className="w-4 h-4" /> Due Date:
              </label>
              <span className="text-secondary-300 ">
                {formatDate(instanceToEdit.date, "MMM DD, yyyy", "utc")}
              </span>

              <label className="text-secondary-400 font-light flex gap-2 items-center">
                <FlagIcon className="w-4 h-4" /> Status:
              </label>
              <span className="text-secondary-300 ">
                <StatusBadge status={instanceToEdit.status} />
              </span>

              {instanceToEdit.parentTask.regulations && (
                <>
                  <label className="text-secondary-100">Regulations:</label>
                  <span className="text-secondary-300 ">
                    {instanceToEdit.parentTask.regulations?.length
                      ? instanceToEdit.parentTask.regulations.join(", ")
                      : "N/a"}
                  </span>
                </>
              )}
              <label className="text-secondary-400 font-light flex gap-2 items-center">
                <ShareIcon className="w-4 h-4" /> Source:
              </label>
              <span className="text-secondary-300 ">
                {capitalizeString(instanceToEdit.parentTask?.source)}
              </span>

              <label className="text-secondary-400 font-light flex gap-2 items-center">
                <PaperClipIcon className="w-4 h-4" /> Attachments:
              </label>
              <span className="text-secondary-300 ">
                {instanceToEdit.parentTask?.attachments?.length ? (
                  instanceToEdit.parentTask.attachments.map(
                    (attachment: FileType) => (
                      <div key={attachment._id} className="flex gap-2">
                        <button
                          className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-xs cursor-pointer truncate flex items-center gap-1"
                          onClick={() =>
                            attachment.url && handleViewFile(attachment.url)
                          }
                        >
                          <span className="truncate">{attachment.name}</span>
                        </button>
                      </div>
                    )
                  )
                ) : (
                  <p className="text-sm italic">No attachments for this task</p>
                )}
              </span>
              <label className="text-secondary-400 font-light flex gap-2 items-center">
                <UserIcon className="w-4 h-4" /> Assigned To:
              </label>
              <span className="text-secondary-300 ">
                <AssignmentSelector
                  users={users}
                  selectedUser={
                    instanceToEdit.assignedTo ? instanceToEdit.assignedTo : null
                  }
                  handleChange={(user) => {
                    handleTaskAssignment(instanceToEdit, user);
                  }}
                />
              </span>
            </div>
            <div className="flex flex-col">
              <label className="text-secondary-400 text-sm font-light py-2 flex gap-2 items-center">
                <DocumentTextIcon className="w-4 h-4" /> Description:
              </label>
              <span className="text-secondary-300 text-sm ">
                {instanceToEdit.parentTask?.details}
              </span>
            </div>
          </div>

          <div className="md:hidden w-full bg-primary">
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="w-full justify-center items-center flex gap-2 focus:outline-none focus:ring-none text-secondary-300 p-4"
            >
              {showDetails ? "Hide Details" : "Show Details"}
              <ChevronDownIcon
                className={`text-inherit h-4 w-4 transition-transform duration-300 transform ${
                  showDetails ? "rotate-180" : ""
                }`}
              />
            </button>
            <div className="h-2 bg-secondary-1000 flex w-full" />
          </div>
          <div className="flex flex-col h-full bg-primary">
            {instanceToEdit && instanceToEdit.status === "skipped" ? (
              <div className="flex justify-center items-center m-4 bg-secondary-1200 py-4 rounded-md">
                <p className="text-sm italic">This task is skipped</p>
              </div>
            ) : (
              <div className="flex flex-col w-full md:w-[calc(60vw-2rem)] md:h-[calc(100vh-130px)] h-full md:overflow-y-auto thin-scrollbar">
                <div className="flex justify-between items-center p-4">
                  <div>
                    <h3 className="text-lg font-medium">
                      {instanceToEdit?.parentTask.title}
                    </h3>
                  </div>
                  {instanceToEdit &&
                    instanceToEdit.parentTask?.map?.fields.length > 1 && (
                      <div className="hidden sm:flex gap-6">
                        <button
                          disabled={!showLeftArrow}
                          onClick={() => scroll("left")}
                          className="text-secondary-100 disabled:text-secondary-800"
                        >
                          <ChevronLeftIcon className="text-inherit h-4 w-4" />
                        </button>

                        <button
                          disabled={!showRightArrow}
                          onClick={() => scroll("right")}
                          className="text-secondary-100 disabled:text-secondary-800"
                        >
                          <ChevronRightIcon className="text-inherit h-4 w-4" />
                        </button>
                      </div>
                    )}
                </div>
                <div
                  ref={scrollContainerRef}
                  className="flex flex-row overflow-x-auto hide-scrollbar-arrows w-full flex-nowrap cursor-grab active:cursor-grabbing px-4 select-none"
                  onScroll={handleScroll}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                >
                  {instanceToEdit &&
                    instanceToEdit.parentTask?.map?.fields.length > 1 &&
                    instanceToEdit.parentTask?.map?.fields.map(
                      (mapField, index) => (
                        <div
                          key={index}
                          className={`border-x px-4 my-4 items-center flex-shrink-0 flex justify-center hover:cursor-pointer hover:shadow-sm min-w-[200px] w-[200px] select-none ${
                            currentIndex === index
                              ? "border-accent-500 text-accent-500"
                              : ""
                          }`}
                          onClick={() =>
                            !isDragging && handleSectionClick(index)
                          }
                        >
                          <div className="flex flex-col w-full">
                            <h3 className="text-sm text-center">
                              {String(mapField[Object.keys(mapField)[1]])}
                            </h3>
                            {instanceToEdit.sectionStatus &&
                            instanceToEdit.sectionStatus[index] ? (
                              <StatusBadge
                                status={instanceToEdit.sectionStatus[index]}
                              />
                            ) : (
                              <StatusBadge status="not started" />
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
                <div className="hidden md:flex w-full justify-between border-y border-secondary-1000 py-2 px-8 ">
                  <InfoField
                    label="Due Date"
                    value={formatDate(
                      instanceToEdit?.date,
                      "MMM DD, yyyy",
                      "utc"
                    )}
                    icon={<CalendarDaysIcon className="text-inherit h-4 w-4" />}
                  />
                  {currentIndex > -1 &&
                    instanceToEdit?.parentTask.map?.fields[currentIndex] && (
                      <>
                        {Object.entries(
                          instanceToEdit.parentTask.map.fields[currentIndex]
                        ).map(([key, value], index) => (
                          <InfoField
                            key={key}
                            label={key}
                            value={String(value)}
                            icon={
                              index === 0 ? (
                                <TagIcon className="text-inherit h-4 w-4" />
                              ) : (
                                <InformationCircleIcon className="text-inherit h-4 w-4" />
                              )
                            }
                          />
                        ))}
                      </>
                    )}
                  <InfoField
                    label="Status"
                    value={
                      <StatusBadge
                        status={instanceToEdit?.status ?? "not started"}
                      />
                    }
                    icon={<FlagIcon className="text-inherit h-4 w-4" />}
                  />
                </div>
                <div className="flex p-4 w-full justify-between md:hidden">
                  <InfoField
                    label="Due Date"
                    value={formatDate(
                      instanceToEdit?.date,
                      "MMM DD, yyyy",
                      "utc"
                    )}
                    icon={<CalendarDaysIcon className="text-inherit h-4 w-4" />}
                  />
                  <InfoField
                    label={
                      instanceToEdit?.parentTask?.map?.fields?.[currentIndex] &&
                      Object.entries(
                        instanceToEdit.parentTask.map.fields[currentIndex]
                      )[1]?.[0]
                    }
                    value={
                      instanceToEdit?.parentTask?.map?.fields?.[currentIndex] &&
                      Object.entries(
                        instanceToEdit.parentTask.map.fields[currentIndex]
                      )[1]?.[1]
                    }
                    icon={<TagIcon className="text-inherit h-4 w-4" />}
                  />
                </div>
                <div className="flex flex-col h-full md:overflow-y-auto thin-scrollbar">
                  {currentIndex > -1 && instanceToEdit && (
                    <div
                      className="h-full"
                      key={`form-container-${currentIndex}-${instanceToEdit._id}`}
                    >
                      <TaskInstanceForm
                        currentIndex={currentIndex}
                        goToSection={handleSectionClick}
                        goToNextSection={goToNextSection}
                        goToPreviousSection={goToPreviousSection}
                        isFirstSection={isFirstSection}
                        isLastSection={isLastSection}
                        onDirtyStateChange={setIsDirty}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showUnsavedModal && (
        <GenericModal
          title="Unsaved Changes"
          buttonText="Leave"
          handleFunction={handleNavigateUnsaved}
          item={instanceToEdit}
          showModal={showUnsavedModal}
          setShowModal={setShowUnsavedModal}
        />
      )}

      <GenericModal
        title="Skip this task"
        color="accent-500"
        secondaryColor="secondary-1000"
        item={instanceToEdit}
        handleFunction={() => instanceToEdit && handleSkipTask(instanceToEdit)}
        buttonText="Skip"
        showModal={showState.showSkip}
        setShowModal={() => updateShowState("showSkip", false)}
      />
    </div>
  );
};

export default RecurringTaskInstance;
