import { FC } from "react";
import {
  BusinessOutlined,
  EmailOutlined,
  Home,
  HomeOutlined,
  Person2Outlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { useVendor } from "../../hooks/useVendor";
import { Vendor } from "../../types/Vendor";
import Table from "../general/Table";
import Button from "../general/Button";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../general/Spinner";
import { BuildingStorefrontIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

const VendorsTable: FC = () => {
  const { currentUser } = useAuth();
  const { vendors, updateShowModalState, setSelectedVendor, isLoading } =
    useVendor();
  const { isMobile } = useDeviceWidth();
  const permissions = currentUser.access;

  const columns = [
    {
      label: "Company",
      key: "name",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <div className="text-sm p-4 lg:p-0">
            <div className="flex items-start">
              <div className="flex-shrink-0 text-accent-300 bg-accent-1000 h-8 rounded-full w-8 flex items-center justify-center">
                <BuildingStorefrontIcon className="w-5 h-5" />
              </div>
              <div className="ml-4 flex flex-col gap-1">
                <span className="font-medium text-secondary">{vendor.name}</span>
                <span className="lg:hidden text-secondary-400">{capitalizeString(vendor.contact)}</span>
                <span className="text-xs text-secondary-500 truncate">{vendor.email}</span>
                <span className="text-xs text-secondary-500">{formatPhoneNumber(vendor.phone)}</span>
                <span className="lg:hidden text-secondary-400 truncate">{vendor.address}</span>
              </div>
            </div>
          </div>
        </>
      ),
      className: "truncate",
    },
    {
      label: "Email",
      key: "email",
      sortable: false,
      render: (vendor: Vendor) => <>{vendor.email}</>,
      className: "hidden  truncate",
    },
    {
      label: "Address",
      key: "address",
      sortable: false,
      render: (vendor: Vendor) => <>{capitalizeString(vendor.address)}</>,
      className: "hidden lg:table-cell truncate",
    },
    {
      label: "Type",
      key: "type",
      sortable: false,
      render: (vendor: Vendor) => <>{capitalizeString(vendor.type)}</>,
      className: "hidden lg:table-cell truncate",
    },
    {
      label: "Phone",
      key: "phone",
      sortable: false,
      render: (vendor: Vendor) => <>{formatPhoneNumber(vendor.phone)}</>,
      className: "hidden truncate",
    },
    {
      label: "Contact Person",
      key: "contact",
      sortable: false,
      render: (vendor: Vendor) => <>{vendor.contact}</>,
      className: "hidden lg:table-cell truncate",
    },
    {
      label: "",
      key: "delete",
      sortable: false,
      render: (vendor: Vendor) =>
        ["organization-admin", "regional"].includes(permissions) && (
          <Button
            onClick={() => {
              setSelectedVendor(vendor);
              updateShowModalState("showDelete", true);
            }}
            icon="Delete"
            styleString="danger"
            children={null}
            overrideStyles="w-6"
          />
        ),
      className: "hidden lg:table-cell w-24",
    },
  ];
  return (
    <Table
      columns={columns}
      data={vendors}
      isClickable={false}
      showHeader={!isMobile}
      noDataMessage={
        isLoading ? (
          <Spinner />
        ) : (
          <div className="flex items-center justify-center flex-col gap-4">
            <div className="bg-primary rounded-lg border border-secondary-1000 p-4">
              <BuildingStorefrontIcon className="w-7 h-7" />
            </div>
            <span className="text-secondary-400 font-light text-sm">
              No vendors found
            </span>
          </div>
        )
      }
      paginationScheme="none"
      stickyFirstColumn
    />
  );
};

export default VendorsTable;
