import { Fragment, useState, useCallback } from "react";
import { Check, Person } from "@mui/icons-material";
import classNames from "classnames";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useInteractions,
  useRole,
  useDismiss,
  FloatingFocusManager,
  useTransitionStyles,
} from "@floating-ui/react";
import { capitalizeString } from "../../utils/FormatFunctions";
import { WorkOrderStatus } from "../../types/WorkOrder";
import { useAuth } from "../../hooks/useAuth";

interface StatusSelectorProps {
  selectedStatus: string | null;
  statusOptions?: { label: string; value: string }[];
  handleChange: (selectedStatus: WorkOrderStatus | null) => void;
}

const StatusSelector: React.FC<StatusSelectorProps> = ({
  selectedStatus,
  statusOptions = [
    { label: "Incomplete", value: "incomplete" },
    { label: "Complete", value: "complete" },
    { label: "Unable", value: "unable" },

  ],
  handleChange,
}) => {
  const { currentUser } = useAuth();
  const permissions = currentUser.access;
  // If the selected status is complete then add the closed option
  if (selectedStatus === "complete") {
    permissions !== "worker" &&
      statusOptions.push({ label: "Closed", value: "closed-pending" });
  }

  if (selectedStatus === "closed") {
    statusOptions.push({ label: "Closed", value: "closed" });
  }

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8), // Add spacing between button and menu
      flip({
        padding: 8, // Add padding to flip calculation
        fallbackPlacements: ["top-start"], // Only flip to top when needed
      }),
      shift({ padding: 8 }), // Keep menu in viewport
    ],
    whileElementsMounted: autoUpdate,
    placement: "bottom-start", // Default to dropping down
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { styles: transitionStyles, isMounted } = useTransitionStyles(
    context,
    {}
  );

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const outlineStyles = classNames(
    "flex focus:ring-2 ring-inset ring-transparent gap-1  w-full cursor-pointer rounded-lg bg-white p-1 text-left text-gray-900  ring-1  focus:outline-none text-sm sm:leading-6",
    selectedStatus === "incomplete" && "ring-secondary-900",
    selectedStatus === "complete" && "ring-accent-500",
    selectedStatus === "closed-pending" && "ring-accent-200",
    selectedStatus === "closed" && "ring-accent-200",
    selectedStatus === "unable" && "ring-reds-500"
  );
  const currentStatusStyle = classNames(
    "truncate text-sm mr-8 rounded-lg cursor-pointer text-sm relative cursor-pointer select-none py-1.5 px-4",
    selectedStatus === "incomplete" && "bg-secondary-1000 text-secondary",
    selectedStatus === "complete" && "bg-accent-500 text-primary",
    selectedStatus === "closed-pending" && "bg-accent-200 text-primary",
    selectedStatus === "closed" && "bg-accent-200 text-primary",
    selectedStatus === "unable" && "bg-reds-500 text-primary"
  );

  return (
    <div className="relative flex w-full">
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={outlineStyles}
      >
        <span className={currentStatusStyle}>
          {selectedStatus ? capitalizeString(selectedStatus) : "Status"}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-secondary-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`h-5 w-5 transition-transform duration-300 transform ${
              isOpen ? "rotate-180" : ""
            }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </button>

      {isMounted && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              ...transitionStyles,
            }}
            {...getFloatingProps()}
            className="z-50 max-h-56 w-full overflow-auto thin-scrollbar rounded-md bg-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs sm:text-sm"
          >
            {statusOptions.map((status) => {
              const selectionStyles = classNames(
                "cursor-pointer text-sm text-secondary-300 relative cursor-default select-none py-2 pl-3 pr-9",
                status.value === "incomplete" &&
                  "hover:bg-secondary-900 hover:text-primary",
                status.value === "complete" &&
                  "hover:bg-accent-500 hover:text-primary",
                status.value === "closed-pending" &&
                  "hover:bg-accent-200 hover:text-primary",
                status.value === "closed" &&
                  "hover:bg-accent-200 hover:text-primary",
                status.value === "unable" &&
                  "hover:bg-reds-500 hover:text-primary"
              );

              return (
                <div
                  key={status.value}
                  className={selectionStyles}
                  onClick={() => {
                    handleChange(status.value as WorkOrderStatus);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-center">
                    <span className="ml-3 block text-xs sm:text-sm">
                      {status.label}
                    </span>
                  </div>

                  {selectedStatus === status.value && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <Check className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default StatusSelector;
