import React from "react";
import {
  DeleteOutline,
  EditOutlined,
  EmailOutlined,
  Person,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  formatDate,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { User } from "../../types/User";
import Table from "../../updatedcomponents/general/Table";
import Spinner from "../../updatedcomponents/general/Spinner";
import { useUserManagement } from "../../hooks/useUserManagement";

const UsersTable: React.FC = () => {
  const {
    users,
    isLoading,
    setSelectedUser,
    handleEditUser,
    updateModalShowState,
  } = useUserManagement();

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: false,
      render: (user: User) => (
        <>
          <div className="whitespace-nowrap py-2 text-sm flex w-full">
            <div className="flex w-full">
              <div className="h-6 w-6 flex flex-shrink-0">
                {user.avatar ? (
                  <img
                    className="h-6 w-6 rounded-full"
                    src={user.avatar}
                    alt=""
                  />
                ) : (
                  <Person className="h-6 w-6 rounded-full" />
                )}
              </div>
              <div className="ml-3 flex flex-col w-full max-w-64 flex-1">
                <div className="font-medium text-secondary-100">
                  {user.firstname} {user.lastname}
                </div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center w-full">
                  <EmailOutlined style={{ fontSize: "1rem" }} />
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {user.email}
                  </span>
                </div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(user.phone)}
                </div>
                <div className="mt-2 sm:hidden">
                  {user.isVerified === true ? (
                    <span className="inline-flex items-center rounded-sm bg-accent-900 px-2 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-200/20">
                      Verified
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-sm bg-reds-1000 px-2 py-1 text-xs font-medium text-reds-300 ring-1 ring-inset ring-reds-300/20">
                      Unverified
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none",
    },
    {
      label: "Facility",
      key: "facility",
      sortable: false,
      render: (user: User) => (
        <span className="pl-0 py-5 text-sm text-secondary-400 break-words">
          {user.facility && user.facility.length > 0
            ? user.facility
                .map((facility: any) =>
                  typeof facility === "object" && facility.name
                    ? facility.name
                    : "Unknown"
                )
                .join(", ")
            : "No Facility"}
        </span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Date Joined",
      key: "joinDate",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {formatDate(user.joinDate)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "isVerified",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {user.isVerified === true ? (
              <span className="inline-flex items-center rounded-sm bg-accent-900 px-2 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-200/20">
                Verified
              </span>
            ) : (
              <span className="inline-flex items-center rounded-sm bg-reds-1000 px-2 py-1 text-xs font-medium text-reds-300 ring-1 ring-inset ring-reds-300/20">
                Unverified
              </span>
            )}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Role",
      key: "access",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {capitalizeString(user.access)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Details",
      key: "details",
      sortable: false,
      render: (user: User) => (
        <span className="whitespace-nowrap px-1 py-5 text-sm text-gray-500">
          <div className="flex space-x-3">
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                handleEditUser(user);
              }}
              className="text-secondary hover:text-accent-200 underline"
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                setSelectedUser(user);
                updateModalShowState("showDelete", true);
              }}
              className="text-reds-500 hover:text-reds-700 underline"
            >
              <DeleteOutline style={{ fontSize: "1.2rem" }} />
            </button>
          </div>
        </span>
      ),
      className: "table-cell",
    },
  ];

  return (
    <Table
      columns={columns}
      data={users}
      onRowClick={(user) => setSelectedUser(user)}
      showHeader={window.innerWidth > 640}
      noDataMessage={
        isLoading ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" alt="No data" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching users.
            </span>
          </div>
        )
      }
      paginationScheme="none"
    />
  );
};

export default UsersTable;
