import { useState } from "react";
import {
  AttachmentOutlined,
  DescriptionOutlined,
  ListAltOutlined,
  TimelineOutlined,
  UnarchiveOutlined,
} from "@mui/icons-material";
import WorkOrderHistory from "../../updatedcomponents/data-display/WorkOrderHistory";
import Attachments from "../../updatedcomponents/data-display/Attachments";
import Details from "../../updatedcomponents/data-display/WorkOrderInfo";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import IncompleteNotes from "../../updatedcomponents/forms/UnableNotesForm";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { classNames } from "../../utils/FormatFunctions";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useAuth } from "../../hooks/useAuth";
import {
  BoltIcon,
  BookmarkIcon,
  CalendarIcon,
  CheckCircleIcon,
  ComputerDesktopIcon,
  DocumentTextIcon,
  KeyIcon,
  MapIcon,
  PaperClipIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import TaskNotes from "../../updatedcomponents/forms/TaskNotes";
import EditableText from "../../updatedcomponents/general-ui/EditableText";

const WorkOrderDetails: React.FC = ({}) => {
  const {
    selectedWorkOrder,
    handleWorkOrderStatusChange,
    handleDelete,
    handleReopen,
    modalShowState,
    updateModalShowState,
    handleWorkOrderTitleChange,
  } = useWorkOrder();

  const { currentUser } = useAuth();

  const dataToRender = [
    { title: "Order ID", dataTag: "shortId", dataType: "text", icon: KeyIcon },
    {
      title: "Status",
      dataTag: "status",
      dataType: "selector",
      options: ["closed", "incomplete", "unable"],
      icon: CheckCircleIcon,
    },
    {
      title: "Request Type",
      dataTag: "requestType",
      dataType: "selector",
      options: ["not-specified", "Electrical", "Plumbing", "HVAC", "Other"],
      icon: TagIcon,
    },
    {
      title: "Date Reported",
      dataTag: "dateReported",
      dataType: "datetime-local",
      icon: CalendarIcon,
    },
    { title: "Location", dataTag: "location", dataType: "text", icon: MapIcon },
    {
      title: "Requested By",
      dataTag: "requestedBy",
      dataType: "text",
      icon: UserIcon,
    },
    {
      title: "Request Origin",
      dataTag: "requestOrigin",
      dataType: "text",
      icon: ComputerDesktopIcon,
    },
    {
      title: "Urgency",
      dataTag: "urgency",
      dataType: "selector",
      options: ["urgent", "standard"],
      icon: BoltIcon,
    },
  ];

  const [navItems, setNavItems] = useState([
    {
      name: "Details",
      icon: <DocumentTextIcon className="w-4 h-4" />,
      component: Details,
      current: true,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: "Activity",
      component: WorkOrderHistory,
      icon: <BookmarkIcon className="w-4 h-4" />,
      current: false,
      access: [
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: `Attachments (${selectedWorkOrder?.attachments?.length || 0})`,
      component: Attachments,
      icon: <PaperClipIcon className="h-4 w-4" />,
      current: false,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
  ]);

  const permissions = currentUser.access;

  return (
    <div className="flex flex-col h-full overflow-hidden relative z-20">
      <EditableText
        text={selectedWorkOrder?.title ?? ""}
        onSave={(newText) => {
          if (selectedWorkOrder) {
            handleWorkOrderTitleChange(selectedWorkOrder, newText);
          }
        }}
        disableEdit={
          (permissions !== "regional" &&
            permissions !== "organization-admin") ||
          selectedWorkOrder?.status === "closed"
        }
        className="hidden sm:flex w-full truncate font-medium text-2xl text-secondary"
      />

      <div className="flex w-full sm:w-fit border-b border-secondary-1000 text-nowrap sticky items-center justify-evenly">
        {navItems.map((item, index) => {
          if (item.access.includes(permissions)) {
            return (
              <button
                key={index}
                onClick={() => {
                  setNavItems((prev) =>
                    prev.map((navItem) => {
                      return {
                        ...navItem,
                        current: navItem.name === item.name ? true : false,
                      };
                    })
                  );
                }}
                className={classNames(
                  item.current
                    ? "text-secondary-100 border-b border-accent-500"
                    : "  hover:border-secondary-700",
                  "mx-3 py-4 text-sm font-normal  w-full flex justify-center items-center gap-2"
                )}
              >
                <span className={item.current ? "text-accent-500" : ""}>
                  {item.icon}
                </span>{" "}
                {item.name}
              </button>
            );
          }
          return null;
        })}
      </div>
      <div className="flex-1 overflow-y-auto">
        {navItems
          .filter((item) => item.current)
          .map((item, index) => (
            <item.component
              key={index}
              dataToRender={dataToRender}
              handleDeleteClick={() => updateModalShowState("showDelete", true)}
              handleReopenClick={() => updateModalShowState("showReopen", true)}
              handleWorkOrderStatusChange={handleWorkOrderStatusChange}
            />
          ))}
      </div>
      <GenericModal
        title={"Delete this work order."}
        item={selectedWorkOrder}
        handleFunction={handleDelete}
        showModal={modalShowState.showDelete}
        setShowModal={() => updateModalShowState("showDelete", false)}
      />
      <GenericModal
        title={"Reopen this work order."}
        item={selectedWorkOrder}
        handleFunction={handleReopen}
        showModal={modalShowState.showReopen}
        setShowModal={() => updateModalShowState("showReopen", false)}
        color="secondary-100"
        secondaryColor="primary"
        buttonText="Reopen"
        ModalIcon={UnarchiveOutlined}
      />
      <Modal
        title="Mark as Unable to Complete"
        subtitle={selectedWorkOrder?.title}
        open={modalShowState.showUnable}
        setOpen={() =>
          updateModalShowState("showUnable", !modalShowState.showUnable)
        }
        content={
          <IncompleteNotes
            setShowNotes={() =>
              updateModalShowState("showUnable", !modalShowState.showUnable)
            }
          />
        }
      />
      <Modal
        title={"Notes"}
        open={modalShowState.showNotes}
        setOpen={() =>
          updateModalShowState("showNotes", !modalShowState.showNotes)
        }
        content={<TaskNotes />}
      />
      <GenericModal
        title={"Close out this work order."}
        item={selectedWorkOrder}
        handleFunction={() => {
          handleWorkOrderStatusChange(selectedWorkOrder, "closed");
          updateModalShowState("showCloseout", !modalShowState.showCloseout);
        }}
        showModal={modalShowState.showCloseout}
        setShowModal={() =>
          updateModalShowState("showCloseout", !modalShowState.showCloseout)
        }
        color="secondary-100"
        secondaryColor={"secondary-1000"}
        buttonText="Close Out"
      />
    </div>
  );
};

export default WorkOrderDetails;
