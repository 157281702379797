import React, { useState } from "react";
import axios from "axios";
import {
  DeleteOutline,
  PolicyOutlined,
  SourceOutlined,
} from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import { File } from "../../types/File";
import { capitalizeString, formatBytes } from "../../utils/FormatFunctions";
import { useBuilding } from "../../hooks/useBuilding";
import {
  DocumentTextIcon,
  ShieldExclamationIcon,
  EyeIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
interface FilesListProps {
  files: File[];
  type: string;
}

const FilesList: React.FC<FilesListProps> = ({ files, type }) => {
  const { loadingFile, handleFileView, handleDeleteFileClick } = useBuilding();
  const isMobile = useDeviceWidth();
  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-y-2">
        {type === "policies" ? (
          <ShieldExclamationIcon className="text-secondary-100  h-8 w-8" />
        ) : (
          <DocumentTextIcon className="text-secondary-100  h-8 w-8" />
        )}

        <h2 className="text-xl font-medium text-secondary-100">
          {capitalizeString(type)}
        </h2>
        <p className="text-sm text-secondary-500 font-light">
          View and manage your building's {type}
        </p>
      </div>
      <ul role="list" className="gap-y-2">
        {files.length > 0 ? (
          files.map((file) => (
            <li
              key={file._id}
              className="flex mt-2 items-center justify-between rounded-lg border border-secondary-900 px-3 py-4 bg-primary"
            >
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold text-secondary-100">
                    {file.name}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-secondary-500">
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="whitespace-nowrap">
                    File Size <span>{formatBytes(file.size)}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  onClick={() => handleFileView(file)}
                  disabled={loadingFile}
                  className={` rounded-lg px-2.5 py-1.5 text-sm font-normal flex justify-center items-center gap-x-1  ring-1 ring-inset ring-secondary-900 ${
                    loadingFile
                      ? " text-secondary-700 cursor-not-allowed"
                      : " text-secondary-100"
                  }`}
                >
                  <EyeIcon className="h-4 w-4" />
                  <span className="hidden sm:block">View</span>
                </button>
                <button
                  className="bg-secondary-1100 text-reds-500 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                  onClick={() => handleDeleteFileClick(file)}
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No {type} present
            </h2>
          </div>
        )}
      </ul>
    </div>
  );
};

export default FilesList;
