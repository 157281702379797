import React from "react";
import Modal from "../../updatedcomponents/general-ui/Modal";
import NewUserForm from "../../updatedcomponents/forms/NewUserForm";
import EditUserForm from "../../updatedcomponents/forms/EditUserForm";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import { useUserManagement } from "../../hooks/useUserManagement";
import Button from "../../updatedcomponents/general/Button";
import { useAuth } from "../../hooks/useAuth";
import UsersTable from "./UsersTable";

const UsersContent: React.FC = () => {
  const { currentUser } = useAuth();
  const {
    handleAddUser,
    modalShowState,
    updateModalShowState,
    searchTerm,
    handleSearch,
    createNewUser,
    selectedUser,
    handleDeleteUser
  } = useUserManagement();

  const permissions = currentUser.access === "system-admin";

  return (
    <>
      <PageHeader
        title="Users"
        mobileTitle={
            <>
              <span className="inline-flex gap-2">Users</span>
            </>
          }
        subtitle="All system users"
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={searchTerm}
              handleSearchChange={(searchTerm) => handleSearch(searchTerm)}
            />
            {permissions && (
              <Button
                icon="Add"
                children="Add User"
                onClick={handleAddUser}
                styleString="primary"
              />
            )}
          </div>
        }
      />
      
      <UsersTable />

      {/* Add User Modal */}
      <Modal
        open={modalShowState.showNewUser}
        setOpen={() => updateModalShowState("showNewUser", false)}
        title="New User"
        subtitle="Get started by filling in the information below to add a new user."
        content={
          <NewUserForm onTemplateCreate={createNewUser} />
        }
      />

      {/* Edit User Modal */}
      <Modal
        open={modalShowState.showEditUser}
        setOpen={() => updateModalShowState("showEditUser", false)}
        title="Edit User"
        subtitle="Update the user information below."
        content={<EditUserForm />}
      />

      {/* Delete User Confirmation Modal */}
      <Modal
        open={modalShowState.showDelete}
        setOpen={() => updateModalShowState("showDelete", false)}
        title="Delete User"
        subtitle="Are you sure you want to delete this user? This action cannot be undone."
        content={
          <div className="flex flex-col space-y-6">
            {selectedUser && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-sm font-medium">User Details:</p>
                <p className="text-sm">{selectedUser.firstname} {selectedUser.lastname}</p>
                <p className="text-sm text-gray-500">{selectedUser.email}</p>
              </div>
            )}
            <div className="flex justify-end space-x-3 border-t border-gray-200 pt-4">
              <Button
                onClick={() => updateModalShowState("showDelete", false)}
                styleString="secondary"
                children="Cancel"
              />
              <Button
                onClick={() => selectedUser && handleDeleteUser(selectedUser)}
                styleString="danger-filled"
                children="Delete"
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default UsersContent;