import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFacility } from "../../hooks/useFacility";
import { useBuilding } from "../../hooks/useBuilding";
import Button from "../general/Button";

interface CreateKioskFormProps {}

interface FormData {
  _id?: string | null;
  location: string;
  name: string;
  pin: string;
  confirmPin: string;
  facility: string;
}

const CreateKioskForm: React.FC<CreateKioskFormProps> = ({}) => {
  const { selectedFacility } = useFacility();
  const { updateShowState, selectedKiosk, handleCreateKiosk } = useBuilding();

  const validationSchema = yup.object().shape({
    _id: yup.string().nullable(),
    location: yup.string().required("Location is required"),
    name: yup.string().required("Name is required"),
    pin: yup.string().required("PIN is required"),
    confirmPin: yup
      .string()
      .oneOf([yup.ref("pin"), undefined], "Pins must match")
      .required("Confirm PIN is required"),
    facility: yup.string().required("Facility is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      _id: selectedKiosk?._id ?? null,
      location: selectedKiosk?.location ?? "",
      name: selectedKiosk?.name ?? "",
      pin: selectedKiosk?.pin ?? "",
      confirmPin: "",
      facility: selectedFacility,
    },
  });

  useEffect(() => {
    if (selectedKiosk) {
      setValue("_id", selectedKiosk._id);
      setValue("location", selectedKiosk.location);
      setValue("name", selectedKiosk.name);
      setValue("pin", selectedKiosk.pin);
      setValue("facility", selectedFacility);
    }
  }, [selectedKiosk, setValue, selectedFacility]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form
      onSubmit={handleSubmit(handleCreateKiosk)}
      noValidate
      autoComplete="off"
    >
      <div className="w-full px-2 mb-2">
        <div className="mt-1">
          <label className="mt-2 block text-sm font-medium text-secondary-100">
            Kiosk Location
          </label>
          <input
            type="text"
            autoComplete="off"
            className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
            {...register("location")}
          />
          <p className="text-xs text-reds-500">{errors.location?.message}</p>
        </div>
        <label className="mt-2 block text-sm font-medium text-secondary-100">
          Kiosk Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            autoComplete="off"
            className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
            {...register("name")}
          />
          <p className="text-xs text-reds-500">{errors.name?.message}</p>
        </div>
        <div className="flex w-full gap-2 mt-2 ">
          <div className="mt-1 w-1/2">
            <label className="block text-sm font-medium text-secondary-100">
              PIN
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
              {...register("pin")}
            />
            <p className="text-xs text-reds-500">{errors.pin?.message}</p>
          </div>
          <div className="mt-1 w-1/2">
            <label className="block text-sm font-medium text-secondary-100">
              Confirm Pin
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-200 sm:text-sm sm:leading-6"
              {...register("confirmPin")}
            />
            <p className="text-xs text-reds-500">
              {errors.confirmPin?.message}
            </p>
          </div>
        </div>
      </div>
      {/* Display the form error */}
      <div className="mt-6 flex flex-col sm:flex-row gap-2 ">
        <Button
          children="Cancel"
          styleString="secondary"
          onClick={() => updateShowState("showKioskAdd", false)}
          disabled={isSubmitting}
        />
        <Button
          children={!selectedKiosk ? "Create" : "Update"}
          styleString="primary"
          type="submit"
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
};

export default CreateKioskForm;
