import React from "react";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { LabelOutlined } from "@mui/icons-material";
import Button from "../general/Button";
import { useAuth } from "../../hooks/useAuth";

interface PreviewFieldProps {
  label: string;
  value: React.ReactNode;
}

const PreviewField: React.FC<PreviewFieldProps> = ({ label, value }) => (
  <div className=" pb-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
    <dt className="text-sm font-medium text-secondary-100">{label}</dt>
    <dd className="mt-1 flex text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
      <span className="flex-grow">{value}</span>
    </dd>
  </div>
);

interface RecurringTaskPreviewProps {
  taskData: any;
  onEdit?: () => void;
  onDelete?: () => void;
}

const RecurringTaskPreview: React.FC<RecurringTaskPreviewProps> = ({
  taskData,
  onEdit,
  onDelete,
}) => {
  const { currentUser } = useAuth();
  const permissions = currentUser?.access;
  const canDelete = ["regional", "organization-admin"].includes(permissions);
  const canEdit = ["regional", "organization-admin"].includes(permissions);

  const renderInputs = () => (
    <ul>
      {taskData.inputs
        .filter((field: { isDeleted: boolean }) => !field.isDeleted)
        .map((input: { name: string; dataType: string }, index: number) => (
          <div
            key={index}
            className="flex items-start justify-between gap-4 py-1"
          >
            <div className="flex items-start gap-2">
              <LabelOutlined className="text-secondary-100" fontSize="small" />
              {capitalizeString(decodeURIComponent(input.name))}
            </div>
            <span className="bg-secondary-1100 py-1 px-2 w-fit text-xs rounded-full">
              {input.dataType === "radio"
                ? "Pass/Fail"
                : input.dataType === "radio-na"
                ? "Pass/Fail/Na"
                : capitalizeString(input.dataType)}
            </span>
          </div>
        ))}
    </ul>
  );

  return (
    <div className="rounded max-w-screen-sm flex flex-col h-full p-4 scrollbar-thin sm:max-h-96 overflow-auto ">
      <div className="flex-shrink-0">
        <h2 className="text-secondary text-lg">Review Recurring Task</h2>
      </div>
      <div className="flex flex-col gap-2 flex-grow my-4 ">
        <PreviewField
          label="Task Title"
          value={capitalizeString(taskData.title)}
        />
        <PreviewField
          label="Start Date"
          value={formatDate(taskData.startDate, "YYYY-MM-DD", "utc")}
        />
        <PreviewField
          label="End Date"
          value={formatDate(taskData.endDate, "YYYY-MM-DD", "utc")}
        />
        <PreviewField
          label="Frequency"
          value={capitalizeString(taskData?.recurrence?.frequency)}
        />
        <PreviewField label="Form Inputs" value={renderInputs()} />
        <PreviewField
          label="Description"
          value={capitalizeString(taskData.details)}
        />
        <PreviewField
          label="Task Category"
          value={capitalizeString(taskData.category)}
        />
        <PreviewField
          label="Task Type"
          value={capitalizeString(taskData.taskType)}
        />
        <PreviewField
          label="Regulations"
          value={taskData.regulations?.join(", ") || "None"}
        />
        <PreviewField
          label="Attachments"
          value={taskData.attachments?.join(", ") || "No Attachments"}
        />
        <PreviewField
          label="Allow Task Skipping"
          value={taskData.skippable ? "Yes" : "No"}
        />
        <PreviewField
          label="Skip Weekends"
          value={taskData.skipWeekends ? "Yes" : "No"}
        />
      </div>

      <div className="flex w-full gap-2 items-center flex-shrink-0">
        {onEdit && canEdit && (
          <Button
            styleString="secondary"
            children="Edit"
            onClick={onEdit}
            icon="Edit"
            disabled={!canEdit}
          />
        )}
        {onDelete && canDelete && (
          <Button
            disabled={!canDelete}
            styleString="danger"
            children="Delete task"
            onClick={onDelete}
            icon="Delete"
          />
        )}
      </div>
    </div>
  );
};

export default RecurringTaskPreview;
