import React from "react";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import Button from "../general/Button";
import StatusSelector from "../general-ui/StatusSelector";
import { useAuth } from "../../hooks/useAuth";

const MultiAction = () => {
  const {
    users,
    selectedWorkOrders,
    setSelectedWorkOrders,
    workOrders,
    handleMultiAction,
  } = useWorkOrder();
  const { currentUser } = useAuth();
  const isVisible = selectedWorkOrders.length > 0;

  const [user, setUser] = React.useState<string | null>(null);
  const [status, setStatus] = React.useState<string | null>(null);

  const isActionEnabled = user !== null || status !== null;

  return (
    <div
      className={`fixed bottom-10 z-20 bg-secondary-1100 shadow right-0 sm:right-auto sm:left-1/2 -translate-x-4 sm:-translate-x-1/2 rounded-xl p-2.5 flex flex-col sm:flex-row sm:items-center items-end gap-2 transition-all duration-300 ease-in-out ${
        isVisible ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
      }`}
    >
      <span className="flex text-nowrap font-medium">
        {selectedWorkOrders.length} selected
      </span>
      {/*   <Button
        onClick={() => {
          setSelectedWorkOrders(workOrders.map((workOrder) => workOrder._id));
        }}
        styleString="secondary"
        children={"Select All"}
      /> */}
      <Button
        onClick={() => {
          setSelectedWorkOrders([]);
        }}
        children={"Deselect All"}
      />
      {currentUser?.access !== "worker" && (
        <>
          <AssignmentSelector
            users={users}
            selectedUser={
              user ? users.find((u) => u._id === user) ?? null : null
            }
            selectedValue={user}
            handleChange={(user) => {
              setUser(user);
            }}
            placeholder="Select a user"
          />
        </>
      )}
      <StatusSelector
        selectedStatus={status}
        handleChange={(status) => {
          setStatus(status);
        }}
      />
      <div className="flex gap-2">
        <Button
          onClick={() => {
            handleMultiAction(user, status);
            setUser(null);
            setStatus(null);
            setSelectedWorkOrders([]);
          }}
          disabled={!isActionEnabled}
          styleString="bold"
          icon="Check"
          children={""}
        />
        <Button
          onClick={() => {
            setUser(null);
            setStatus(null);
          }}
          styleString="danger-outline"
          icon="Close"
          children={""}
        />
      </div>
    </div>
  );
};

export default MultiAction;
