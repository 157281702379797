import React, { useEffect, useRef, useCallback } from "react";
import { DateRangeOutlined } from "@mui/icons-material";
import {
  capitalizeString,
  formatDate,
  classNames,
} from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { TaskInstance } from "../../types/TaskInstance";
import { User } from "../../types/User";
import StatusBadge from "../general-ui/StatusBadges";
import { useRecurring } from "../../hooks/useRecurring";
import RecurringTaskCard from "../cards/RecurringTaskCard";
import Spinner from "../general/Spinner";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import { useAuth } from "../../hooks/useAuth";
import { returnTypeString } from "../../utils/Helpers";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

interface ToDoTableProps {
  data: TaskInstance[];
  users: any[];
  handleLogInstanceView: (task: TaskInstance) => void;
}

const ToDoTable: React.FC<ToDoTableProps> = ({
  data,
  handleLogInstanceView,
}) => {
  const {
    tasksPerPage,
    page,
    handlePageSizeChange,
    totalPages,
    isLoading,
    handleRefresh,
    handleEndReached,
    users,
    handleTaskAssignment,
  } = useRecurring();
  const { currentUser } = useAuth();
  const { isMobile } = useDeviceWidth();

  const columns = [
    {
      label: "Task",
      key: "title",
      render: (taskInstance: TaskInstance) => (
        <>
          <span className="hidden sm:inline-flex">{taskInstance.title}</span>
          <div className="flex w-full sm:hidden">
            <RecurringTaskCard taskInstance={taskInstance} />
          </div>
        </>
      ),
      className: "truncate",
    },
    {
      label: "Due Date",
      key: "date",
      render: (taskInstance: TaskInstance) => (
        <span
          className={classNames(
            "flex items-center gap-1",
            taskInstance.status === "overdue"
              ? "text-reds-600"
              : "text-secondary-400"
          )}
        >
          <DateRangeOutlined style={{ fontSize: "1rem" }} />
          {formatDate(taskInstance.date, "MMM DD, YYYY", "utc")}
        </span>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Task Type",
      key: "taskType",
      render: (taskInstance: TaskInstance) => (
        <span>
          {taskInstance.taskType
            ? returnTypeString(
                taskInstance.taskType as
                  | "life-safety"
                  | "prev-maint"
                  | "administrative"
              )
            : "N/A"}
        </span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "status",
      render: (taskInstance: TaskInstance) => (
        <span>{<StatusBadge status={taskInstance.status} />}</span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Frequency",
      key: "frequency",
      render: (taskInstance: TaskInstance) => (
        <span>{capitalizeString(taskInstance.recurrence?.frequency)}</span>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Source",
      key: "source",
      render: (taskInstance: TaskInstance) => (
        <span>{capitalizeString(taskInstance.source)}</span>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      sortable: true,
      render: (taskInstance: TaskInstance) => (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <AssignmentSelector
              users={users}
              selectedUser={
                taskInstance.assignedTo ? taskInstance.assignedTo : null
              }
              handleChange={(user) => handleTaskAssignment(taskInstance, user)}
            />
          </div>
        </>
      ),
      className: ` ${
        currentUser.access !== "worker" ? "hidden sm:table-cell" : "hidden"
      }`,
    },
  ];

  return (
    <Table
      data={data}
      showHeader={!isMobile}
      onRowClick={handleLogInstanceView}
      columns={columns}
      paginationScheme="infiniteScroll"
      pageSize={tasksPerPage}
      page={page}
      totalPages={totalPages}
      handlePageChange={handleEndReached}
      handlePageSizeChange={handlePageSizeChange}
      noDataMessage={
        isLoading ? (
          <Spinner size="xl" />
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" alt="No data" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No tasks to display.
            </span>
          </div>
        )
      }
      loading={isLoading}
      handleRefresh={handleRefresh}
    />
  );
};

export default ToDoTable;
