import VendorsTable from "../../updatedcomponents/tables/VendorsList";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import { useVendor } from "../../hooks/useVendor";
import Button from "../../updatedcomponents/general/Button";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useAuth } from "../../hooks/useAuth";
import NewVendorForm from "../../updatedcomponents/forms/NewVendorForm";
import Modal from "../../updatedcomponents/general-ui/Modal";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
const Vendors = () => {
  const { currentUser } = useAuth();
  const {
    selectedVendor,
    searchTerm,
    handleViewVendor,
    applySearch,
    resetSearch,
    handleDelete,
    showModalState,
    updateShowModalState,
  } = useVendor();

  const permissions = currentUser.access;

  return (
    <div className="flex flex-col h-full bg-secondary-1100">
      <PageHeader
        title="Vendors"
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Vendors</span>
          </>
        }
        subtitle="All vendors servicing your facility"
        buttons={
          <>
            <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
              <SearchInput
                searchTerm={searchTerm}
                handleSearchChange={(searchTerm) => applySearch(searchTerm)}
              />
              {["regional", "organization-admin"].includes(permissions) && (
                <Button
                  icon="Add"
                  styleString="primary"
                  children="Add Vendor"
                  onClick={() => handleViewVendor(null)}
                  overrideStyles="w-full"
                />
              )}
            </div>
          </>
        }
        children={
          <button
            onClick={() => updateShowModalState("showVendor", true)}
            className="sm:hidden text-primary bg-secondary p-2.5 h-fit my-auto rounded-md"
          >
            <PlusIcon className="h-5 w-5 " aria-hidden="true" />
          </button>
        }
      />
      <div
        className={
          "border border-secondary-1000 bg-primary px-3 py-2 "
        }
      >
        <SearchInput
          searchTerm={searchTerm}
          handleSearchChange={(searchTerm) => applySearch(searchTerm)}
        />
      </div>

      <VendorsTable />
      <Modal
        open={showModalState.showVendor}
        setOpen={() => updateShowModalState("showVendor", false)}
        title={"Create Vendor"}
        subtitle="Add a new vendor to your facility"
        content={<NewVendorForm />}
      />

      <GenericModal
        title={"Delete Vendor"}
        item={selectedVendor}
        handleFunction={handleDelete}
        showModal={showModalState.showDelete}
        setShowModal={() => updateShowModalState("showDelete", false)}
        color="reds-500"
        secondaryColor={"reds-1000"}
        buttonText="Delete"
      />
    </div>
  );
};

export default Vendors;
