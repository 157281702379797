import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useFacility } from "../../hooks/useFacility";
import Button from "../general/Button";
import { fetchAllFacilities } from "../../api/services/AdminService";
import Select from "react-select";

// The component can be used in both Team and Users contexts
interface NewUserFormProps {
  onTemplateCreate?: (data: any) => Promise<void>;
}

type InputType = {
  id: string;
  label: string;
  type: string;
  placeholder: string;
};

// Define a simplified interface for context types to avoid TypeScript errors
interface ContextType {
  updateModalShowState: (modal: string, value: boolean) => void;
  createNewUser?: (data: any) => Promise<void>;
}

// Facility interface for dropdown
interface FacilityOption {
  _id: string;
  name: string;
}

// React-select option format
interface SelectOption {
  value: string;
  label: string;
}

const inputs: InputType[] = [
  {
    id: "firstname",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
  },
  {
    id: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "email@example.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "tel",
    placeholder: "(123) 456-7890",
  },
];

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  isVerified?: boolean;
  joinDate?: Date;
  facility?: any[];
}

const NewUserForm: React.FC<NewUserFormProps> = ({ onTemplateCreate }) => {
  const { currentUser } = useAuth();
  const { selectedFacility } = useFacility();
  const [facilities, setFacilities] = React.useState<FacilityOption[]>([]);
  const [facilityOptions, setFacilityOptions] = React.useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Define the context and handler variables
  let teamContext: ContextType | null = null;
  let userManagementContext: ContextType | null = null;

  // Try to get TeamContext
  try {
    // Import and use the Team context if available
    const useTeam = require("../../hooks/useTeam").useTeam;
    teamContext = useTeam();
  } catch (error) {
    // Team context not available, will use UserManagement context
  }

  // Try to get UserManagement context if onTemplateCreate is provided
  if (!teamContext && onTemplateCreate) {
    try {
      const useUserManagement =
        require("../../hooks/useUserManagement").useUserManagement;
      userManagementContext = useUserManagement();
    } catch (error) {
      // UserManagement context not available
    }
  }

  // Determine which context to use
  const activeContext = teamContext || userManagementContext;

  // Use appropriate handlers based on available context
  const updateModalShowState = activeContext?.updateModalShowState;
  const createNewUser = teamContext?.createNewUser || onTemplateCreate;

  const validationSchema = yup.object().shape({
    firstname: yup.string().required("*Required field"),
    lastname: yup.string().required("*Required field"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("*Required field"),
    phone: yup.string().required("*Required field"),
    access: yup.string().required("*Required field"),
    isVerified: yup.boolean(),
    joinDate: yup.date(),
    facility: yup.array().min(1, "Select at least one facility"),
  });

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      facility: teamContext ? [selectedFacility] : [],
      access: "worker",
    },
  });

  // Fetch facilities directly
  useEffect(() => {
    const fetchFacilitiesData = async () => {
      if ((currentUser.access === "system-admin" || 
           currentUser.access === "regional" || 
           currentUser.access === "organization-admin") && 
          !teamContext) {
        try {
          setIsLoading(true);
          const response = await fetchAllFacilities();
          
          // Check the actual structure of the response
          let facilitiesData: FacilityOption[] = [];
          if (Array.isArray(response)) {
            facilitiesData = response;
          } else if (response && Array.isArray(response.data)) {
            facilitiesData = response.data;
          } else {
            console.error("Unexpected facilities data structure:", response);
          }
          
          setFacilities(facilitiesData);
          
          // Convert facilities to options format for react-select
          const options = facilitiesData.map(facility => ({
            value: facility._id,
            label: facility.name
          }));
          setFacilityOptions(options);
          
        } catch (error) {
          console.error("Error fetching facilities:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFacilitiesData();
  }, [currentUser.access, teamContext]);

  // Reset form after successful submission
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleCancel = () => {
    if (updateModalShowState) {
      updateModalShowState("showNewUser", false);
    }
  };

  // Custom submit handler to format the data correctly
  const onSubmit = async (data: FormValues) => {
    if (createNewUser) {
      // Convert selected facility options back to an array of facility IDs
      await createNewUser(data);
    }
  };

  const data = getValues();
  
  // Check if we should show the facility dropdown
  const shouldShowFacilityDropdown = 
    (currentUser.access === "system-admin" || 
     currentUser.access === "regional" || 
     currentUser.access === "organization-admin") && 
    !teamContext;

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-col overflow-y-scroll hide-scrollbar-arrows"
        noValidate
      >
        <div className="grid grid-cols-2 gap-4 pb-4">
          {inputs.map((input) => (
            <div key={input.id}>
              <label
                htmlFor={input.id}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {input.label}
              </label>
              <input
                type={input.type}
                placeholder={input.placeholder}
                className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
                {...register(input.id as keyof FormValues)}
              />
              {errors && errors[input.id as keyof FormValues] && (
                <p className="text-reds-500 text-xs">
                  {errors[input.id as keyof FormValues]?.message}
                </p>
              )}
            </div>
          ))}
        </div>
        {(currentUser.access === "regional" ||
          currentUser.access === "organization-admin" ||
          currentUser.access === "system-admin") && (
          <div key={"access"} className="mt-4">
            <label
              htmlFor="access"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Access
            </label>

            <div className="sm:col-span-2">
              <select
                id={"access"}
                className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
                {...register("access")}
              >
                <option value={"worker"}>Worker</option>
                <option value={"regional"}>Regional</option>
                <option value={"director"}>Director</option>
                <option value={"administrator"}>Administrator</option>
                {currentUser.access === "system-admin" && (
                  <option value={"system-admin"}>System Admin</option>
                )}
              </select>
            </div>
          </div>
        )}
        
        {/* Multi-select Facility selector */}
        {shouldShowFacilityDropdown && (
          <div key={"facility-selector"} className="mt-4">
            <label
              htmlFor="facility"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Facilities
            </label>
            <div className="sm:col-span-2">
              <Controller
                name="facility"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={facilityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={isLoading ? "Loading facilities..." : "Select facilities"}
                    isLoading={isLoading}
                    onChange={(selectedOptions) => {
                      // Extract facility IDs from selected options
                      const facilityIds = selectedOptions
                        ? selectedOptions.map(option => option.value)
                        : [];
                      setValue('facility', facilityIds);
                    }}
                    value={facilityOptions.filter(option => 
                      field.value && field.value.includes(option.value)
                    )}
                    styles={{
                      input: (base) => ({
                        "input:focus": {
                          boxShadow: "none",
                        },
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                        primary25: "lightgray",
                      },
                    })}
                  />
                )}
              />
              {errors.facility && (
                <p className="text-reds-500 text-xs mt-1">
                  {errors.facility.message?.toString()}
                </p>
              )}
            </div>
          </div>
        )}

        <div className="flex w-full gap-2 justify-between border-t border-secondary-1000 mt-4">
          <Button
            type="button"
            styleString="secondary"
            onClick={handleCancel}
            children="Cancel"
            overrideStyles="w-full"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Submit"
            disabled={isSubmitting}
            overrideStyles="w-full"
          />
        </div>
      </form>
    </div>
  );
};

export default NewUserForm;