import React, { useState, useContext, useRef } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { FacilityContext } from "../../context/FacilityContext";
import { Close } from "@mui/icons-material";
import { useFacility } from "../../hooks/useFacility";
import { useBuilding } from "../../hooks/useBuilding";
import Button from "../general/Button";
import { PaperClipIcon } from "@heroicons/react/24/outline";

interface FileUploadProps {}

const FileUpload: React.FC<FileUploadProps> = ({}) => {
  const { updateShowState, fileType, handleCreateFile } = useBuilding();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<{ file: FileList }>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const files = watch("file");

  const selectedFile = files && files.length > 0 ? files[0] : null;

  return (
    <form onSubmit={handleSubmit(handleCreateFile)}>
      <Controller
        name="file"
        control={control}
        defaultValue={undefined}
        render={({ field }) => (
          <div
            className="cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-secondary-1000 p-12 text-center hover:border-secondary-900 focus:outline-none focus:ring-2 focus:ring-accent-100 focus:ring-offset-2"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              field.onChange(e.dataTransfer.files);
            }}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => field.onChange(e.target.files)}
              style={{ display: "none" }}
            />
            <div className="flex justify-center items-center bg-secondary-1100 border border-secondary-1000 w-fit mx-auto rounded-lg p-2">
              <PaperClipIcon className="mx-auto h-12 w-12 text-accent-300" />
            </div>
            {selectedFile ? (
              <p className="w-52 mt-4 block text-sm font-light text-secondary-500">
                Selected File: {selectedFile.name}
              </p>
            ) : (
              <p className="w-52 mt-4 block text-sm  text-secondary-500">
                Drag and drop a {fileType} here or click to select
              </p>
            )}
          </div>
        )}
      />
      <div className="flex gap-2 pt-2 w-full">
        <Button
          children="Cancel"
          styleString="secondary"
          onClick={() => updateShowState("showFileAdd", false)}
          disabled={isSubmitting}
        />
        <Button
          children="Upload"
          styleString="primary"
          type="submit"
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
};

export default FileUpload;
