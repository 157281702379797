import { Fragment, FC } from "react";
import {
  EmailOutlined,
  Person,
  PhoneAndroidOutlined,
  Search,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  formatDate,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { User } from "../../types/User";
import { useAuth } from "../../hooks/useAuth";
import Table from "../general/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTeam } from "../../hooks/useTeam";
import Spinner from "../general/Spinner";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

const TeamTable: FC = () => {
  const { team, permissionToNavigate, onUserClick, isLoading } = useTeam();
  const { isMobile } = useDeviceWidth();

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: false,
      render: (user: User) => (
        <>
          <div className="whitespace-nowrap py-2 px-6 text-sm flex w-full">
            <div className="flex w-full flex-col ">
              <div className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                  <div className="h-7 w-7 flex items-center justify-center flex-shrink-0 bg-secondary-1000 rounded-full">
                    {user.avatar ? (
                      <img
                        className="h-7 w-7  rounded-full"
                        src={user.avatar}
                        alt=""
                      />
                    ) : (
                      <Person className="h-6 w-6 rounded-full" />
                    )}
                  </div>
                  <div className="font-medium text-secondary-100">
                    {user.firstname} {user.lastname}
                  </div>
                </div>

                {/*
                <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(user.phone)}
                </div>
                <div className="mt-2 sm:hidden">
                  {user.isVerified === true ? (
                    <span className="inline-flex items-center rounded-md bg-accent-1000 px-2 py-1 text-xs font-medium text-accent-500 ring-1 ring-inset ring-accent-500/20">
                      <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                      Verified
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-reds-1100 px-2 py-1 text-xs  text-reds-500 ring-1 ring-inset ring-reds-500/20">
                      <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                      Unverified
                    </span>
                  )}
                </div> */}
                <div className="sm:hidden">
                  {user.isVerified === true ? (
                    <span className="inline-flex items-center rounded-md bg-accent-1000 px-2 py-1 text-xs font-medium text-accent-500 ring-1 ring-inset ring-accent-500/20">
                      <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                      Verified
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-reds-1100 px-2 py-1 text-xs  text-reds-500 ring-1 ring-inset ring-reds-500/20">
                      <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                      Unverified
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col -mx-6 sm:mx-2 px-6 gap-2 py-2 border-b border-secondary-1000 sm:border-none">
                <div className=" text-secondary-400 gap-2 flex items-center w-full">
                  <EmailOutlined style={{ fontSize: "1rem" }} />
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {user.email}
                  </span>
                </div>
                <div className=" text-secondary-400 gap-2 flex items-center w-full">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(user.phone)}
                </div>
              </div>
              {isMobile && (
                <div className="flex w-full justify-between py-2">
                  <div className="flex flex-col">
                    <span className="text-secondary-500 text-sm ">Role</span>
                    <span className="text-secondary-100 text-sm ">
                      {capitalizeString(user.access)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-secondary-500 text-sm ">
                      Date Joined
                    </span>
                    <span className="text-secondary-100 text-sm ">
                      {formatDate(user.joinDate)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ),
      className: "sm:w-[400px] max-w-[400px]",
    },
    {
      label: "Date Joined",
      key: "joinDate",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap py-5 text-sm text-secondary-400">
            {formatDate(user.joinDate)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "isVerified",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap py-5 text-sm text-secondary-400">
            {user.isVerified === true ? (
              <span className="inline-flex items-center rounded-md bg-accent-1000 px-2 py-1 text-xs  text-accent-500 ring-1 ring-inset ring-accent-500/20">
                <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                Verified
              </span>
            ) : (
              <span className="inline-flex items-center rounded-md bg-reds-1100 px-2 py-1 text-xs  text-reds-500 ring-1 ring-inset ring-reds-500/20">
                <CheckBadgeIcon className="w-4 h-4 text-inherit mr-1" />
                Unverified
              </span>
            )}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Role",
      key: "type",
      sortable: false,
      render: (user: User) => (
        <>
          <span className="whitespace-nowrap py-5 text-sm text-secondary-400">
            {capitalizeString(user.access)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];
  return (
    <Table
      columns={columns}
      data={team}
      onRowClick={(user) => onUserClick(user)}
      showHeader={!isMobile}
      isClickable={permissionToNavigate}
      noDataMessage={
        isLoading ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching users.
            </span>
          </div>
        )
      }
      paginationScheme="none"
    />
  );
};

export default TeamTable;
