import React, { useState, useContext } from "react";
import axios from "axios";
import { Form, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import { Link } from "react-router-dom";
import { NotificationContext } from "../../context/PushNotificationContext";
import { DeviceTabletIcon, UserIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

interface AccountFormValues {
  email: string;
  password: string;
  general?: string;
}

interface KioskFormValues {
  kioskName: string;
  pin: string;
  general?: string;
}

const accountSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required"),
  general: Yup.string().optional(),
});

const kioskSchema = Yup.object().shape({
  kioskName: Yup.string().required("Kiosk name is required"),
  pin: Yup.string().required("Pin is required"),
  general: Yup.string().optional(),
});

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState<"account" | "kiosk">("account");
  const { handleFacilityChange } = useContext(FacilityContext);
  const { dispatch } = useAuth();
  const { isMobile } = useDeviceWidth();

  const {
    register: registerAccount,
    handleSubmit: handleSubmitAccount,
    setError: setErrorAccount,
    formState: { errors: errorsAccount },
  } = useForm<AccountFormValues>({
    resolver: yupResolver(accountSchema),
  });

  const {
    register: registerKiosk,
    handleSubmit: handleSubmitKiosk,
    setError: setErrorKiosk,
    formState: { errors: errorsKiosk },
  } = useForm<KioskFormValues>({
    resolver: yupResolver(kioskSchema),
  });

  const onSubmitAccount = async (data: AccountFormValues) => {
    const user = { email: data.email, password: data.password };

    try {
      const response = await axios.post("/api/authenticate/user", user);
      const data = response.data;

      if (data.token) {
        localStorage.setItem("token", data.token);
        const userWithoutPassword = { ...data.user };
        delete userWithoutPassword.password;

        const userWithOrganization = {
          ...userWithoutPassword,
        };
        localStorage.setItem("user", JSON.stringify(userWithOrganization));
        const defaultFacility = userWithOrganization.lastLoggedFacility
          ? userWithOrganization.lastLoggedFacility
          : userWithOrganization.facility.length > 0
          ? userWithOrganization.facility[0]._id
          : "";
        handleFacilityChange(defaultFacility, userWithOrganization._id);
        dispatch({ type: "LOGIN", payload: userWithOrganization });
        const path =
          userWithOrganization.access === "worker" ? "/work-orders" : "/home";
        navigate(path);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setErrorAccount("general", { message: "Invalid email or password" });
      } else {
        setErrorAccount("general", {
          message:
            "Oops, looks like we are having difficulty logging you in. Try again later",
        });
      }
      console.log(error);
    }
  };

  const onSubmitKiosk = async (data: KioskFormValues) => {
    try {
      const { kioskName, pin } = data;

      const response = await axios.post(
        "/api/kiosk/login",
        {
          kioskName,
          pin,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.data;

        if (data.token) {
          localStorage.setItem("token", data.token);
          const kiosk = { ...data.kiosk };

          localStorage.setItem("kiosk", JSON.stringify(kiosk));

          dispatch({ type: "LOGIN", payload: kiosk });
          navigate("/kiosks/request");
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setErrorKiosk("general", { message: "Kiosk Not Found" });
        } else if (error.response.status === 401) {
          setErrorKiosk("general", { message: "Invalid credentials" });
        } else {
          setErrorKiosk("general", {
            message:
              "Oops, looks like we are having difficulty logging you in. Try again later",
          });
        }
      } else {
        setErrorKiosk("general", {
          message:
            "Oops, looks like we are having difficulty logging you in. Try again later",
        });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (loginType === "account") {
        handleSubmitAccount(onSubmitAccount)();
      } else {
        handleSubmitKiosk(onSubmitKiosk)();
      }
    }
  };

  return (
    <div className="LoginWithAccount relative flex h-screen flex-1 flex-col items-center sm:justify-center sm:bg-secondary-1100 overflow-clip">
      <div className="hidden sm:block sm:absolute -top-80 -left-0">
        <img
          src="/sequra_pattern.svg"
          className="scale-[2]"
          alt="SVG Clipping"
        />
      </div>
      <div className="hidden sm:block sm:absolute -bottom-[500px] right-0">
        <img
          src="/sequra_pattern.svg"
          className="scale-[3]"
          alt="SVG Clipping"
        />
      </div>
      <div className="Content z-20 sm:my-4 overflow-y-auto h-fit max-h-screen w-screen sm:max-w-sm lg:max-w-lg sm:px-8 pt-8 sm:py-6 relative bg-primary rounded-xl sm:shadow flex-col justify-start items-center gap-6 inline-flex">
        <div className="Logogram w-full h-10 sm:py-0.5 justify-center items-center inline-flex">
          <img
            src="\sequra_logo__primary_black.png"
            alt="SEQURA logo"
            className="w-40 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="Header self-stretch h-24 flex-col justify-start items-center gap-2 sm:gap-6 flex">
          <div className="HiWelcomeBack text-secondary-100 text-3xl sm:text-4xl font-medium font-header leading-10">
            Welcome to Sequra
          </div>
          <div className="PleaseLoginToContinue text-secondary-400 text-base sm:text-2xl font-normal leading-loose">
            Please login to continue
          </div>
        </div>
        <div className="Content self-stretch h-full  flex-col justify-start items-start sm:gap-8 flex">
          <div className="SegmentedTabs self-stretch p-1 pb-0 sm:bg-secondary-1100 sm:rounded justify-start items-center inline-flex sm:flex-row">
            <button
              onClick={() => setLoginType("account")}
              className={`SegmentedItem w-full grow shrink basis-0 h-10 px-3 py-2.5 ${
                loginType === "account"
                  ? "text-accent-300 sm:text-primary border-b-2 border-accent-300 sm:border-b-0 sm:bg-secondary-100 sm:rounded sm:shadow font-medium"
                  : "text-secondary-100 font-normal"
              } justify-center items-center gap-2.5 flex`}
            >
              <UserIcon className="User w-5 h-5 relative" />
              <div className="Label text-center text-inherit text-sm leading-tight">
                Login with Account
              </div>
            </button>
            <button
              onClick={() => setLoginType("kiosk")}
              className={`SegmentedItem w-full grow shrink basis-0 h-10 px-3 py-2.5 ${
                loginType === "kiosk"
                  ? "text-accent-300 sm:text-primary border-b-2 border-accent-300 sm:border-b-0 sm:bg-secondary-100 sm:rounded sm:shadow font-medium"
                  : "text-secondary-100 font-normal"
              } justify-center items-center gap-2.5 flex`}
            >
              <DeviceTabletIcon className="Devicetabletspeaker w-5 h-5 relative" />
              <div className="Label text-center text-inherit text-sm leading-tight">
                Login with Kiosk
              </div>
            </button>
          </div>

          {loginType === "account" ? (
            <form
              className="Forms px-4 sm:px-0 bg-secondary-1100 sm:bg-primary self-stretch flex-col justify-start items-start gap-4 sm:gap-8 flex"
              onSubmit={handleSubmitAccount(onSubmitAccount)}
              onKeyDown={handleKeyDown}
            >
              <div className="Form self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                  <div className="Label self-stretch h-6 flex-col justify-center items-start gap-1 flex">
                    <label className="InputLabel hidden sm:block text-secondary-100 text-base font-normal leading-normal">
                      Email address
                    </label>
                  </div>
                  <input
                    id="email"
                    type="email"
                    placeholder="Email Address"
                    {...registerAccount("email")}
                    className={`
                      ${
                        errorsAccount.email
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset"`}
                  />
                  {errorsAccount.email && (
                    <p className="Emailerror text-reds-500 text-xs font-normal leading-none">
                      {errorsAccount.email.message}
                    </p>
                  )}
                </div>
                <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                  <div className="Label self-stretch h-0 sm:h-6 flex-col justify-center items-start gap-1 flex">
                    <div className=" hidden sm:flex w-full justify-between">
                      <label className="InputLabel text-secondary-100 text-base font-normal leading-normal">
                        Password
                      </label>
                      <Link
                        to="/forgot-password"
                        className="font-semibold text-sm text-accent-100 hover:text-accent-200"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    {...registerAccount("password")}
                    className={`
                      ${
                        errorsAccount.password
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset"`}
                  />
                  <Link to={"/forgot-password"} className="ForgotPassword text-secondary-700 text-xs flex sm:hidden w-full justify-end">
                    Forgot password?
                  </Link>
                  {errorsAccount.password && (
                    <p className="PasswordError text-reds-500 text-xs font-normal leading-none">
                      {errorsAccount.password.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="pt-4 sm:pt-0 flex w-full">
                <Button
                  children="Log In"
                  onClick={handleSubmitAccount(onSubmitAccount)}
                  styleString={isMobile ? "primary" : "bold"}
                />
              </div>
              {errorsAccount.general && (
                <p className="text-reds-500 text-xs mx-auto">
                  {errorsAccount.general.message}
                </p>
              )}
            </form>
          ) : (
            <form
              className="Forms px-4 sm:px-0 bg-secondary-1100 sm:bg-primary self-stretch flex-col justify-start items-start gap-4 sm:gap-8 flex"
              onSubmit={handleSubmitKiosk(onSubmitKiosk)}
              onKeyDown={handleKeyDown}
            >
              <div className="Form self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                  <div className="Label self-stretch h-6 flex-col justify-center items-start gap-1 flex">
                    <label className="InputLabel hidden sm:block text-secondary-100 text-base font-normal leading-normal">
                      Kiosk name
                    </label>
                  </div>
                  <input
                    id="kioskName"
                    type="text"
                    placeholder="Enter your kiosk name"
                    {...registerKiosk("kioskName")}
                    className={`
                      ${
                        errorsKiosk.kioskName
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset"`}
                  />
                  {errorsKiosk.kioskName && (
                    <p className="KioskNameError text-reds-500 text-xs font-normal leading-none">
                      {errorsKiosk.kioskName.message}
                    </p>
                  )}
                </div>
                <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                  <div className="Label self-stretch h-0 sm:h-6 flex-col justify-center items-start gap-1 flex">
                    <label className="InputLabel hidden sm:block text-secondary-100 text-base font-normal leading-normal">
                      Kiosk PIN
                    </label>
                  </div>
                  <input
                    id="pin"
                    type="password"
                    placeholder="Enter your PIN"
                    {...registerKiosk("pin")}
                    className={`
                      ${
                        errorsKiosk.pin
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset"`}
                  />
                  {errorsKiosk.pin && (
                    <p className="PinError text-reds-500 text-xs font-normal leading-none">
                      {errorsKiosk.pin.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full">
                <Button
                  children="Log In"
                  onClick={handleSubmitKiosk(onSubmitKiosk)}
                  styleString={isMobile ? "primary" : "bold"}
                />
              </div>
              {errorsKiosk.general && (
                <p className="text-reds-500 text-xs mx-auto">
                  {errorsKiosk.general.message}
                </p>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
