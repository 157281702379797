import { Fragment } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  ArrowBack,
  CalendarMonthOutlined,
  CheckCircleOutlined,
  ChecklistOutlined,
  Close,
  DeleteOutline,
  Email,
  EmailOutlined,
  Person,
  PhoneOutlined,
  RemoveCircleOutlineOutlined,
  Sms,
  Verified,
  Warning,
  WorkOutline,
} from "@mui/icons-material";
import MirrorDensityPlot from "../../updatedcomponents/data-display/MirrorDensityPlot";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  formatDate,
  formatPhoneNumber,
} from "../../utils/FormatFunctions";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { WorkOrder } from "../../types/WorkOrder";
import { useAuth } from "../../hooks/useAuth";
import { useTeam } from "../../hooks/useTeam";
import { TaskInstance } from "../../types/TaskInstance";
import Widget from "../../updatedcomponents/data-display/Widget";
import { useNavigate } from "react-router-dom";
import Spinner from "../../updatedcomponents/general/Spinner";
import {
  ArrowRightIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

const UserPage = () => {
  const {
    selectedUser,
    modalShowState,
    updateModalShowState,
    userWorkOrders,
    userTasks,
    workOrderHistory,
    handleViewTask,
    handleDeleteUser,
  } = useTeam();
  const { isMobile } = useDeviceWidth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const permissions = currentUser.access;

  // const assignedWorkOrderTable = (
  //   tasks: WorkOrder[] | TaskInstance[],
  //   type: string,
  //   message: string
  // ) => (
  //   <div className="flex-1 px-4 sm:px-6  border border-secondary-1000 rounded-lg  h-96 overflow-y-scroll  hide-scrollbar-arrows">
  //     <h2 className="py-4 text-md text-secondary-300 font-semibold">
  //       Assigned {type}
  //     </h2>
  //     <table className="min-w-full">
  //       <thead>
  //         <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
  //           <th
  //             scope="col"
  //             className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 "
  //           >
  //             Title
  //           </th>
  //           <th
  //             scope="col"
  //             className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
  //           >
  //             ID
  //           </th>
  //           <th
  //             scope="col"
  //             className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
  //           >
  //             Status
  //           </th>
  //           <th scope="col" className="relative py-0.5 pl-3 pr-4 sm:pr-6">
  //             <span className="sr-only">View</span>
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {tasks.length === 0 ? (
  //           <Fragment>
  //             <tr className="h-2"></tr>
  //             <tr>
  //               <td
  //                 colSpan={4}
  //                 className="text-center text-md py-8 bg-secondary-1200 border border-secondary-1000"
  //               >
  //                 <div className="flex flex-col items-center justify-center text-sm gap-2 text-secondary-400">
  //                   <ChecklistOutlined style={{ fontSize: "2rem" }} />
  //                   {message}
  //                 </div>
  //               </td>
  //             </tr>
  //           </Fragment>
  //         ) : (
  //           tasks.map((task) => (
  //             <Fragment key={`${task._id}-${type}`}>
  //               <tr key={`${task._id}-gap`} className="h-2"></tr>
  //               <tr
  //                 key={task._id}
  //                 className=" border border-secondary-1000 text-secondary-100"
  //               >
  //                 <td className="truncate max-w-12 whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-secondary-100 ">
  //                   {task.title}
  //                 </td>
  //                 <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
  //                   {task?.shortId}
  //                 </td>
  //                 <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
  //                   {<StatusBadge status={task.status} />}
  //                 </td>
  //                 <td className="whitespace-nowrap px-3 py-1.5 text-sm ">
  //                   <button
  //                     onClick={() => handleViewTask(task, type)}
  //                     className=" px-2 py-1 text-sm font-semibold text-secondary-100 underline  hover:text-secondary-300"
  //                   >
  //                     View
  //                   </button>
  //                 </td>
  //               </tr>
  //             </Fragment>
  //           ))
  //         )}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  const deactivate = (
    <div className="flex-1 flex items-center gap-4">
      <button
        onClick={() => updateModalShowState("showDelete", true)}
        className="bg-reds-500 text-primary text-sm hover:bg-reds-600 px-2 py-1 rounded  shadow cursor-pointer flex items-center gap-2"
      >
        <DeleteOutline style={{ fontSize: "1rem" }} />
        Delete user
      </button>
      <p className="text-secondary-500 text-xs">
        This action can not be undone
      </p>
    </div>
  );

  return (
    <div className="bg-secondary-1100">
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => window.history.back()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
          </button>
        }
        subtitle={""}
        mobileTitle={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => window.history.back()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
          </button>
        }
        buttons={<></>}
      />
      {selectedUser ? (
        <div>
          {selectedUser ? (
            <div className="sm:p-4 flex sm:flex-row flex-col-reverse w-full gap-4">
              {isMobile && (
                <div className="flex p-4">
                  {["regional", "organization-admin"].includes(permissions) &&
                    deactivate}
                </div>
              )}
              <div className="flex flex-1 max-w-[900px]">
                {<MirrorDensityPlot data={workOrderHistory} />}
              </div>

              <div className="flex flex-col sm:w-fit justify-center items-start rounded-lg bg-primary border border-secondary-1000 h-fit">
                <div className="flex w-full justify-between p-4">
                  <div className="flex-shrink-0 ">
                    {selectedUser.avatar ? (
                      <img
                        className="h-14 w-14  rounded-full"
                        src={selectedUser.avatar}
                        alt=""
                      />
                    ) : (
                      <Person className="h-11 w-11 rounded-full" />
                    )}
                  </div>
                  <div className="flex flex-col ">
                    <div className="flex gap-2 justify-end">
                      {selectedUser.isActive === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-accent-1000 px-2 py-1 text-xs  text-accent-500 ring-1 ring-inset ring-accent-500/20">
                          <CheckCircleOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Active
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs  text-reds-500 ring-1 ring-inset ring-reds-600/20">
                          <Close
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Inactive
                        </div>
                      )}
                      {selectedUser.isVerified === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-accent-1000 px-2 py-1 text-xs  text-accent-500 ring-1 ring-inset ring-accent-500/20">
                          <Verified
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Verified
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-green-600/20">
                          <Verified
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Unverified
                        </div>
                      )}
                    </div>
                    <div className="text-secondary-500 text-xs pt-2 flex items-center gap-2">
                      Join Date:{" "}
                      {formatDate(selectedUser.joinDate, "YYYY-MM-DD h:mm a")}{" "}
                    </div>
                  </div>
                </div>
                <div className="pb-4">
                  <div className="flex flex-col items-start px-4 pb-2 border-b border-secondary-1000 w-full">
                    <h2 className="text-xl">
                      {capitalizeString(selectedUser.firstname)}{" "}
                      {capitalizeString(selectedUser.lastname)}
                    </h2>
                    <div className="text-secondary-300 text-sm flex items-center gap-2">
                      {capitalizeString(selectedUser.access)}
                    </div>
                  </div>
                  <div className="gap-y-2 flex flex-col px-4 py-2 w-full">
                    <span className="text-secondary-500 text-sm font-medium">
                      Contact Information
                    </span>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <EmailOutlined style={{ fontSize: "1rem" }} />{" "}
                      {selectedUser.email}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <PhoneOutlined style={{ fontSize: "1rem" }} />{" "}
                      {formatPhoneNumber(selectedUser.phone)}
                    </div>

                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      {selectedUser.smsNotify === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-accent-1000 px-2 py-1 text-xs  text-accent-500 ring-1 ring-inset ring-accent-500/20">
                          <Sms className="mr-1" style={{ fontSize: ".9rem" }} />{" "}
                          SMS Notifications Enabled
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-green-600/20">
                          <RemoveCircleOutlineOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          SMS Notifications Disabled
                        </div>
                      )}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      {selectedUser.emailNotify === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-accent-1000 px-2 py-1 text-xs  text-accent-500 ring-1 ring-inset ring-accent-500/20">
                          <Email
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />
                          Email Notifications Enabled
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-green-600/20">
                          <RemoveCircleOutlineOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Email Notifications Disabled
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col py-6 gap-4">
                      <div
                        className="flex border border-secondary-1000 rounded-lg p-4"
                        onClick={() => {
                          navigate(
                            `/work-orders?filters={"assignedTo":"${selectedUser._id}"}`
                          );
                        }}
                      >
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex w-full justify-between">
                            <div className="flex items-center gap-2">
                              <BriefcaseIcon className="w-4 h-4" />
                              <span className="text-secondary-500 text-sm font-medium">
                                Assigned Work Orders
                              </span>
                            </div>
                            <div className="text-secondary-500 text-xl font-medium">
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                          </div>
                          <span className="text-secondary-500 text-xl font-medium">
                            {userWorkOrders.length}
                          </span>
                        </div>
                      </div>

                      <div
                        className="flex border border-secondary-1000 rounded-lg p-4"
                        onClick={() => {
                          navigate(
                            `/tasks?filters={"assignedTo":"${selectedUser._id}"}`
                          );
                        }}
                      >
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex w-full justify-between">
                            <div className="flex items-center gap-2">
                              <CalendarDaysIcon className="w-4 h-4" />
                              <span className="text-secondary-500 text-sm font-medium">
                                Assigned Recurring Tasks
                              </span>
                            </div>
                            <div className="text-secondary-500 text-xl font-medium">
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                          </div>
                          <span className="text-secondary-500 text-xl font-medium">
                            {userTasks.length}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      {["regional", "organization-admin"].includes(
                        permissions
                      ) &&
                        !isMobile && <div className="flex">{deactivate}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}

          {/* <div className="flex gap-4 flex-col md:flex-row mx-4">
            {assignedWorkOrderTable(
              userWorkOrders,
              "Work Orders",
              "No work orders found"
            )}
            {assignedWorkOrderTable(
              userTasks,
              "Recurring Tasks",
              "No recurring tasks found"
            )}
          </div> */}

          <GenericModal
            title={"Delete Account"}
            handleFunction={handleDeleteUser}
            showModal={modalShowState.showDelete}
            setShowModal={() => updateModalShowState("showDelete", false)}
            buttonText={"Delete"}
            ModalIcon={Warning}
            item={selectedUser}
          />
        </div>
      ) : (
        <div className="flex flex-col flex-grow justify-center items-center h-full">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default UserPage;
