import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFacility } from "../../hooks/useFacility";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Button from "../general/Button";
import { File } from "../../types/File";
import RecurringTaskPreview from "../data-display/RecurringTaskPreview";
import { validationSchema } from "../../validation/recurringTaskValidation";
import { getFormInputs, formSteps } from "../../config/recurringTaskFormConfig";
import FormContent from "./FormContent";
import TemplateSelection from "./TemplateSelection";
import { useNavigate } from "react-router-dom";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

interface RecurringTaskFormProps {}

export interface NewRecurringTaskFormData {
  taskType: string;
  category: string;
  title: string;
  startDate: string;
  endDate?: string;
  recurrence: {
    frequency: string;
  };
  source: string;
  details: string;
  inputs: {
    isDeleted?: boolean;
    name: string;
    dataType: string;
    options?: string[];
    validator: {
      lowerLimit?: number | null;
      upperLimit?: number | null;
      required?: boolean;
      requireNote?: boolean;
    };
  }[];
  notifyVendor: boolean;
  skipWeekends: boolean;
  vendor?: string;
  map?: string;
  attachments: string[];
  sourceTemplateId: string;
  facility: string;
  newInput?: {
    name: string;
    dataType: string;
    validator: {
      lowerLimit?: number | null;
      upperLimit?: number | null;
      required?: boolean;
    };
  };
  skippable?: boolean;
  regulations?: string[];
}

const RecurringTaskForm: React.FC<RecurringTaskFormProps> = ({}) => {
  const { selectedFacility } = useFacility();
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();
  const {
    selectedTask,
    updateShowModalState,
    createTask,
    updateTask,
    templates,
    attachments,
    maps,
    vendors,
    tasks,
    isEditing,
    setIsEditing,
  } = useTaskScheduler();

  const attachmentOptions = attachments.map((document: File) => ({
    value: document._id,
    label: document.name,
  }));

  const [steps, setSteps] = useState(formSteps(isEditing));
  const currentStepCount = steps.find((step) => step.current)?.stepCount || 1;

  const methods = useForm<NewRecurringTaskFormData>({
    defaultValues: {
      notifyVendor: false,
      skipWeekends: false,
      attachments: [],
      vendor: "",
      map: "",
      sourceTemplateId: "",
      taskType: "",
      category: "",
      title: "",
      startDate: "",
      endDate: "",
      recurrence: {
        frequency: "",
      },
      source: "",
      details: "",
      inputs: [],
      facility: selectedFacility,
      newInput: {
        name: "",
        dataType: "",
        validator: {
          required: true,
        },
      },
      regulations: [],
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const toggleStep = async (stepCount: number) => {
    if (currentStepCount === 2 && stepCount > currentStepCount) {
      const isValid = await methods.trigger();

      if (isValid) {
        setSteps(
          steps.map((item) =>
            item.stepCount === stepCount
              ? { ...item, current: true }
              : { ...item, current: false }
          )
        );
      } else {
        await methods.trigger();
      }
    } else {
      setSteps(
        steps.map((item) =>
          item.stepCount === stepCount
            ? { ...item, current: true }
            : { ...item, current: false }
        )
      );
    }
  };

  const selectedTemplateId = methods.watch("sourceTemplateId");
  const selectedTemplate = templates.find(
    (template) => template._id === selectedTemplateId
  );

  useEffect(() => {
    if (isEditing && selectedTask) {
      const { _id, ...formValues } = selectedTask;
      const formattedValues = {
        ...formValues,
        startDate: formValues.startDate
          ? new Date(formValues.startDate).toISOString().split("T")[0]
          : "",
        endDate: formValues.endDate
          ? new Date(formValues.endDate).toISOString().split("T")[0]
          : "",
        vendor: formValues.vendor || "",
        map: formValues.map || "",
        newInput: {
          name: "",
          dataType: "",
          validator: {
            required: true,
          },
        },
      };
      methods.reset(formattedValues as any);
    } else if (!isEditing && selectedTemplate) {
      methods.setValue("title", selectedTemplate.title || "");
      methods.setValue("taskType", selectedTemplate.taskType || "");
      methods.setValue("category", selectedTemplate.category || "");
      methods.setValue("source", selectedTemplate.source || "");
      methods.setValue("notifyVendor", selectedTemplate.notifyVendor || false);
      methods.setValue(
        "recurrence.frequency",
        selectedTemplate.recurrence.frequency || ""
      );
      methods.setValue(
        "inputs",
        selectedTemplate.inputs.map((input) => ({
          ...input,
          validator: {
            ...input.validator,
            lowerLimit: input.validator?.lowerLimit
              ? Number(input.validator.lowerLimit)
              : null,
            upperLimit: input.validator?.upperLimit
              ? Number(input.validator.upperLimit)
              : null,
          },
        })) || []
      );
      methods.setValue("details", selectedTemplate.details || "");
      methods.setValue("sourceTemplateId", selectedTemplate._id || "");
      methods.setValue("skipWeekends", selectedTemplate.skipWeekends || false);
      methods.setValue("regulations", selectedTemplate.regulations || []);
    }
  }, [
    isEditing,
    selectedTask,
    methods.reset,
    selectedTemplate,
    methods.setValue,
  ]);

  const onSubmit = async (data: NewRecurringTaskFormData) => {
    try {
      if (isEditing) {
        if (selectedTask) {
          await updateTask(selectedTask._id, data);
        }
      } else {
        await createTask(data);
        updateShowModalState("createTask", false);
      }
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const formInputs = getFormInputs(maps, vendors);

  const renderContent = () => {
    if (isEditing) {
      return (
        <FormProvider {...methods}>
          <FormContent
            formInputs={formInputs}
            attachmentOptions={attachmentOptions}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            updateShowModalState={updateShowModalState}
            onSubmit={onSubmit}
          />
        </FormProvider>
      );
    }

    return (
      <FormProvider {...methods}>
        <div className="w-full max-w-2xl flex flex-col h-full ">
          <div className="flex flex-col sm:flex-row flex-1 -mt-4 overflow-hidden">
            <div className="flex sm:flex-col gap-y-4 border-r border-secondary-1000 pr-4 pt-4">
              {steps.map((step) => (
                <div key={step.name} className="flex">
                  <button
                    onClick={() => toggleStep(step.stepCount)}
                    disabled={step.disabled && step.stepCount === 3}
                    className={`cursor-pointer text-sm font-medium group flex w-full items-center gap-2 ${
                      currentStepCount >= step.stepCount
                        ? "text-secondary-500"
                        : "text-secondary-1000"
                    } `}
                  >
                    <span
                      className={` ${
                        currentStepCount >= step.stepCount
                          ? "bg-secondary-1100 text-accent-500"
                          : ""
                      } rounded-full h-6 w-6 flex items-center justify-center text-xs`}
                    >
                      {step.id}
                    </span>
                    {step.name}
                  </button>
                </div>
              ))}
            </div>
            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="flex-1 overflow-y-auto">
                {currentStepCount === 1 && (
                  <TemplateSelection
                    control={methods.control}
                    templates={templates}
                    tasks={tasks}
                    selectedTemplate={selectedTemplate}
                  />
                )}
                {currentStepCount === 2 && (
                  <FormContent
                    formInputs={formInputs}
                    attachmentOptions={attachmentOptions}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    updateShowModalState={updateShowModalState}
                    onSubmit={onSubmit}
                  />
                )}
                {currentStepCount === 3 && (
                  <RecurringTaskPreview taskData={methods.getValues()} />
                )}
              </div>

              <div className="flex gap-4 border-t border-secondary-900 pt-2 ">
                <Button
                  styleString="secondary"
                  onClick={() =>
                    currentStepCount > 1
                      ? toggleStep(currentStepCount - 1)
                      : isMobile
                      ? isEditing
                        ? setIsEditing(false)
                        : navigate(-1)
                      : updateShowModalState("createTask", false)
                  }
                  children={currentStepCount > 1 ? "Previous Step" : "Cancel"}
                  icon={currentStepCount > 1 ? "ArrowBack" : undefined}
                />
                <Button
                  styleString="primary"
                  onClick={
                    currentStepCount !== 3
                      ? () => toggleStep(currentStepCount + 1)
                      : methods.handleSubmit(onSubmit)
                  }
                  children={currentStepCount !== 3 ? "Next Step" : "Submit"}
                  icon={currentStepCount !== 3 ? "ArrowForward" : undefined}
                  type="button"
                  reverse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    );
  };

  return renderContent();
};

export default RecurringTaskForm;
