import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { User } from "../types/User";
import axios from "axios";
import { debounce } from "../utils/Helpers";
import {
  createUser,
  deleteUser,
  fetchUser,
  updateUser,
} from "../api/services/UserService";
import { fetchUsers } from "../api/services/AdminService";
import { set } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../hooks/useToast";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  facility: string[];
}

interface Facility {
  _id: string;
  name: string;
}

interface UserManagementContextType {
  users: User[];
  selectedUser: User | null;
  setSelectedUser: (user: User | null) => void;
  handleAddUser: () => void;
  handleEditUser: (user: User) => void;
  createNewUser: (data: FormValues) => Promise<void>;
  updateExistingUser: (data: FormValues) => Promise<void>;
  updateModalShowState: (modal: string, value: boolean) => void;
  isLoading: boolean;
  searchTerm: string;
  handleSearch: (searchTerm: string) => void;
  modalShowState: {
    showDelete: boolean;
    showFilter: boolean;
    showNewUser: boolean;
    showEditUser: boolean;
  };
  handleDeleteUser: (user: User) => Promise<void>;
  facilities: Facility[];
}

export const UserManagementContext = createContext<
  UserManagementContextType | undefined
>(undefined);

export const UserManagementProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [modalShowState, setModalShowState] = useState({
    showDelete: false,
    showFilter: false,
    showNewUser: false,
    showEditUser: false,
  });
  const { showResponse } = useToast();

  const loadUsers = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);

        const searchTerm = searchParams.get("search") || "";
        const queryString = new URLSearchParams({
          search: searchTerm,
        }).toString();

        const response = await fetchUsers(queryString);
        if (response) {
          const { users } = response;
          setUsers(users || []);
          setIsLoading(false);
        } else {
          console.error("No users found in response");
          setUsers([]);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false);
      }
    }, 300),
    [searchParams]
  );

  const searchTerm = searchParams.get("search") || "";

  const handleAddUser = () => {
    setSelectedUser(null);
    updateModalShowState("showNewUser", true);
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    updateModalShowState("showEditUser", true);
  };

  const createNewUser = async (data: FormValues) => {
    try {
      setIsLoading(true);
      await createUser(data);
      updateModalShowState("showNewUser", false);
      loadUsers();
      showResponse(
        "User Created",
        "New user has been successfully created.",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating new user:", error);
      showResponse(
        "Error",
        "There was an error creating the new user.",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const updateExistingUser = async (data: FormValues) => {
    try {
      if (selectedUser) {
        const userData = {
          ...data,
          facility: Array.isArray(data.facility)
            ? data.facility
            : [data.facility],
        };
        await updateUser(selectedUser._id, userData);
        updateModalShowState("showEditUser", false);
        loadUsers();
        showResponse(
          "User Updated",
          "User information has been successfully updated.",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      console.error("Error updating user:", error);
      showResponse(
        "Error",
        "There was an error updating the user.",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const handleDeleteUser = async (user: User) => {
    try {
      await deleteUser(user._id);
      updateModalShowState("showDelete", false);
      loadUsers();
      showResponse(
        "User Deleted",
        "User has been successfully deleted.",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      console.error("Error deleting user:", error);
      showResponse(
        "Error",
        "There was an error deleting the user.",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const updateModalShowState = (modalName: string, show: boolean) => {
    setModalShowState((prevState) => ({
      ...prevState,
      [modalName]: show,
    }));
  };

  const handleSearch = (value: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set("search", value);
    } else {
      newParams.delete("search");
    }
    setSearchParams(newParams);
  };

  // Load facilities
  useEffect(() => {
    const loadFacilities = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/admin/facilities", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFacilities(response.data || []);
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    loadFacilities();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers, searchParams]);

  return (
    <UserManagementContext.Provider
      value={{
        users,
        selectedUser,
        setSelectedUser,
        handleAddUser,
        handleEditUser,
        createNewUser,
        updateExistingUser,
        updateModalShowState,
        isLoading,
        searchTerm,
        handleSearch,
        modalShowState,
        handleDeleteUser,
        facilities,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
