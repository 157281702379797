import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../updatedcomponents/general/Button";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

const DeleteUser: React.FC = () => {
  const { isMobile } = useDeviceWidth();
  const [email, setEmail] = useState<string>("");
  const [deleteRequested, setDeleteRequested] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleDeleteRequest = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !email.includes("@")) {
      setError("Please enter a valid email address");
      return;
    }

    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post("/api/authenticate/delete-account", {
        email: email.toLowerCase(),
      });

      // Even if the email doesn't exist, we show the same success message
      // This prevents user enumeration attacks
      setDeleteRequested(true);
    }
    catch (error) {
      // We won't display specific errors from the server for security
      console.error("Error requesting account deletion:", error);

      // Still show success to prevent user enumeration
      setDeleteRequested(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col items-center sm:justify-center py-12 sm:px-8 bg-primary sm:bg-secondary-1000">
      <div
        className="flex justify-center sm:absolute sm:left-5 top-5 cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <img
          className="mx-auto h-8 w-auto"
          src="/sequra_logo__primary_black.png"
          alt="Sequra logo"
        />
      </div>

      {deleteRequested ? (
        <div className="flex flex-col justify-center items-center gap-6 bg-white p-8 rounded sm:shadow sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="text-center font-medium text-2xl text-secondary-100 mx-4">
            Thank You!
          </h2>
          <p className="text-center text-sm font-light text-secondary-300">
            We'll process your request and delete your account. If you have any
            questions or need assistance, please contact us at {"info@sequra.ai"}
          </p>
        </div>
      ) : (
        <div className="sm:bg-white sm:p-8 rounded sm:shadow sm:mx-auto sm:w-full sm:max-w-sm flex-col flex">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center font-semibold text-2xl leading-9 text-secondary-100">
              Delete Account
            </h2>
            <p className="mt-2 mx-4 text-xs text-center text-secondary-400">
              Please confirm your email address to proceed with the deletion of your
              account. This action is irreversible.
            </p>
            <div className="mt-10 pt-8 sm:pt-0 bg-secondary-1000 sm:bg-primary sm:mx-auto sm:w-full sm:max-w-sm">
              <form
                className="space-y-6 px-4 sm:px-0"
                onSubmit={handleDeleteRequest}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="hidden sm:block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                    />
                  </div>
                </div>
                {error && (
                  <div className="rounded-md bg-red-50 py-2">
                    <div className="flex">
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {error}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex w-full">
                  <Button
                    children={isLoading ? "Sending..." : "Delete Account"}
                    type="submit"
                    styleString={isMobile ? "primary" : "bold"}
                    disabled={isLoading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteUser;
