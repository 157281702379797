import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { WorkOrder } from "../../types/WorkOrder";
import StatusBadge from "../general-ui/StatusBadges";
import Table from "../general/Table";
import { useLog } from "../../hooks/useLog";
import WorkOrderCard from "../cards/WorkOrderCard";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import Spinner from "../general/Spinner";

const WorkOrderLogTable: React.FC = () => {
  const {
    workOrders,
    page,
    pageSize,
    totalPages,
    handlePageChange,
    updateShowState,
    handleRefresh,
    handleSort,
    sortColumn,
    sortOrder,
    isLoading,
  } = useLog();
  const { setSelectedWorkOrder } = useWorkOrder();
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();

  const handleView = (workOrder: WorkOrder) => {
    if (isMobile) {
      const workOrderId = workOrder._id;
      navigate(`/work-orders/instance/${workOrderId}`, {
        state: { returnUrl: "/logs" },
      });
    } else {
      setSelectedWorkOrder(workOrder);
      updateShowState("showWorkOrder", true);
    }
  };

  const columns = [
    {
      label: "ID",
      key: "shortId",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className="hidden sm:inline-flex">{workOrder.shortId}</span>
          <div className="flex w-full sm:hidden" key={workOrder._id}>
            <WorkOrderCard
              workOrder={workOrder}
              handleView={() => handleView(workOrder)}
              hideCheckbox={true}
            />
          </div>
        </>
      ),
      className: "truncate",
    },
    {
      label: "Request Title",
      key: "title",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <span>{capitalizeString(workOrder.title)}</span>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {formatDate(workOrder.dateReported)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Date Completed",
      key: "dateComplete",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {formatDate(workOrder.dateComplete)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Date Closed",
      key: "dateClosed",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {formatDate(workOrder.dateClosed)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Location",
      key: "location",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span
            className="hidden sm:inline-flex"
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeString(workOrder.location)}
          </span>
        </>
      ),
      className: "hidden xl:table-cell truncate",
    },
    {
      label: "Status",
      key: "status",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            <StatusBadge status={workOrder.status} />
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden md:table-cell",
    },
  ];

  return (
    <Table
      data={workOrders}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortOrder}
      showHeader={isMobile ? false : true}
      onRowClick={(workOrder) => handleView(workOrder)}
      columns={columns}
      stickyFirstColumn
      noDataMessage={
        isLoading ? (
          <Spinner size="xl" />
        ) : (
          // Display an svg
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching orders.
            </span>
          </div>
        )
      }
      paginationScheme="infiniteScroll"
      pageSize={pageSize}
      page={page}
      totalPages={totalPages}
      handlePageChange={handlePageChange}
      loading={isLoading}
      handleRefresh={() => handleRefresh()}
    />
  );
};

export default WorkOrderLogTable;
