import React, { useState } from "react";
import {
  ComputerOutlined,
  CopyAll,
  DeleteOutline,
  EditOutlined,
  UndoOutlined,
} from "@mui/icons-material";
import { capitalizeString } from "../../utils/FormatFunctions";
import { useBuilding } from "../../hooks/useBuilding";
import {
  ArrowUturnLeftIcon,
  ComputerDesktopIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

interface KioskListProps {}

const KioskList: React.FC<KioskListProps> = ({}) => {
  const [copiedKioskId, setCopiedKioskId] = useState<string | null>(null);

  const {
    kiosks,
    handleShowKiosk,
    handleKioskDeactivate,
    handleKioskReactivate,
  } = useBuilding();

  const handleCopyLink = (kioskId: string) => {
    try {
      const kioskURI = `${window.location.host}/kiosk/${kioskId}`;
      navigator.clipboard.writeText(kioskURI);
      setCopiedKioskId(kioskId);
      // Reset the copied state after 2 seconds
      setTimeout(() => {
        setCopiedKioskId(null);
      }, 2000);
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    }
  };

  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-y-2">
        <ComputerDesktopIcon className="h-8 w-8 text-secondary-100" />
        <h2 className="text-xl font-medium text-secondary-100">Kiosks</h2>
        <p className="text-sm text-secondary-500 font-light">
          Manage locations that you can receive work orders from
        </p>
      </div>
      <ul role="list" className="divide-y gap-y-2 ">
        {kiosks.length > 0 ? (
          kiosks.map((kiosk) => (
            <li
              key={kiosk._id}
              className="flex flex-col sm:flex-row mt-2 items-center justify-between rounded-lg border border-secondary-900 px-3 py-3 bg-primary"
            >
              {/* Info section */}
              <div className="flex  flex-col w-full">
                <div className="flex w-full sm:w-fit items-center gap-2 justify-between sm:justify-start">
                  <p className="text-sm font-semibold  text-secondary-100">
                    {capitalizeString(kiosk.name)}
                  </p>
                  <span
                    className={`inline-flex items-center rounded-lg px-2 py-1 text-xs  ring-1 ring-inset  ${
                      kiosk.status === "inactive"
                        ? "text-reds-500  bg-reds-1000 ring-reds-500/10"
                        : "text-accent-500  bg-accent-1000 ring-accent-500/10"
                    }`}
                  >
                    {capitalizeString(kiosk.status)}
                  </span>
                </div>
                <div className="mt-1 w-full sm:w-fit flex flex-col sm:flex-row sm:items-center gap-x-2 text-xs leading-5 text-secondary-500">
                  <p className="whitespace-nowrap">
                    {capitalizeString(kiosk.location)}
                  </p>
                  <div className="flex items-center gap-1">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">Pin {kiosk.pin}</p>
                  </div>
                </div>
              </div>
              {/* Action Section */}
              <div className="flex w-full sm:w-fit justify-between flex-none items-center gap-x-4">
                <button
                  onClick={() => handleCopyLink(kiosk._id)}
                  className=" bg-secondary-1100 border border-secondary-900 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100  hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                  data-tooltip-id={`copy-tooltip-${kiosk._id}`}
                >
                  <DocumentDuplicateIcon className="h-4 w-4" />
                </button>
                <Tooltip
                  id={`copy-tooltip-${kiosk._id}`}
                  content={
                    copiedKioskId === kiosk._id ? "Copied!" : "Copy Link"
                  }
                  place="bottom"
                  className="max-w-xs !bg-secondary-1100 !text-secondary-100 !opacity-100 shadow-xl"
                  style={{
                    borderRadius: "0.375rem",
                    padding: "0.5rem",
                  }}
                  noArrow
                />
                <div className="flex items-center gap-x-2">
                  <button
                    className="bg-secondary-1100 border border-secondary-900 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100  hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                    onClick={() => handleShowKiosk(kiosk)}
                  >
                    <PencilSquareIcon className="h-4 w-4" />
                    Edit
                  </button>
                  {kiosk.status === "active" ? (
                    <button
                      className="bg-secondary-1100 border border-secondary-900 text-reds-500 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-semibold   shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                      onClick={() => handleKioskDeactivate(kiosk)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  ) : (
                    <button
                      className="bg-secondary-1100 border border-secondary-900 text-accent-500 px-2.5 py-1.5 rounded-lg flex justify-center items-center gap-x-1 text-sm font-semibold   shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                      onClick={() => handleKioskReactivate(kiosk)}
                    >
                      <ArrowUturnLeftIcon className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No kiosks present
            </h2>
          </div>
        )}
      </ul>
    </div>
  );
};

export default KioskList;
