import React from "react";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import {
  Delete,
  FileOpenOutlined,
  NoteOutlined,
  UndoOutlined,
} from "@mui/icons-material";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import StatusBadge from "../general-ui/StatusBadges";
import { useAuth } from "../../hooks/useAuth";
import { WorkOrder, WorkOrderStatus } from "../../types/WorkOrder";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import {
  ArrowTrendingUpIcon,
  CheckBadgeIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Button from "../general/Button";
import StatusSelector from "../general-ui/StatusSelector";
import Selector from "../general-ui/Selector";
import UrgencySelector from "../general-ui/UrgencySelector";
import EditableText from "../general-ui/EditableText";

interface DetailsProps {
  dataToRender: {
    title: string;
    dataTag: string;
    icon: React.ElementType;
  }[];
  handleDeleteClick: (workOrder: WorkOrder) => void;
  handleReopenClick: (workOrder: WorkOrder) => void;
  handleWorkOrderStatusChange: (
    workOrder: WorkOrder,
    status: WorkOrderStatus | null
  ) => void;
}

const Details: React.FC<DetailsProps> = ({
  dataToRender,
  handleDeleteClick,
  handleReopenClick,
  handleWorkOrderStatusChange,
}) => {
  const { currentUser } = useAuth();
  const permissions = currentUser.access;

  const {
    selectedWorkOrder,
    handleAssignment,
    users,
    updateModalShowState,
    handleWorkOrderUrgencyChange,
    handleWorkOrderDetailsChange,
    handleWorkOrderTitleChange,
  } = useWorkOrder();

  if (!selectedWorkOrder) {
    return null;
  }

  return (
    <div className="bg-secondary-1100 sm:bg-primary flex flex-col h-full gap-y-2 overflow-y-auto thin-scrollbar w-full">
      <div className="bg-primary">
        <EditableText
          text={selectedWorkOrder?.title ?? ""}
          onSave={(newText) => {
            if (selectedWorkOrder) {
              handleWorkOrderTitleChange(selectedWorkOrder, newText);
            }
          }}
          disableEdit={
            (permissions !== "regional" &&
              permissions !== "organization-admin") ||
            selectedWorkOrder.status === "closed"
          }
          className="flex sm:hidden font-medium text-xl px-5 py-6 text-secondary"
        />

        {dataToRender
          .filter(
            (data) =>
              data.dataTag !== "status" &&
              data.dataTag !== "details" &&
              data.dataTag !== "urgency"
          )
          .map((data, index) => (
            <div
              key={index}
              className="py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 px-5 sm:px-0 text-xs sm:text-sm"
            >
              <dt className=" text-secondary-400 flex items-center gap-2">
                {<data.icon className="h-4 w-4" />}
                {data.title}
              </dt>
              <dd className="flex text-secondary-100 sm:col-span-2">
                <span className="flex-grow">
                  {data.dataTag !== "urgency" ? (
                    data.dataTag !== "dateReported" ? (
                      capitalizeString(
                        selectedWorkOrder[
                          data.dataTag as keyof WorkOrder
                        ] as string
                      )
                    ) : (
                      formatDate(
                        selectedWorkOrder[
                          data.dataTag as keyof WorkOrder
                        ] as string,
                        "MMM D, YYYY h:mm a"
                      )
                    )
                  ) : (
                    <StatusBadge
                      status={
                        selectedWorkOrder[
                          data.dataTag as keyof WorkOrder
                        ] as WorkOrderStatus
                      }
                    />
                  )}
                </span>
              </dd>
            </div>
          ))}
      </div>
      <div className="bg-primary p-5 grid grid-cols-2 sm:flex sm:flex-col gap-x-4 sm:px-0">
        {/* Details section */}
        <div className="sm:py-2 grid grid-cols-1 col-span-2 sm:grid-cols-3 gap-4 sm:px-0 text-sm sm:text-sm items-start">
          <dt className="text-secondary-400 flex items-center gap-2">
            {<InformationCircleIcon className="h-4 w-4" />}Details
          </dt>
          <dd className="flex text-secondary-100 font-normal text-sm sm:col-span-2">
            <EditableText
              text={selectedWorkOrder.details ?? ""}
              onSave={(newText) =>
                handleWorkOrderDetailsChange(selectedWorkOrder, newText)
              }
              multiline={true}
              disableEdit={
                (permissions !== "regional" &&
                  permissions !== "organization-admin") ||
                selectedWorkOrder.status === "closed"
              }
              className="w-full flex-grow"
            />
          </dd>
        </div>

        <div className="text-xs sm:text-sm py-2 grid grid-cols-1 sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className=" text-secondary-400 flex items-center gap-2">
            {<ArrowTrendingUpIcon className="h-4 w-4" />}
            Status
          </dt>

          <dd className="mt-1 flex w-fit text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
            <StatusSelector
              selectedStatus={selectedWorkOrder.status}
              handleChange={(status: WorkOrderStatus | null) => {
                handleWorkOrderStatusChange(selectedWorkOrder, status);
              }}
            />
          </dd>
        </div>

        <div className="text-xs sm:text-sm py-2 grid grid-cols-1 sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className=" text-secondary-400 flex items-center gap-2">
            {<ArrowTrendingUpIcon className="h-4 w-4" />}
            Urgency
          </dt>

          <dd className="mt-1 flex w-fit text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
            {permissions === "organization-admin" ||
            permissions === "regional" ? (
              <UrgencySelector
                selectedUrgency={selectedWorkOrder.urgency}
                handleChange={(urgency) =>
                  handleWorkOrderUrgencyChange(selectedWorkOrder, urgency)
                }
              />
            ) : (
              <span className="text-secondary-400">
                <StatusBadge status={selectedWorkOrder.urgency} />
              </span>
            )}
          </dd>
        </div>

        <div className="text-sm sm:text-sm py-5 sm:py-2 grid grid-cols-1 col-span-2 sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className=" text-secondary-400 flex items-center gap-2">
            {<UserIcon className="h-4 w-4" />}
            Assigned To
          </dt>
          {selectedWorkOrder.status === "closed" ? (
            <dd className="mt-1 flex text-sm text-secondary-400 sm:col-span-1 sm:mt-0">
              <span className="text-secondary-400">
                {selectedWorkOrder?.assignedTo?.firstname
                  ? `${selectedWorkOrder.assignedTo.firstname} ${selectedWorkOrder.assignedTo.lastname}`
                  : "Unassigned"}
              </span>
            </dd>
          ) : (
            <dd className="mt-1 flex text-sm text-secondary-400  sm:col-span-1 sm:mt-0">
              {currentUser.access !== "worker" && users.length > 0 ? (
                <AssignmentSelector
                  users={users}
                  selectedUser={
                    selectedWorkOrder.assignedTo
                      ? selectedWorkOrder.assignedTo
                      : null
                  }
                  handleChange={(user) =>
                    handleAssignment(selectedWorkOrder, user)
                  }
                />
              ) : (
                <span className="text-secondary-400">
                  {selectedWorkOrder?.assignedTo?.firstname}{" "}
                  {selectedWorkOrder?.assignedTo?.lastname}
                </span>
              )}
            </dd>
          )}
        </div>
      </div>
      {/* Note Section */}
      <div className="py-4 flex flex-col gap-y-2 bg-primary px-5 sm:px-0">
        <dt className=" text-secondary-400 flex items-center gap-2 border-b border-secondary-1100">
          <DocumentTextIcon className="h-4 w-4" />
          Notes
        </dt>
        <dd className="flex text-secondary-400 w-full">
          {selectedWorkOrder.notes && selectedWorkOrder?.notes.length > 0 ? (
            <ul className="bg-secondary-1200 rounded-lg w-full  px-1.5 max-h-24 overflow-y-auto scrollbar-thin ">
              {selectedWorkOrder.notes.map((note, index) => (
                <li key={index}>
                  <span className="text-xs text-grey-900">{note.text}</span>
                  {
                    <div className="text-xs text-gray-400">
                      Posted by{" "}
                      {note.userId ? note.userId.firstname : "Unknown user"} at{" "}
                      {formatDate(note.timestamp, "MMM D, YYYY h:mm a")}
                    </div>
                  }
                </li>
              ))}
            </ul>
          ) : (
            <div className="bg-secondary-1200 rounded-md w-full px-1.5 py-4 flex flex-col text-secondary-500  justify-center items-center">
              <NoteOutlined />
              <span className="text-sm  text-center">No previous notes</span>
            </div>
          )}
        </dd>
        <div className="w-fit">
          <Button
            onClick={() => updateModalShowState("showNotes", true)}
            icon="FileOpenOutlined"
            styleString="secondary"
            children="Add a note"
          />
        </div>
      </div>
      {(permissions === "organization-admin" || permissions === "regional") && (
        <div className="flex w-full flex-col bg-primary px-5 sm:px-0">
          <div className="my-2 flex items-center w-fit py-1.5 px-1 rounded-md text-reds-500 ">
            <Button
              onClick={() => handleDeleteClick(selectedWorkOrder)}
              icon="DeleteOutlined"
              styleString="danger-outline"
              children="Delete Order"
            />

            <p className="text-xs sm:text-sm text-secondary-500 px-1 ml-3">
              This action can not be undone.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
