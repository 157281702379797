import React, {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RecurringTask, TaskInputType } from "../types/RecurringTask";
import { TaskInstance } from "../types/TaskInstance";
import apiClient from "../api/apiClient";
import { useFacility } from "../hooks/useFacility";
import useFetchData from "../hooks/useFetchData";
import { fetchTeam } from "../api/services/UserService";
import { User } from "../types/User";
import {
  assignTask,
  closeOutTaskInstance,
  fetchTaskInstance,
  fetchTasks,
  skipTask,
  unskipTask,
  updateTaskInstance,
} from "../api/services/RecurringTaskService";
import { debounce } from "../utils/Helpers";
import { useToast } from "../hooks/useToast";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { updateSearchParams } from "../utils/ParamUtils";

interface Filters {
  status?: string;
  frequency?: string;
  assignedTo?: string;
  q?: string;
  taskType?: string;
  source?: string;
}

interface RecurringTaskContextProps {
  recurringTasks: TaskInstance[];
  users: User[];
  totalPages: number;
  isLoading: boolean;
  hasMore: boolean;
  showState: {
    showFilter: boolean;
    showSpecs: boolean;
    showSkip: boolean;
  };
  updateShowState: (field: string, value: boolean) => void;
  handleResetFilters: () => void;
  applyFilters: (filters: Record<string, string>) => void;
  handlePageSizeChange: ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => void;
  applyIndividualFilter: (key: string, value: string) => void;
  handleLogInstanceView: (logInstance: TaskInstance) => void;
  instanceToEdit: TaskInstance | null;
  setInstanceToEdit: (instance: TaskInstance | null) => void;
  filters: Record<string, string>;
  view: string;
  page: number;
  tasksPerPage: string;
  navigateBack: () => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handleViewFile: (file: string) => void;
  handleTaskAssignment: (
    task: TaskInstance,
    selectedUser: string | null
  ) => void;
  handleSkipTask: (task: TaskInstance) => Promise<void>;
  handleUnskipTask: (task: TaskInstance) => Promise<void>;
  onSubmit: (data: any) => void;
  handleCloseOut: () => void;
  closeOutValidation: () => void;
  canCloseOut: boolean;
  handleRefresh: () => void;
  handleEndReached: () => void;
}

export const RecurringTaskContext = createContext<
  RecurringTaskContextProps | undefined
>(undefined);

interface RecurringTaskProviderProps {
  children: ReactNode;
}

export const RecurringTaskProvider: React.FC<RecurringTaskProviderProps> = ({
  children,
}) => {
  const { selectedFacility } = useFacility();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showResponse } = useToast();

  const [recurringTasks, setRecurringTasks] = useState<TaskInstance[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const view = searchParams.get("view") || "list";
  const tasksPerPage = searchParams.get("pageSize") || "20";

  const filterObject = useMemo(() => {
    const defaultFilters = {
      readyToComplete: searchParams.get("readyToComplete") || "true",
    };
    const customFilters = searchParams.get("filters")
      ? JSON.parse(searchParams.get("filters")!)
      : {};
    return { ...defaultFilters, ...customFilters };
  }, [searchParams]);

  const fetchRecurringTasks = useCallback(
    async (
      pageNum: number,
      currentFilters: Filters,
      currentPageSize: number,
      append: boolean = false
    ) => {
      if (!selectedFacility) return;

      try {
        if (!append) {
          setIsLoading(true);
        }
        const queryString = new URLSearchParams({
          ...currentFilters,
          page: pageNum.toString(),
          pageSize: currentPageSize.toString(),
        }).toString();
        const taskData = await fetchTasks(selectedFacility, queryString);

        if (taskData) {
          const { tasks: fetchedTasks, pages } = taskData;

          if (append) {
            setRecurringTasks((prevTasks) => {
              // Create a Set of existing task IDs for O(1) lookup
              const existingIds = new Set(
                prevTasks.map((task) => `${task.parentTask}-${task.date}`)
              );
              // Filter out any tasks that already exist in the list
              const uniqueNewTasks = fetchedTasks.filter(
                (task: TaskInstance) =>
                  !existingIds.has(`${task.parentTask}-${task.date}`)
              );
              return [...prevTasks, ...uniqueNewTasks];
            });
          } else {
            setRecurringTasks(fetchedTasks);
          }

          setTotalPages(pages);
          setPage(pageNum);
          setHasMore(pageNum < pages);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        if (!append) {
          setIsLoading(false);
        }
      }
    },
    [selectedFacility, searchParams]
  );

  const fetchUsers = useCallback(async () => {
    try {
      const userResponse = await fetchTeam(selectedFacility, "");
      setUsers(userResponse.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [selectedFacility]);

  const [showState, setShowState] = useState({
    showFilter: false,
    showSpecs: true,
    showSkip: false,
  });

  const updateShowState = (field: string, value: boolean) => {
    setShowState({
      ...showState,
      [field]: value,
    });
  };

  const [instanceToEdit, setInstanceToEdit] = useState<TaskInstance | null>(
    null
  );

  const handleResetFilters = () => {
    setPage(1);
    if (Object.keys(filterObject).length > 0) {
      //setRecurringTasks([]);
      //}
      updateSearchParams(setSearchParams, {
        filters: JSON.stringify(""),
        page: "1",
        view,
        readyToComplete: "true",
      });
    }
    updateShowState("showFilter", false);
  };

  const applyFilters = (filters: Record<string, string>) => {
    setPage(1);
    setRecurringTasks([]);
    updateSearchParams(setSearchParams, {
      filters: JSON.stringify(filters),
      page: "1",
    });
  };

  const applyIndividualFilter = (key: string, value: string) => {
    const currentFilters = filterObject || {};
    const updatedFilters = { ...currentFilters, [key]: value };

    if (value === "") {
      delete updatedFilters[key];
    }

    setPage(1);
    updateSearchParams(setSearchParams, {
      filters: JSON.stringify(updatedFilters),
    });
  };

  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    updateSearchParams(setSearchParams, { pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    if (isLoading) return;
    setIsLoading(true);
    fetchRecurringTasks(newPage, filterObject, Number(tasksPerPage), true);
    setIsLoading(false);
  };

  const navigate = useNavigate();

  const handleLogInstanceView = (logInstance: TaskInstance) => {
    navigate(
      `/tasks/${logInstance.parentTask}_${logInstance.date}?${searchParams}`
    );
  };

  const navigateBack = () => {
    setInstanceToEdit(null);
    fetchRecurringTasks(1, filterObject, Number(tasksPerPage), false);
    navigate(`/tasks?${searchParams}`);
  };

  useEffect(() => {
    if (!selectedFacility) return;
    fetchUsers();
    fetchRecurringTasks(1, filterObject, Number(tasksPerPage), false);
  }, [searchParams, selectedFacility]);

  const { id } = useParams();

  const fetchTaskInstanceData = async (parentTask: string, date: string) => {
    try {
      setIsLoading(true);
      const taskInstance = await fetchTaskInstance(parentTask, date);
      setInstanceToEdit(taskInstance);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching task :", error);
    }
  };
  useEffect(() => {
    if (id) {
      // Split id to get parentTask and date
      const [parentTask, date] = id.split("_");

      fetchTaskInstanceData(parentTask, date);
    }
  }, [id]);

  const [files, setFiles] = useState<File[]>([]);
  const handleViewFile = async (link: string) => {
    const file = files.find((file) => file.name === link);

    if (file) {
      // If the file is in the files state, create a blob URL and open it
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    } else {
      try {
        const encodedLink = encodeURIComponent(link);
        await apiClient
          .get("/recurring-tasks/file", {
            params: { encodedLink },
          })
          .then((response) => {
            window.open(response.data.url, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const [canCloseOut, setCanCloseOut] = useState<boolean>(false);

  const handleTaskAssignment = async (
    task: TaskInstance,
    selectedUser: string | null
  ) => {
    if (!task.childId) {
      task.childId = "createId";
    }
    // Depending on whether the task is a recurring task or a task instance, we need to get the parentId differently
    const parentId =
      task.parentTask._id || (task.parentTask as unknown as string);
    try {
      await assignTask(task, selectedUser, selectedFacility);
      fetchTaskInstanceData(parentId, task.date);

      // Update the task list to reflect the new assignment
      setRecurringTasks((prevTasks) =>
        prevTasks.map((t) => {
          if (t.parentTask === task.parentTask && t.date === task.date) {
            // Find the assigned user from the users list
            const assignedUser = selectedUser
              ? users.find((u) => u._id === selectedUser)
              : undefined;
            return { ...t, assignedTo: assignedUser };
          }
          return t;
        })
      );

      showResponse(
        "Task assigned",
        "Task has been successfully assigned",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error assigning the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error assigning task:", error);
    }
  };

  const validateDataForFail = (formData: Record<string, any>): boolean => {
    if (!formData) {
      return false;
    }

    for (const [fieldId, value] of Object.entries(formData)) {
      if (fieldId === "ID" || fieldId === "Notes") continue;

      if (value === "Fail") {
        return false;
      }
    }
    return true;
  };

  const validateForCompletion = (formData: Record<string, any>): boolean => {
    if (!formData || !instanceToEdit?.parentTask?.inputs) {
      console.log("No form data or inputs");
      return false;
    }

    for (const field of instanceToEdit.parentTask.inputs) {
      if (field.validator.isRequired && !formData[field._id]) {
        return false;
      }
    }
    return true;
  };

  const validateEntireDataSet = (): boolean => {
    if (!instanceToEdit?.sectionStatus) {
      setCanCloseOut(false);
      return false;
    }

    const mapKeys = instanceToEdit.parentTask.map.fields;

    const sectionStatus = instanceToEdit.sectionStatus;

    if (Object.keys(sectionStatus).length !== mapKeys.length) {
      setCanCloseOut(false);
      return false;
    }
    for (const status of Object.values(sectionStatus)) {
      if (status !== "complete") {
        setCanCloseOut(false);
        return false;
      }
    }
    setCanCloseOut(true);
    return true;
  };
  // Helper function to reorder form data

  const reorderFormData = (data: any) => {
    const orderedData: Record<string, any> = {};

    // Get the second field key from map fields
    const mapFields = instanceToEdit?.parentTask?.map?.fields?.[0] || {};
    const secondFieldKey = Object.keys(mapFields).find((key) => key !== "ID");

    // First add ID and dynamic second field if they exist
    ["ID", secondFieldKey].forEach((field) => {
      if (field && data.hasOwnProperty(field)) {
        orderedData[field] = data[field];
      }
    });

    // Then add all other fields except Notes
    Object.keys(data).forEach((field) => {
      if (field !== "ID" && field !== secondFieldKey && field !== "Notes") {
        orderedData[field] = data[field];
      }
    });

    // Finally add Notes if it exists
    if (data.hasOwnProperty("Notes")) {
      orderedData["Notes"] = data["Notes"];
    }

    return orderedData;
  };

  const onSubmit = async (data: any) => {
    if (!instanceToEdit) return;
    console.log("data", data);

    try {
      // Reorder the form data
      const formattedData = reorderFormData(data);

      const isNotFailed = validateDataForFail(data);
      const isNotIncomplete = validateForCompletion(data);
      const updatedStatus = isNotFailed
        ? isNotIncomplete
          ? "complete"
          : "incomplete"
        : "failed";
      const uploadForm = new FormData();
      uploadForm.append("facility", selectedFacility);
      uploadForm.append("status", updatedStatus);
      uploadForm.append("sectionStatus", updatedStatus);
      uploadForm.append(
        "sectionsLength",
        instanceToEdit.parentTask.map.fields.length.toString()
      );
      uploadForm.append("customInput", JSON.stringify(formattedData));
      uploadForm.append("parentDate", instanceToEdit.date);

      files.forEach((file) => {
        uploadForm.append("files", file);
      });

      const updatedInstance = await updateTaskInstance(
        instanceToEdit.parentTask._id,
        uploadForm
      );
      setInstanceToEdit(updatedInstance);
      showResponse(
        "Task Saved",
        "Data has been successfully saved",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error updating task:", error);
    }
  };

  const handleCloseOut = async () => {
    try {
      if (!instanceToEdit) return;
      const parentId = instanceToEdit.parentTask._id;
      const childId = instanceToEdit.childId;
      if (!parentId || !childId) return;
      await closeOutTaskInstance(parentId, childId);

      fetchRecurringTasks(1, filterObject, Number(tasksPerPage), false);
      navigate(`/tasks?${searchParams}`);
      setInstanceToEdit(null);
      showResponse(
        "Task Closed",
        "Task has been successfully closed",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error closing out the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error closing out task:", error);
    }
  };

  const handleRefresh = () => {
    fetchRecurringTasks(1, filterObject, Number(tasksPerPage), false);
  };

  const handleEndReached = useCallback(() => {
    if (!isLoading) {
      console.log("End reached: ", page);
      handlePageChange(page + 1);
    } else {
      console.log("Loading: ", isLoading);
    }
  }, [isLoading, page, handlePageChange]);

  const handleSkipTask = async (task: TaskInstance) => {
    try {
      const parentId = task.parentTask._id || task.parentTask;
      await skipTask(parentId.toString(), task.date);
      setInstanceToEdit((prevTask) =>
        prevTask ? { ...prevTask, status: "skipped" } : null
      );
      updateShowState("showSkip", false);
      navigateBack();
      showResponse(
        "Task Skipped",
        "Task has been successfully skipped",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );

      handleRefresh();
    } catch (error) {
      showResponse(
        "Error",
        "This task cannot be skipped",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error skipping task:", error);
    }
  };

  const handleUnskipTask = async (task: TaskInstance) => {
    try {
      const parentId = task.parentTask._id || task.parentTask;
      await unskipTask(parentId.toString(), task.date);
      showResponse(
        "Task Unskipped",
        "Task has been successfully unskipped",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
      handleRefresh();
    } catch (error) {
      showResponse(
        "Error",
        "Error unskipping task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error unskipping task:", error);
    }
  };

  return (
    <RecurringTaskContext.Provider
      value={{
        recurringTasks,
        users,
        totalPages,
        isLoading,
        hasMore,
        showState,
        updateShowState,
        filters: filterObject,
        applyIndividualFilter,
        handleResetFilters,
        applyFilters,
        handlePageSizeChange,
        handleLogInstanceView,
        instanceToEdit,
        setInstanceToEdit,
        view,
        page,
        tasksPerPage,
        navigateBack,
        files,
        setFiles,
        handleViewFile,
        handleTaskAssignment,
        handleSkipTask,
        handleUnskipTask,
        onSubmit,
        handleCloseOut,
        closeOutValidation: validateEntireDataSet,
        canCloseOut,
        handleRefresh,
        handleEndReached,
      }}
    >
      {children}
    </RecurringTaskContext.Provider>
  );
};
