// ResetPassword.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Check } from "@mui/icons-material";
import Button from "../../updatedcomponents/general/Button";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

const Registration: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Check token validity on component mount
  useEffect(() => {
    const validateToken = async () => {
      if (!token) {
        setIsTokenValid(false);
        return;
      }
      
      try {
        await axios.get(`/api/authenticate/validate-token?token=${token}`);
        setIsTokenValid(true);
      } catch (error) {
        setIsTokenValid(false);
      }
    };
    
    validateToken();
  }, [token]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setResetError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(`/api/authenticate/reset-password?token=${token}`, {
        password,
      });

      // If the API call is successful, set resetSuccess to true
      setResetSuccess(true);
      setResetError("");
    } catch (error: any) {
      console.error("Error resetting password:", error);
      
      // More specific error handling based on response
      if (axios.isAxiosError(error) && error.response) {
        setResetError(error.response.data.message || "Failed to reset password. Please try again.");
      } else {
        setResetError("Failed to reset password. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isTokenValid) {
    return (
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center bg-primary sm:bg-secondary-1100">
        <div className="bg-primary p-8 rounded-md shadow text-center">
          <h2 className="text-xl font-medium text-secondary-100 mb-4">Invalid or Expired Link</h2>
          <p className="text-secondary-500 mb-6">
            This password reset link is invalid or has expired. 
            Please request a new one.
          </p>
          <Link 
            to="/forgot-password" 
            className="text-accent-500 hover:text-accent-600 underline"
          >
            Request New Password Reset
          </Link>
        </div>
      </div>
    );
  }

  const inputs = [
    {
      label: "New Password",
      placeholder: "New password",
      type: "password",
      value: password,
      onChange: handlePasswordChange,
      required: true,
    },
    {
      label: "Confirm New Password",
      placeholder: "Confirm new password",
      type: "password",
      value: confirmPassword,
      onChange: handleConfirmPasswordChange,
      required: true,
    },
  ];

  return (
    <div className="flex min-h-screen flex-1 flex-col items-center sm:justify-center pt-12 sm:pb-12 sm:px-8 sm:bg-secondary-1100">
      <div
        className="flex justify-center sm:absolute sm:left-5 top-5 cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <img
          className="mx-auto h-8 w-auto"
          src="/sequra_logo__primary_black.png"
          alt="Sequra logo"
        />
      </div>
      {resetSuccess ? (
        <div className="bg-primary p-8 rounded-md shadow">
          <div className="text-center">
            <Check
              className="rounded-full bg-accent-1000 text-accent-500 p-2 mx-auto"
              style={{ fontSize: "3rem" }}
            />
            <h2 className="font-header text-center text-secondary-100 my-6 text-xl">
              Password Reset Successful!
            </h2>
            <p className="text-secondary-500 text-sm mb-6">
              Your password has been reset successfully.
            </p>
            <Link 
              to="/login" 
              className="text-accent-500 py-2 hover:text-accent-600 underline"
            >
              Return to Login
            </Link>
          </div>
        </div>
      ) : (
        <div className="bg-primary sm:p-8 rounded sm:shadow sm:mx-auto w-full sm:max-w-sm flex flex-col flex-grow sm:flex-grow-0">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center font-semibold text-2xl leading-9 text-secondary-100">
              Create a New Password
            </h2>
          </div>
          <div className="flex flex-col flex-grow mt-6 mx-auto w-full sm:max-w-sm bg-secondary-1000 sm:bg-primary px-4 sm:px-0 pt-8 sm:pt-0">
            <form className="space-y-6" onSubmit={handleResetPassword}>
              {inputs.map((input, index) => (
                <div key={index}>
                  <label className="hidden sm:block text-sm font-medium leading-6 text-gray-900">
                    {input.label}
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                    placeholder={input.placeholder}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    required={input.required}
                  />
                </div>
              ))}
              
              {resetError && 
                <p className="text-reds-500 text-sm bg-reds-50 p-2 rounded">
                  {resetError}
                </p>
              }
              
              <div className="flex w-full">
                <Button
                  children={isLoading ? "Resetting..." : "Reset Password"}
                  type="submit"
                  styleString={isMobile ? "primary" : "bold"}
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
