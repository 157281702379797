import React from "react";
import * as MuiIcons from "@mui/icons-material";
import classNames from "classnames";

export type ButtonTypes =
  | "primary"
  | "secondary"
  | "danger"
  | "danger-outline"
  | "danger-filled"
  | "minimal"
  | "bold";

type MuiIconNames = keyof typeof MuiIcons;

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (e: any) => void;
  styleString?: ButtonTypes;
  children?: React.ReactNode;
  overrideStyles?: string;
  icon?: MuiIconNames;
  reverse?: boolean; // New prop to control the order
  props?: any;
};

const Button: React.FC<ButtonProps> = ({
  type = "button",
  disabled,
  onClick,
  styleString = "primary",
  children,
  overrideStyles,
  icon,
  reverse = false,
  props,
}) => {
  const buttonStyle = classNames(
    "justify-center flex-1 items-center gap-3 inline-flex text-sm ring-1 ring-inset py-2.5 px-4 h-fit rounded-lg ",
    {
      "bg-secondary-100 text-primary ": styleString === "primary",
      "bg-transparent text-secondary ring-secondary shadow-sm":
        styleString === "secondary",
      "bg-accent-500 text-primary ring-accent-500": styleString === "bold",
      "bg-reds-1100 text-reds-300 ring-transparent": styleString === "danger",
      "ring-transparent": styleString === "minimal",
      "bg-transparent text-reds-500 ring ring-reds-500":
        styleString === "danger-outline",
      "bg-reds-500 text-primary ring-reds-500": styleString === "danger-filled",

      "bg-blue-500 text-white": ![
        "primary",
        "bold",
        "secondary",
        "danger",
        "minimal",
        "danger-outline",
        "danger-filled",
      ].includes(styleString),
      "cursor-not-allowed bg-secondary-800 ring-secondary-700 hover:cursor-disabled": disabled,
      "hover:cursor-pointer": !disabled,
    },
    overrideStyles
  );

  const IconComponent = icon ? MuiIcons[icon as MuiIconNames] : null;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
      {...props}
    >
      <div
        className={classNames(
          "flex gap-2 items-center",
          reverse ? "flex-row-reverse" : "flex-row "
        )}
      >
        {IconComponent && (
          <IconComponent style={{ fontSize: "1rem", color: "currentcolor" }} />
        )}
        {children && <span className="truncate">{children}</span>}
      </div>
    </button>
  );
};

export default Button;
