import { useMemo, useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  extractFilenameFromUrl,
  formatDate,
  decodedString,
} from "../../utils/FormatFunctions";
import {
  ArrowBack,
  AttachmentOutlined,
  CheckCircleOutline,
  Close,
  OpenInNew,
  Delete,
  Refresh,
} from "@mui/icons-material";
import { useLog } from "../../hooks/useLog";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import Button from "../../updatedcomponents/general/Button";
import { useAuth } from "../../hooks/useAuth";
import {
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  FlagIcon,
  PaperClipIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../../updatedcomponents/general/Spinner";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

interface Attachment {
  _id: string;
  url: string;
  name: string;
}

interface ParentTask {
  source: string;
  details: string;
  attachments: Attachment[];
}

interface CustomInput {
  dataName: string;
  dataType: string;
  value: any;
  [key: string]: any;
}

interface LogInstance {
  date: string;
  status: string;
  parentTask: ParentTask;
  customInput: CustomInput[];
}

const LogInstance: React.FC = () => {
  const { currentUser } = useAuth();
  const {
    selectedLogInstance,
    navigateBack,
    handleViewFile,
    handleDeleteInstance,
    handleReopenInstance,
    showState,
    handleUnskipTask,
    updateShowState,
  } = useLog();
  const { isMobile } = useDeviceWidth();
  const [showSpecs, setShowSpecs] = useState(false);
  const [activeTab, setActiveTab] = useState<"details" | "attachments">(
    "details"
  );
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

  // Add null checks in useMemo
  const normalizeCustomInput = useMemo<CustomInput[]>(() => {
    if (!selectedLogInstance?.customInput) {
      return [];
    }

    if (Array.isArray(selectedLogInstance.customInput)) {
      return selectedLogInstance.customInput;
    }

    if (typeof selectedLogInstance.customInput === "object") {
      const values = Object.values(selectedLogInstance.customInput);
      return values;
    }

    return [];
  }, [selectedLogInstance?.customInput]);

  const tableKeys = useMemo(() => {
    if (!normalizeCustomInput?.[0]) {
      return [];
    }

    const uniqueKeys = Object.entries(normalizeCustomInput[0]).map(
      ([key, entry]) => {
        const uniqueId = `${entry.dataName}_${entry.dataType}_${key}`;
        return {
          key: uniqueId,
          dataName: entry.dataName,
          originalKey: key,
        };
      }
    );

    return uniqueKeys;
  }, [normalizeCustomInput]);

  if (!selectedLogInstance) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  const renderCellContent = (row: CustomInput, key: string) => {
    const prop = row[key];
    if (!prop) return "N/A";

    const { dataType, value } = prop;

    if (dataType === "date") {
      return formatDate(value, "MM/DD/YYYY hh:mm a");
    }
    if (dataType === "radio") {
      if (value === "Pass") {
        return (
          <div className="text-accent-700 flex items-center gap-1">
            <CheckCircleOutline fontSize="small" /> <span>Pass</span>
          </div>
        );
      } else {
        return (
          <div className="text-reds-500 flex items-center gap-1">
            <Close fontSize="small" /> <span>Fail</span>
          </div>
        );
      }
    }
    if (dataType === "file") {
      return (
        <div className="flex w-full justify-center my-1">
          {Array.isArray(value) &&
            value.map(
              (item: string, index: number) =>
                typeof item === "string" && (
                  <button
                    key={index}
                    className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer"
                    onClick={() => handleViewFile(item)}
                  >
                    {extractFilenameFromUrl(item)}{" "}
                    <OpenInNew fontSize="small" />
                  </button>
                )
            )}
        </div>
      );
    }
    if (value === "") {
      return "--";
    }

    return value;
  };

  return (
    <div className="h-full realtive">
      <PageHeader
        title={
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
              onClick={navigateBack}
            >
              <ChevronLeftIcon className="w-4 h-4" />{" "}
              {selectedLogInstance?.parentTask?.title}
            </button>
          </div>
        }
        buttons={
          <div className="flex gap-4">
            {["regional", "organization-admin"].includes(
              currentUser.access
            ) && (
              <Button
                type="button"
                icon="DeleteOutline"
                children="Delete Log"
                styleString="danger"
                onClick={() => updateShowState("showDelete", true)}
              />
            )}
            {["regional", "organization-admin", "director"].includes(
              currentUser.access
            ) && (
              <Button
                type="button"
                icon="Redo"
                children="Reopen Log"
                styleString="primary"
                onClick={() => updateShowState("showReopen", true)}
              />
            )}
          </div>
        }
        variant={isMobile ? "secondary" : "default"}
        mobileTitle={
          <div className="flex justify-between items-center w-full bg-seocndary-1000">
            <button
              type="button"
              className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
              onClick={navigateBack}
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>

            <button
              type="button"
              className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
              onClick={() => {}}
            >
              <ShareIcon className="w-5 h-5" />
            </button>
          </div>
        }
      />
      <div className="">
        <div className="flex sm:flex-row flex-col sm:justify-between  px-4 border-b border-secondary-1000 bg-primary sm:bg-transparent">
          <div className="flex gap-4 h-full">
            <button
              className={`py-3 px-4 flex items-center gap-2 text-sm border-b-2 ${
                activeTab === "details"
                  ? "border-accent-600 text-secondary-300"
                  : "border-transparent text-secondary-400 hover:text-secondary-300"
              }`}
              onClick={() => {
                setActiveTab("details");
                setShowSpecs(true);
              }}
            >
              <DocumentTextIcon
                className={`w-4 h-4 ${
                  activeTab === "details"
                    ? "text-accent-500"
                    : "text-secondary-400"
                }`}
              />
              Details
            </button>
            <button
              className={`py-2 px-4 flex items-center gap-2 text-sm border-b-2 ${
                activeTab === "attachments"
                  ? "border-accent-600 text-secondary-300"
                  : "border-transparent text-secondary-400 hover:text-secondary-300"
              }`}
              onClick={() => {
                setActiveTab("attachments");
                setShowSpecs(true);
              }}
            >
              <PaperClipIcon
                className={`w-4 h-4 ${
                  activeTab === "attachments"
                    ? "text-accent-500"
                    : "text-secondary-400"
                }`}
              />
              Attachments
            </button>
          </div>
          <div className="hidden sm:flex w-fit">
            <Button
              onClick={() => setShowSpecs(!showSpecs)}
              type="button"
              icon={showSpecs ? "ExpandLess" : "ExpandMore"}
              reverse={true}
              children={showSpecs ? "Hide Details" : "Show Details"}
              styleString="secondary"
            />
          </div>
        </div>
        {showSpecs && (
          <>
            {activeTab === "details" && (
              <div className="flex flex-col sm:flex-row justify-between text-sm p-4 bg-primary sm:bg-transparent">
                <div className=" grid grid-cols-2 gap-y-4 sm:w-1/3 pb-4">
                  <label className="text-secondary-400 font-light flex gap-2 items-center">
                    <CalendarDaysIcon className="w-4 h-4" /> Due Date:
                  </label>
                  <span className="">
                    {formatDate(
                      selectedLogInstance?.date,
                      "MMM DD, yyyy",
                      "utc"
                    )}
                  </span>

                  <label className="text-secondary-400 font-light flex gap-2 items-center">
                    <FlagIcon className="w-4 h-4" /> Status:
                  </label>
                  <span className="">
                    <StatusBadge status={selectedLogInstance?.status} />
                  </span>

                  <label className="text-secondary-400 font-light flex gap-2 items-center">
                    <ShareIcon className="w-4 h-4" /> Source:
                  </label>
                  <span className="">
                    {capitalizeString(selectedLogInstance?.parentTask?.source)}
                  </span>
                </div>

                <div className="flex flex-col gap-1 -mx-4 px-4 border-t-4 sm:border-t-0 pt-4 sm:pt-0 border-secondary-1000 sm:mx-0 sm:w-1/2">
                  <label className="text-secondary-400 font-light flex gap-2 items-center">
                    <DocumentTextIcon className="w-4 h-4" /> Description:
                  </label>
                  <span className="">
                    {selectedLogInstance?.parentTask?.details}
                  </span>
                </div>
              </div>
            )}

            {activeTab === "attachments" && (
              <div className="p-4 bg-primary sm:bg-transparent">
                <div className="text-secondary-300">
                  {selectedLogInstance?.parentTask?.attachments?.length > 0 ? (
                    selectedLogInstance?.parentTask.attachments.map(
                      (attachment) => (
                        <div key={attachment._id} className="flex gap-2 mb-2">
                          <button
                            className="px-3 py-2 bg-secondary-1100 rounded text-sm cursor-pointer hover:bg-secondary-1000 flex items-center gap-2"
                            onClick={() =>
                              attachment.url && handleViewFile(attachment.url)
                            }
                          >
                            <AttachmentOutlined fontSize="small" />
                            {attachment.name}
                          </button>
                        </div>
                      )
                    )
                  ) : (
                    <p className="text-sm italic text-secondary-400">
                      No attachments for this task
                    </p>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <div className="flex sm:hidden w-full justify-center bg-primary py-4">
          {showSpecs ? (
            <button
              type="button"
              className="flex items-center gap-2"
              onClick={() => setShowSpecs(!showSpecs)}
            >
              Hide Details <ChevronUpIcon className="w-4 h-4" />
            </button>
          ) : (
            <button
              type="button"
              className="flex items-center gap-2"
              onClick={() => setShowSpecs(!showSpecs)}
            >
              Show Details <ChevronDownIcon className="w-4 h-4" />
            </button>
          )}
        </div>

        {selectedLogInstance?.status !== "skipped" ? (
          <div className="h-full overflow-auto thin-scrollbar pb-20 sm:pb-0">
            {isMobile ? (
              // Mobile card view
              <div className="flex flex-col gap-4 py-2">
                {normalizeCustomInput.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="flex flex-col bg-primary divide-y-2 divide-secondary-1000"
                  >
                    <div
                      key={rowIndex}
                      className={`p-4 flex w-full justify-between ${
                        rowIndex === activeRowIndex
                          ? "bg-secondary-1200"
                          : "bg-primary"
                      }`}
                    >
                      <div className="flex flex-col gap-1">
                        <span className="text-secondary-300 font-light">
                          {tableKeys[0].dataName}
                        </span>
                        <span className="text-secondary-100">
                          {row[tableKeys[0].originalKey].value}
                        </span>
                      </div>
                      <button
                        type="button"
                        className={`flex items-center"`}
                        onClick={() =>
                          setActiveRowIndex(
                            activeRowIndex === rowIndex ? null : rowIndex
                          )
                        }
                      >
                        <ChevronDownIcon
                          className={`w-6 h-6 ${
                            activeRowIndex === rowIndex ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </div>
                    <div
                      className={`w-full p-4 ${
                        activeRowIndex === rowIndex ? "bg-secondary-1200" : ""
                      }`}
                    >
                      {row[tableKeys[1].originalKey].value}
                    </div>
                    {activeRowIndex === rowIndex && (
                      <div className="w-full flex flex-col divide-y-2 divide-secondary-1000">
                        {/* Display all of the key value pairs from the form here */}
                        {tableKeys
                          .slice(2)
                          .map(({ key, dataName, originalKey }) => (
                            <div
                              key={key}
                              className="flex flex-1 justify-between py-2 px-4"
                            >
                              <span className="text-sm text-secondary-300 font-light break-words">
                                {capitalizeString(decodedString(dataName))}
                              </span>
                              <span className="text-sm text-secondary-100 break-words text-wrap">
                                {renderCellContent(row, originalKey)}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              // Desktop table view
              <div className="flex relative">
                <table className="table-auto w-full border-collapse border border-secondary-1000">
                  <thead>
                    <tr className="sticky top-0 bg-primary">
                      {tableKeys.map(({ key, dataName }, index) => (
                        <th
                          className={`p-2 border border-secondary-1000 text-secondary-500 text-left font-light text-xs  align-top break-words
                            ${
                              index === 0
                                ? "sticky left-0 bg-secondary-1200"
                                : ""
                            }`}
                          key={key}
                          style={{ minWidth: "150px", maxWidth: "150px" }}
                        >
                          {capitalizeString(decodedString(dataName))}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {normalizeCustomInput.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {tableKeys.map(({ key, originalKey }, cellIndex) => (
                          <td
                            className={`border border-secondary-1000 text-left font-light pl-2 text-sm w-fit ${
                              cellIndex === 0
                                ? "sticky left-0 bg-secondary-1200"
                                : ""
                            }`}
                            key={key}
                          >
                            {renderCellContent(row, originalKey)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center bg-secondary-1200 p-4 rounded-lg">
            <p className="text-sm italic">This task is skipped</p>
          </div>
        )}
        <div className="flex sm:hidden gap-4 fixed bottom-0 left-0 right-0 p-4 bg-primary border-t border-secondary-1000">
          {["regional", "organization-admin"].includes(currentUser.access) && (
            <Button
              type="button"
              icon="DeleteOutline"
              children="Delete Log"
              styleString="danger"
              onClick={() => updateShowState("showDelete", true)}
            />
          )}
          {["regional", "organization-admin", "director"].includes(
            currentUser.access
          ) && (
            <Button
              type="button"
              icon="Redo"
              children="Reopen Log"
              styleString="primary"
              onClick={() => updateShowState("showReopen", true)}
            />
          )}
        </div>
      </div>

      <GenericModal
        title="Delete this log instance"
        item={selectedLogInstance}
        handleFunction={() =>
          selectedLogInstance?._id &&
          handleDeleteInstance(selectedLogInstance._id)
        }
        showModal={showState.showDelete}
        setShowModal={() => updateShowState("showDelete", false)}
      />

      <GenericModal
        title="Unskip this task"
        color="accent-500"
        secondaryColor="secondary-1000"
        item={selectedLogInstance}
        handleFunction={() =>
          selectedLogInstance?.parentTask?._id &&
          handleUnskipTask(
            selectedLogInstance.parentTask._id,
            selectedLogInstance.date
          )
        }
        buttonText="Unskip"
        showModal={showState.showUnskip}
        setShowModal={() => updateShowState("showUnskip", false)}
      />

      <GenericModal
        title="Reopen this log instance"
        color="accent-500"
        secondaryColor="secondary-1000"
        item={selectedLogInstance}
        handleFunction={() =>
          selectedLogInstance?._id &&
          handleReopenInstance(selectedLogInstance._id)
        }
        buttonText="Reopen"
        showModal={showState.showReopen}
        setShowModal={() => updateShowState("showReopen", false)}
      />
    </div>
  );
};

export default LogInstance;
