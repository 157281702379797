import { useState } from "react";
import MapList from "../../updatedcomponents/tables/MapList";
import CreateMapButton from "../../updatedcomponents/forms/CreateMapForm";
import FilesList from "../../updatedcomponents/tables/FilesList";
import FileUpload from "../../updatedcomponents/modals/FileUploader";
import KioskList from "../../updatedcomponents/tables/KioskList";
import CreateKioskForm from "../../updatedcomponents/forms/CreateKioskForm";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";

import { useBuilding } from "../../hooks/useBuilding";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import Modal from "../../updatedcomponents/general-ui/Modal";
import Button from "../../updatedcomponents/general/Button";
import classNames from "classnames";
import {
  ArrowLeftIcon,
  ComputerDesktopIcon,
  DocumentTextIcon,
  MapIcon,
  PlusIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
const MyBuilding = () => {
  const {
    kiosks,
    docs,
    handleAddFile,
    selectedKiosk,
    handleShowKiosk,
    handleUpdateKioskStatus,
    showState,
    updateShowState,
    selectedMap,
    handleDeleteMap,
    handleShowMap,
    handleSkipTemplate,
    fileType,
    selectedFile,
    handleDeleteFile,
  } = useBuilding();

  const [showId, setShowId] = useState(0);

  interface PageItem {
    id: number;
    icon: any;
    title: string;
    subtitle: string;
    list: any;
    type: string;
    clickParam?: any;
    clickFunction: (...args: any) => void;
    kiosks?: object[];
    files?: object[];
  }

  const pageItems: PageItem[] = [
    {
      id: 0,
      icon: MapIcon,
      title: "Maps",
      subtitle: "View and manage maps for your building",
      list: MapList,
      type: "map",
      clickParam: true,
      clickFunction: () => handleShowMap(null),
    },
    {
      id: 1,
      icon: ComputerDesktopIcon,
      title: "My Kiosks",
      subtitle: "View and manage kiosks for your building",
      list: KioskList,
      type: "kiosk",
      kiosks: kiosks,
      clickFunction: () => handleShowKiosk(null),
    },
    {
      id: 2,
      icon: DocumentTextIcon,
      title: "My Documents",
      subtitle: "View and manage documents for your building",
      list: FilesList,
      type: "documents",
      files: docs.documents,
      clickFunction: () => handleAddFile("document"),
      clickParam: "document",
    },
    {
      id: 3,
      icon: ShieldExclamationIcon,
      title: "My Policies",
      subtitle: "View and manage policies for your building",
      list: FilesList,
      type: "policies",
      files: docs.policies,
      clickParam: "policy",
      clickFunction: () => handleAddFile("policy"),
    },
  ];

  const selectedItem = pageItems.find((item) => item.id === showId);

  return (
    <div className="flex flex-col h-full bg-secondary-1100">
      <PageHeader
        title={<div className="animate-fade-in-right">My Building</div>}
        subtitle={""}
        mobileTitle={
          <>
            <span className="inline-flex gap-2">My Building</span>
          </>
        }
        children={
          <button
            onClick={() => selectedItem?.clickFunction()}
            className="sm:hidden text-primary bg-secondary p-2.5 h-fit my-auto rounded-md"
          >
            <PlusIcon className="h-5 w-5 " aria-hidden="true" />
          </button>
        }
      />

      <div className="flex w-full justify-between items-center sm:py-2  sm:px-4 bg-primary border-b border-secondary-900">
        <nav className="flex w-fit border sm:rounded-lg bg-secondary-1100 divide-x divide-secondary-1000 overflow-x-auto scrollbar-none scroll-smooth snap-x snap-mandatory">
          {pageItems.map((pageItem, index) => (
            <div className="flex shrink-0 snap-center" key={index}>
              <button
                onClick={() => setShowId(pageItem.id)}
                className={classNames(
                  pageItem.id === showId
                    ? "bg-secondary-1100 text-secondary-100"
                    : "bg-primary text-secondary-100 hover:bg-secondary-1200",
                  "group flex items-center py-4 text-xs sm:text-sm leading-6  px-3 whitespace-nowrap",
                  "transition-all duration-300 ease-in-out",
                  "transform",
                  "hover:shadow-lg"
                )}
                style={{
                  transitionProperty: "all",
                  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                  transitionDuration: "300ms",
                }}
              >
                <span className="flex align-middle mr-2 transition-transform duration-300 ease-in-out transform group-hover:scale-110">
                  {<pageItem.icon className="h-5 w-5" />}
                </span>
                <span className="transition-all duration-300 ease-in-out">
                  {pageItem.title}
                </span>
              </button>
            </div>
          ))}
        </nav>
        <div className="hidden sm:flex">
          <Button
            icon="Add"
            styleString="primary"
            children={`Add ${selectedItem?.type}`}
            onClick={() => selectedItem?.clickFunction()}
          />
        </div>
      </div>

      {selectedItem && (
        <div className="w-full flex flex-1  overflow-y-auto scrollbar-thin">
          {<selectedItem.list {...selectedItem} />}
        </div>
      )}

      <Modal
        title={`Upload ${fileType}`}
        open={showState.showFileAdd}
        setOpen={() => updateShowState("showFileAdd", false)}
        content={<FileUpload />}
      />

      <Modal
        title="Create Map"
        open={showState.showMapCreate}
        setOpen={() => updateShowState("showMapCreate", false)}
        content={<CreateMapButton />}
      />

      <Modal
        title={selectedKiosk ? "Update Kiosk" : "Create Kiosk"}
        open={showState.showKioskAdd}
        setOpen={() => updateShowState("showKioskAdd", false)}
        content={<CreateKioskForm />}
      />

      <GenericModal
        title="Delete Map"
        showModal={showState.showDelete}
        item={selectedMap}
        setShowModal={() => updateShowState("showDelete", false)}
        handleFunction={handleDeleteMap}
        warningText="This will disassociate the map from all recurring tasks."
      />
      <GenericModal
        title="Skip Template"
        showModal={showState.showSkipTemplate}
        item={selectedMap}
        setShowModal={() => updateShowState("showSkipTemplate", false)}
        handleFunction={handleSkipTemplate}
        buttonText="Skip"
      />
      <GenericModal
        title="Deactivate Kiosk"
        showModal={showState.showDeactivate}
        item={selectedKiosk}
        setShowModal={() => updateShowState("showDeactivate", false)}
        handleFunction={() => handleUpdateKioskStatus("inactive")}
        buttonText="Deactivate"
      />
      <GenericModal
        title="Reactivate Kiosk"
        showModal={showState.showReactivate}
        item={selectedKiosk}
        setShowModal={() => updateShowState("showReactivate", false)}
        handleFunction={() => handleUpdateKioskStatus("active")}
        buttonText="Reactivate"
        color="accent-500"
      />
      {selectedFile && (
        <GenericModal
          title={`Delete ${selectedFile.name}`}
          item={selectedFile}
          showModal={showState.showDeleteFile}
          setShowModal={() => updateShowState("showDeleteFile", false)}
          handleFunction={() => handleDeleteFile()}
        />
      )}
    </div>
  );
};

export default MyBuilding;
