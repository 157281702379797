import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  SetURLSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RecurringTask } from "../types/RecurringTask";
import { WorkOrder } from "../types/WorkOrder";
import { TaskInstance } from "../types/TaskInstance";
import { useFacility } from "../hooks/useFacility";
import {
  fetchAttachments,
  fetchLogInstance,
  fetchLogs,
  fetchWorkOrderLogs,
  printLog,
  deleteLogInstance,
  reopenLogInstance,
  unskipTask,
} from "../api/services/LogService";
import { updateSearchParams } from "../utils/ParamUtils";
import { useToast } from "../hooks/useToast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useDeviceWidth } from "../hooks/useDeviceWidth";

export type TabType = "work-orders" | "life-safety" | "prev-maint";

interface LogContextType {
  selectedTab: TabType;
  filters: Record<string, any>;
  page: string;
  pageSize: string;
  totalPages: number;
  isLoading: boolean;
  isPrinting: boolean;
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
  setTotalPages: (pages: number) => void;
  updateTab: (tab: TabType) => void;
  applyFilters: (filters: Record<string, any>) => void;
  applyIndividualFilter: (filter: string, value: string) => void;
  resetFilters: () => void;
  handlePageChange: (newPage: number) => void;
  updateSearchParams: (
    setSearchPArams: SetURLSearchParams,
    newParams: Record<string, string>
  ) => void;
  logs: RecurringTask[];
  workOrders: WorkOrder[];
  selectedLog: RecurringTask | null;
  setSelectedLog: (log: RecurringTask | null) => void;
  handleViewInstance: (log: string) => void;
  showState: {
    showPrintState: boolean;
    showLogState: boolean;
    showWorkOrder: boolean;
    showDelete: boolean;
    showReopen: boolean;
    showUnskip: boolean;
  };
  updateShowState: (modalName: string, show: boolean) => void;
  selectedLogInstance: TaskInstance | null;
  navigateBack: () => void;
  handleViewFile: (link: string) => void;
  handleViewLog: (log: RecurringTask) => void;
  submitPrint: (data: SubmitPrintData) => void;
  handleDeleteInstance: (logId: string) => Promise<void>;
  handleReopenInstance: (logId: string) => Promise<void>;
  handleUnskipTask: (taskId: string, date: string) => Promise<void>;
  handleRefresh: () => void;
  handleSort: (column: string) => void;
  sortColumn: string;
  sortOrder: "asc" | "desc";
}
interface SubmitPrintData {
  startDate: string;
  endDate: string | null;
  logs: { [key: string]: any };
  selected: { [key: string]: boolean };
  fileType: "pdf" | "excel";
}

interface LogProviderProps {
  children: ReactNode;
}

export const LogContext = createContext<LogContextType | undefined>(undefined);

export const LogProvider: React.FC<LogProviderProps> = ({ children }) => {
  const { selectedFacility } = useFacility();
  const { showResponse } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilter, setShowFilter] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showState, setShowState] = useState({
    showPrintState: false,
    showLogState: false,
    showWorkOrder: false,
    showDelete: false,
    showReopen: false,
    showUnskip: false,
  });
  const [isPrinting, setIsPrinting] = useState(false);

  const updateShowState = (modalName: string, show: boolean) => {
    setShowState((prevState) => ({
      ...prevState,
      [modalName]: show,
    }));
  };
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();
  // Get values from URL or use defaults
  const [page, setPage] = useState("1");
  const pageSize = searchParams.get("pageSize") || "20";
  const selectedTab = (searchParams.get("tab") as TabType) || "work-orders";
  const sortColumn = searchParams.get("sortColumn") || "";
  const sortOrder = (searchParams.get("sortOrder") as "asc" | "desc") || "asc";

  // Replace the filters useMemo with useState and useEffect
  const [filters, setFilters] = useState<Record<string, any>>({});

  useEffect(() => {
    const filterParam = searchParams.get("filters");

    if (filterParam) {
      try {
        const parsedFilters = JSON.parse(filterParam);
        setFilters(parsedFilters);
      } catch (error) {
        setFilters({});
      }
    } else {
      setFilters({});
    }
  }, [searchParams]);

  const updateTab = (tab: TabType) => {
    setPage("1");
    updateSearchParams(setSearchParams, {
      tab,
      filters: "{}",
    });
  };

  // Update the applyFilters function
  const applyFilters = (newFilters: Record<string, any>) => {
    setWorkOrders([]);
    setPage("1");
    setFilters(newFilters);
    const searchParamsObj = {
      tab: selectedTab,
      filters: JSON.stringify(newFilters),
    };
    setSearchParams(searchParamsObj);
  };

  const applyIndividualFilter = (key: string, value: string) => {
    const currentFilters = filters || {};
    const updatedFilters = { ...currentFilters, [key]: value };

    if (value === "") {
      delete updatedFilters[key];
    }

    setPage("1");
    updateSearchParams(setSearchParams, {
      tab: selectedTab,
      filters: JSON.stringify(updatedFilters),
    });
  };

  const resetFilters = () => {
    setWorkOrders([]);
    setPage("1");

    setSearchParams({
      tab: selectedTab,
      filters: "{}",
    });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage.toString());
  };

  // State for data
  const [logs, setLogs] = useState<RecurringTask[]>([]);
  const [selectedLog, setSelectedLog] = useState<RecurringTask | null>(null);
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [selectedLogInstance, setSelectedLogInstance] =
    useState<TaskInstance | null>(null);

  const handleViewLog = (log: RecurringTask) => {
    setSelectedLog(log);
    if (!isMobile || log.logEntries.length === 0) {
      updateShowState("showLogState", true);
    }
  };
  const handleViewInstance = async (logId: string) => {
    navigate(`/logs/${logId}`);
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchLogInstanceData = async () => {
        try {
          const logInstance = await fetchLogInstance(id);
          setSelectedLogInstance(logInstance);
        } catch (error) {
          console.error("Error fetching log :", error);
        }
      };
      fetchLogInstanceData();
    }
  }, [id]);

  // Add debounced fetch function
  const debouncedFetch = useCallback(
    debounce((dataType: "work-orders" | "life-safety" | "prev-maint") => {
      if (!selectedFacility) return;

      if (dataType === "work-orders") {
        setIsLoading(true);
        fetchWorkOrderLogs(page.toString(), pageSize, filters, selectedFacility)
          .then(({ workOrders: newWorkOrders, totalPages: newTotalPages }) => {
            setWorkOrders((prevWorkOrders) => {
              if (page !== "1") {
                console.log(
                  "Adding new work orders:",
                  prevWorkOrders,
                  newWorkOrders
                );
                return [...prevWorkOrders, ...newWorkOrders];
              }
              return newWorkOrders;
            });
            setTotalPages(newTotalPages);
          })
          .catch((error) => {
            console.error("Error loading logs", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        fetchLogs(selectedTab, filters, selectedFacility, sortColumn, sortOrder)
          .then((logs) => {
            setLogs(logs);
          })
          .catch((error) => {
            console.error("Error loading logs", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, 300), // 500ms delay
    [selectedFacility, page, pageSize, filters, sortColumn, sortOrder]
  );

  // Update the useEffect to use debouncedFetch
  useEffect(() => {
    if (!selectedFacility) return;
    debouncedFetch(selectedTab);

    // Cleanup function to cancel pending debounced calls
    return () => {
      debouncedFetch.cancel();
    };
  }, [selectedFacility, filters, selectedTab, pageSize, debouncedFetch]);

  // Update the pagination effect
  useEffect(() => {
    if (!selectedFacility || page === "1") return;
    debouncedFetch(selectedTab);

    return () => {
      debouncedFetch.cancel();
    };
  }, [page, debouncedFetch]);

  // Add debounce utility function at the top of the file
  function debounce<T extends (...args: any[]) => any>(func: T, wait: number) {
    let timeout: NodeJS.Timeout;

    const debouncedFunction = (...args: Parameters<T>) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };

    debouncedFunction.cancel = () => {
      clearTimeout(timeout);
    };

    return debouncedFunction;
  }

  const navigateBack = () => {
    setSelectedLogInstance(null);
    navigate(-1);
  };

  const handleViewFile = async (link: string) => {
    try {
      const encodedLink = encodeURIComponent(link);
      await fetchAttachments(encodedLink);
    } catch (error) {
      console.error("Error fetching file:", error);
      showResponse(
        "Error",
        "There was an error fetching the file",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const submitPrint = async (data: SubmitPrintData) => {
    const { startDate, endDate, logs, fileType } = data;
    try {
      setIsPrinting(true);
      await printLog(
        startDate,
        endDate,
        logs,
        selectedTab,
        selectedFacility,
        fileType
      );
      updateShowState("showPrintState", false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPrinting(false);
    }
  };

  const handleDeleteInstance = async (logId: string) => {
    try {
      await deleteLogInstance(logId);
      navigateBack();
      updateShowState("showDelete", false);
      showResponse(
        "Log Instance Deleted",
        "Log instance has been successfully deleted",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      console.error("Error deleting log instance:", error);
      showResponse(
        "Error",
        "There was an error deleting the log instance",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const handleReopenInstance = async (logId: string) => {
    try {
      await reopenLogInstance(logId);

      navigateBack();
      updateShowState("showReopen", false);
      showResponse(
        "Log Instance Reopened",
        "Log instance has been successfully reopened",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      console.error("Error reopening log instance:", error);
      showResponse(
        "Error",
        "There was an error reopening the log instance",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const handleUnskipTask = async (taskId: string, date: string) => {
    try {
      await unskipTask(taskId, date);
      const logInstance = await fetchLogInstance(
        selectedLogInstance?._id || ""
      );
      setSelectedLogInstance(logInstance);
      updateShowState("showUnskip", false);
      showResponse(
        "Task Unskipped",
        "Task has been successfully unskipped",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      console.error("Error unskipping task:", error);
      showResponse(
        "Error",
        "There was an error unskipping the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const handleRefresh = () => {
    setIsLoading(true);
    debouncedFetch(selectedTab);
    setIsLoading(false);
  };

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      // If the same column is clicked again, toggle the sort direction
      updateSearchParams(setSearchParams, {
        sortOrder: sortOrder === "asc" ? "desc" : "asc",
        sortColumn: column,
      });
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending order
      updateSearchParams(setSearchParams, {
        sortOrder: "asc",
        sortColumn: column,
      });
    }
    setPage("1");
  };

  return (
    <LogContext.Provider
      value={{
        selectedTab,
        filters,
        page,
        pageSize,
        totalPages,
        isLoading,
        isPrinting,
        showFilter,
        setShowFilter,
        setTotalPages,
        updateTab,
        applyFilters,
        applyIndividualFilter,
        resetFilters,
        handlePageChange,
        updateSearchParams,
        logs,
        workOrders,
        selectedLog,
        setSelectedLog,
        handleViewInstance,
        showState,
        updateShowState,
        selectedLogInstance,
        navigateBack,
        handleViewFile,
        handleViewLog,
        submitPrint,
        handleDeleteInstance,
        handleReopenInstance,
        handleUnskipTask,
        handleRefresh,
        handleSort,
        sortColumn,
        sortOrder,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};
