import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { Add, Close, UploadFile } from "@mui/icons-material";
import { useBuilding } from "../../hooks/useBuilding";
import Button from "../general/Button";

interface CreateMapFormProps {}

interface MapRow {
  ID: string;
  [key: string]: string;
}

const CreateMapForm: React.FC<CreateMapFormProps> = () => {
  const { updateShowState, selectedMap, onSubmitMap } = useBuilding();

  // Form state
  const [title, setTitle] = useState("");
  const [columns, setColumns] = useState(["ID", "Location"]);
  const [rows, setRows] = useState<MapRow[]>([
    { ID: "ROW-001", Location: "" }
  ]);
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
  
  // Error state
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  // Initialize form with selected map data
  useEffect(() => {
    if (selectedMap) {
      // Loading existing map
      setTitle(selectedMap.name);
      
      if (selectedMap.required === "true") {
        // Template map
        setColumns(["ID", "Location"]);
        setRows([{ ID: generateUniqueId(selectedMap.name, 1), Location: "" }]);
      } 
      else if (selectedMap.fields && selectedMap.fields.length > 0) {
        // Existing map - apply ID standardization here
        const firstField = selectedMap.fields[0];
        const headers = Object.keys(firstField);
        setColumns(headers);
        
        // Create new rows with standardized IDs
        const updatedRows = selectedMap.fields.map((field, index) => {
          // Create a copy of the field to avoid mutating the original
          const updatedField = { ...field };
          // Replace the ID with the new format
          updatedField.ID = generateUniqueId(selectedMap.name, index + 1);
          return updatedField;
        });
        
        setRows(updatedRows);
      }
    } else {
      // New map
      setTitle("");
      setColumns(["ID", "Location"]);
      setRows([{ ID: "ROW-001", Location: "" }]);
    }
  }, [selectedMap]);

  // Helper function to generate unique IDs
  const generateUniqueId = (mapName: string, index: number): string => {
    if (!mapName || mapName.trim() === "") {
      return `ROW-${index < 10 ? `00${index}` : index < 100 ? `0${index}` : index}`;
    }
    
    // Remove vowels and spaces from the name
    let filteredName = mapName.toUpperCase().replace(/[AEIOU\s]/gi, '');
    
    // If removing vowels results in an empty string, use the original string without spaces
    if (filteredName.length === 0) {
      filteredName = mapName.toUpperCase().replace(/\s/g, '');
    }
    
    // Take first 3 letters
    const prefix = filteredName.substring(0, 3);
    
    // If the prefix is less than 3 characters, pad with X
    const paddedPrefix = prefix.padEnd(3, 'X');
    
    // Format the index based on its value
    let suffix;
    if (index < 10) {
      suffix = `00${index}`;
    } else if (index < 100) {
      suffix = `0${index}`;
    } else {
      suffix = index.toString();
    }
    
    return `${paddedPrefix}-${suffix}`;
  };
  
  // Handlers for form interactions
  // Handle title change with dynamic validation
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    
    // Update IDs when title changes (only for new maps)
    if (!selectedMap || selectedMap.required === "true") {
      const updatedRows = rows.map((row, index) => ({
        ...row,
        ID: generateUniqueId(newTitle, index + 1)
      }));
      setRows(updatedRows);
    }
    
    // Clear error if we're fixing an empty title
    if (error && error.includes("title") && newTitle.trim() !== "") {
      setError(null);
    }
  };
  
  // Handle column name change with dynamic validation
  const handleColumnNameChange = (index: number, newName: string) => {
    // Don't change ID column
    if (index === 0) return;
    
    const oldName = columns[index];
    const newColumns = [...columns];
    newColumns[index] = newName;
    setColumns(newColumns);
    
    // Update row property names
    if (oldName !== newName) {
      const updatedRows = rows.map(row => {
        const newRow = { ...row };
        if (oldName in newRow) {
          newRow[newName] = newRow[oldName];
          delete newRow[oldName];
        } else {
          newRow[newName] = "";
        }
        return newRow;
      });
      setRows(updatedRows);
    }
    
    // Clear error if we're fixing an empty column name
    if (error && error.includes("second column") && newName.trim() !== "") {
      setError(null);
    }
  };
  
  // Handle cell change with dynamic validation
  const handleCellChange = (rowIndex: number, column: string, value: string) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [column]: value
    };
    setRows(updatedRows);
    
    // Clear error if we're fixing an empty value in the second column
    if (error && column === columns[1] && value.trim() !== "") {
      // Check if this was the only row with an error or if there are others
      const emptyRows: number[] = [];
      updatedRows.forEach((row, idx) => {
        if (idx !== rowIndex && (!row[columns[1]] || row[columns[1]].trim() === "")) {
          emptyRows.push(idx + 1);
        }
      });
      
      if (emptyRows.length === 0) {
        // All errors fixed
        setError(null);
      } else {
        // Update error message to reflect remaining issues
        setError(`Please fill out all values in the "${columns[1]}" column`);
      }
    }
  };
  
  const addRow = () => {
    const newIndex = rows.length + 1;
    const newRow: MapRow = {
      ID: generateUniqueId(title, newIndex)
    };
    
    // Add empty value for each column
    columns.forEach(column => {
      if (column !== "ID") {
        newRow[column] = "";
      }
    });
    
    setRows([...rows, newRow]);
  };

  const addRowAfter = (index: number) => {
    // Insert position is right after the current index
    const insertIndex = index + 1;
    
    // Create a new row
    const newRow: MapRow = {
      ID: "" // Temporary ID, will be updated in updateRowIds
    };
    
    // Add empty values for each column except ID
    columns.forEach(column => {
      if (column !== "ID") {
        newRow[column] = "";
      }
    });
    
    // Insert the new row at the specified position
    const newRows = [
      ...rows.slice(0, insertIndex),
      newRow,
      ...rows.slice(insertIndex)
    ];
    
    // Update all row IDs to maintain sequence
    const updatedRows = updateRowIds(newRows);
    
    setRows(updatedRows);
  };
  
  const removeRow = (index: number) => {
    if (rows.length <= 1) return;

    const newRows = [...rows];
    newRows.splice(index, 1);

    // Update all rows to ensure IDs are in order
    const updatedRows = updateRowIds(newRows);
    setRows(updatedRows);
  };

  const updateRowIds = (updatedRows: MapRow[]): MapRow[] => {
    return updatedRows.map((row, idx) => ({
      ...row,
      ID: generateUniqueId(title, idx + 1)
    }));
  };
  
  // File upload handling
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const file = files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);
        const wb = XLSX.read(data, { type: 'array' });
        setWorkbook(wb);
        
        const sheets = wb.SheetNames;
        setSheetNames(sheets);
        
        if (sheets.length > 0) {
          // First, set the selected sheet
          setSelectedSheet(sheets[0]);
          
          // Then, process the sheet data
          const sheetName = sheets[0];
          const sheet = wb.Sheets[sheetName];
          
          // First set the title to ensure proper ID generation
          setTitle(sheetName);
          
          // Then process the sheet with the new title
          processSheet(sheet, sheetName);
        }
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    };
    
    reader.readAsArrayBuffer(file);
  };
  
  const handleSheetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sheetName = e.target.value;
    setSelectedSheet(sheetName);
    
    if (workbook && workbook.Sheets[sheetName]) {
      // First set the title to ensure proper ID generation
      setTitle(sheetName);
      
      // Then process the sheet with the new title
      processSheet(workbook.Sheets[sheetName], sheetName);
    }
  };
  
  const processSheet = (sheet: XLSX.WorkSheet, sheetName: string) => {
    if (!sheet) return;
    
    // Extract data from sheet
    const excelRows = XLSX.utils.sheet_to_json<string[]>(sheet, { header: 1 });
    const nonEmptyRows = excelRows.filter(row => 
      row.some(cell => cell !== null && cell !== "")
    );
    
    if (nonEmptyRows.length === 0) return;
    
    // First row = headers
    let headers = nonEmptyRows[0] as string[];
    
    // If headers don't include ID, add it
    if (!headers.includes("ID")) {
      headers = ["ID", ...headers];
    }
    
    // Ensure at least 2 columns
    if (headers.length < 2) {
      headers.push("Location");
    }
    
    // Process data rows - use the current title which was just set
    const dataRows = nonEmptyRows.slice(1).map((row, index) => {
      const rowObj: MapRow = { 
        ID: generateUniqueId(sheetName, index + 1)
      };
      
      // Add data from other columns
      headers.forEach((header, idx) => {
        if (header !== "ID") {
          rowObj[header] = row[idx - 1] ? String(row[idx - 1]) : "";
        }
      });
      
      return rowObj;
    });
    
    setColumns(headers);
    setRows(dataRows.length > 0 ? dataRows : [{ ID: generateUniqueId(sheetName, 1), [headers[1]]: "" }]);
  };
  
  // Simplified validation function
  const validateForm = () => {
    // Check title is provided
    if (!title || title.trim() === "") {
      setError("Map title is required");
      return false;
    }
    
    // Check second column has a name
    if (!columns[1] || columns[1].trim() === "") {
      setError("Please provide a name for the second column");
      return false;
    }
    
    // Check all rows have values in second column
    const hasEmptyValues = rows.some(row => !row[columns[1]] || row[columns[1]].trim() === "");
    
    if (hasEmptyValues) {
      setError(`Please fill out all values in the "${columns[1]}" column`);
      return false;
    }
    
    // No errors
    setError(null);
    return true;
  };
  
  // Form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    setSubmitting(true);
    
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }
    
    // Prepare data for submission
    const formData: {
      collectionTitle: string;
      rows?: MapRow[];
      mapTemplateId?: string;
    } = {
      collectionTitle: title,
      rows: rows
    };
    
    if (selectedMap && selectedMap.required === "true") {
      formData["mapTemplateId"] = selectedMap._id;
    }
    
    // Submit the data
    onSubmitMap(formData);
    setSubmitting(false);
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <div>
        {/* Title input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Map Title</label>
            <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter a map title"
            className={`w-full p-2 border rounded focus:ring-accent-500 focus:border-accent-500 ${
              error && error.includes("title") ? "border-2 border-reds-500" : ""
            }`}
            />
          {error && error.includes("title") && (
            <p className="mt-1 text-sm text-reds-500">{error}</p>
          )}
        </div>
        
        {/* Map data table */}
        <div className="mt-4 flex justify-center items-start align-middle max-h-96 overflow-auto thin-scrollbar">
          <table className="table-auto w-full">
            <thead className="bg-secondary-1100">
              <tr>
                {columns.map((column, colIndex) => (
                  <th
                    key={colIndex}
                    className={`w-1/2 py-2 
                      ${colIndex > 0 ? "border-y" : "border"}
                      ${colIndex === 1 ? "group" : ""}
                      ${colIndex === 1 && error && error.includes("second column") 
                        ? "border-2 border-reds-500" 
                        : "border-secondary-1000"
                      }`}
                  >
                    <input
                      type="text"
                      className="bg-inherit border-none focus:ring-0 p-1 w-full font-medium text-xs"
                      value={column}
                      disabled={colIndex === 0}
                      onChange={(e) => handleColumnNameChange(colIndex, e.target.value)}
                    />
                  </th>
                ))}
                <th className="border-secondary-1000 border-t border-r border-b" />
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr className="h-2" />
                  <tr>
                    {columns.map((column) => (
                      <td 
                        key={`${rowIndex}-${column}`} 
                        className={`w-1/2 border ${
                          column !== "ID" && 
                          error && 
                          error.includes(column) && 
                          (!row[column] || row[column].trim() === "") ? 
                          "border-2 border-reds-500" : ""
                        }`}
                      >
                        <input
                          className="focus:border-secondary-1000 w-full border-none focus:ring-accent-500 ring-0 text-xs"
                          type="text"
                          disabled={column === "ID"}
                          readOnly={column === "ID"}
                          value={row[column] || ""}
                          onChange={(e) => handleCellChange(rowIndex, column, e.target.value)}
                        />
                      </td>
                    ))}
                    <td className="flex items-center">
                      <button
                        type="button"
                        onClick={()=>addRowAfter(rowIndex)}
                        className="p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-accent-400 text-accent-500"
                      >
                        <Add style={{ fontSize: "1rem" }} />
                      </button>
                      <button
                        type="button"
                        onClick={() => removeRow(rowIndex)}
                        disabled={rows.length <= 1}
                        className={`p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-reds-600 ${
                          rows.length <= 1 ? "text-gray-400" : "text-reds-500"
                        }`}
                      >
                        <Close style={{ fontSize: "1rem" }} />
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        
        <button
          type="button"
          onClick={addRow}
          className="my-4 w-full rounded-lg bg-primary px-2 py-1 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-500 hover:bg-secondary-1200"
        >
          + Add Row
        </button>
        
        {/* OR divider */}
        <div className="relative flex items-center justify-center w-full">
          <div className="absolute left-0 right-0 h-0.5 bg-secondary-1000" />
          <span className="relative text-secondary-700 px-2 bg-primary text-sm">
            Or
          </span>
        </div>
        
        {/* File upload */}
        <label
          htmlFor="file-input"
          className="bg-secondary-1100 mx-auto flex align-middle items-center text-sm gap-2 text-center justify-center w-full text-secondary-100 cursor-pointer px-2 py-0.5 m-2 border border-secondary-1000"
        >
          <UploadFile style={{ fontSize: "1rem" }} />
          Import from excel file
          <input
            type="file"
            id="file-input"
            accept=".xls, .xlsx"
            onChange={handleFileUpload}
            className="hidden"
          />
        </label>
        
        {/* Sheet selector */}
        {sheetNames.length > 0 && (
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1">Sheet</label>
            <select
              value={selectedSheet}
              onChange={handleSheetChange}
              className="w-full p-2 border rounded focus:ring-accent-500 focus:border-accent-500"
            >
              {sheetNames.map(sheet => (
                <option key={sheet} value={sheet}>{sheet}</option>
              ))}
            </select>
          </div>
        )}
        
        {/* Error display */}
        {error && (
          <div className="mt-2 text-reds-500 text-sm">
            {error}
          </div>
        )}
        
        {/* Action buttons */}
        <div className="flex gap-2 mt-4">
          <Button
            onClick={() => updateShowState("showMapCreate", false)}
            styleString="secondary"
            children="Cancel"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Save"
            disabled={submitting}
          />
        </div>
      </div>
    </form>
  );
};

export default CreateMapForm;