import React, { Fragment } from "react";
import { capitalizeString } from "../../utils/FormatFunctions";
import { RecurringTask } from "../../types/RecurringTask";
import Table from "../general/Table";
import { RepeatOutlined } from "@mui/icons-material";
import { useLog } from "../../hooks/useLog";
import Spinner from "../general/Spinner";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import {
  ArrowPathRoundedSquareIcon,
  CalendarDaysIcon,
  InboxArrowDownIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import StatusBadge from "../general-ui/StatusBadges";

const LogsList: React.FC = () => {
  const { logs, handleViewLog, isLoading, sortColumn, sortOrder, handleSort } =
    useLog();
  const { isMobile } = useDeviceWidth();
  const columns = [
    {
      label: "Log Title",
      key: "title",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <>
          <span className="py-1 gap-2 text-sm font-medium text-secondary-100 divide-y divide-secondary-1000">
            <div className="flex flex-col sm:flex-row gap-2 p-4 sm:p-0">
              <div className="break-words">
                {capitalizeString(recurringTask.title)}
              </div>
              <span className="font-light text-xs bg-secondary-1000 w-fit rounded px-2 py-1 h-fit text-nowrap">
                {recurringTask.logEntries.length} Item
                {recurringTask.logEntries.length === 1 ? "" : "s"}
              </span>
            </div>
            <div className="flex flex-col sm:hidden">
              <div className="flex justify-between px-4 py-2 font-normal text-sm">
                <span className="inline-flex gap-2 items-center">
                  <InboxArrowDownIcon className="w-4 h-4" />
                  {capitalizeString(recurringTask.category)}
                </span>
                <span className="inline-flex gap-2 items-center">
                  <ShareIcon className="w-4 h-4" />
                  {capitalizeString(recurringTask.source)}
                </span>
              </div>
              <div className="px-4 py-2">
                <span className="flex w-fit gap-2 items-center border border-secondary-1000 bg-secondary-1200 rounded-md px-2 py-1 text-xs font-light">
                  <ArrowPathRoundedSquareIcon className="w-4 h-4" />
                  {capitalizeString(recurringTask.recurrence.frequency)}
                </span>
              </div>
            </div>
          </span>
        </>
      ),
      className: "w-1/2 break-words table-cell",
    },
    {
      label: "Frequency",
      key: "recurrence.frequency",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <div className="py-1 px-2 inline-flex items-center gap-1 bg-secondary-1200 rounded border border-secondary-1000">
          <ArrowPathRoundedSquareIcon className="w-4 h-4" />
          {capitalizeString(recurringTask.recurrence.frequency)}
        </div>
      ),
      className: "hidden sm:table-cell max-w-36",
    },
    {
      label: "Log Type",
      key: "taskType",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.taskType)}</>
      ),
      className: "hidden sm:table-cell max-w-36",
    },
    {
      label: "Data Source",
      key: "source",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.source)}</>
      ),
      className: "hidden sm:table-cell max-w-36",
    },
  ];
  return (
    <Table
      data={logs}
      columns={columns}
      onRowClick={(log) => handleViewLog(log)}
      paginationScheme="infiniteScroll"
      loading={isLoading}
      showHeader={!isMobile}
      sortColumn={sortColumn}
      sortDirection={sortOrder}
      handleSort={handleSort}
      noDataMessage={
        isLoading ? (
          <Spinner size="xl" />
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching logs.
            </span>
          </div>
        )
      }
    />
  );
};

export default LogsList;
