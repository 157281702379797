import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import RecurringTasks from "../../updatedcomponents/tables/RecurringTaskList";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Modal from "../../updatedcomponents/general-ui/Modal";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import RecurringTaskForm from "../../updatedcomponents/forms/RecurringTaskForm";
import RecurringTaskPreview from "../../updatedcomponents/data-display/RecurringTaskPreview";
import { useFacility } from "../../hooks/useFacility";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  RectangleStackIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Selector from "../../updatedcomponents/general-ui/Selector";

const TaskScheduler: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();
  const { currentUser } = useAuth();
  const {
    tasks,
    searchTerm,
    setSearchTerm,
    showModalState,
    updateShowModalState,
    selectedTask,
    handleDeleteTask,
    sortTasks,
    currentSort,
    updateSort,
    isEditing,
    setIsEditing,
  } = useTaskScheduler();

  const { selectedFacility } = useFacility();

  const facility = currentUser?.facility.find(
    (facility) => facility._id === selectedFacility
  );

  const [isActiveSearch, setIsActiveSearch] = useState(false);

  const handleCloseViewModal = () => {
    if (!showModalState.deleteTask) {
      updateShowModalState("viewTask", false);
      setIsEditing(false);
    }
  };

  const handleCreateTask = () => {
    if (isMobile) {
      navigate("/task-scheduler/create");
    } else {
      updateShowModalState("createTask", true);
    }
  };

  const sortOptions = [
    { label: "Title", value: "title" },
    { label: "Frequency", value: "frequency" },
    { label: "Category", value: "category" },
    { label: "Type", value: "taskType" },
  ];

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={
          <>
            <span className="inline-flex gap-2">{facility?.name}</span>
          </>
        }
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Task Scheduler</span>
          </>
        }
        subtitle={
          <div className="flex gap-2 items-center pt-1">
            <span className="text-secondary-100">Total amount:</span>
            <span className="bg-secondary-1000 rounded px-2">
              {tasks.length}
            </span>
          </div>
        }
        buttons={
          <div className="flex gap-4">
            <SearchInput
              searchTerm={searchTerm}
              handleSearchChange={(searchTerm) => {
                setSearchTerm(searchTerm);
              }}
            />
            {["regional", "organization-admin", "director"].includes(
              currentUser?.access
            ) && (
              <Button
                styleString="primary"
                icon="CalendarToday"
                onClick={handleCreateTask}
                children={"Schedule Task"}
              />
            )}
          </div>
        }
        children={
          <button
            onClick={handleCreateTask}
            className="sm:hidden text-primary bg-secondary p-2.5 h-fit my-auto rounded-md"
          >
            <PlusIcon className="h-5 w-5 " aria-hidden="true" />
          </button>
        }
      />

      <div className="flex lg:hidden w-full justify-between items-center bg-primary shadow border-b-2 border-secondary-1000 py-2.5 px-4 text-secondary-400 gap-2">
        <div
          className={`flex gap-4 text-sm ${
            isActiveSearch ? "hidden" : "w-fit"
          }`}
        >
          <Selector
            icon={<RectangleStackIcon className="h-5 w-5" />}
            key={"sortOrder"}
            placeholder="Sort By: None"
            options={sortOptions}
            value={sortOptions.find((option) => option.value === currentSort)}
            onChange={(selectedOption) =>
              updateSort(String(selectedOption.value))
            }
          />
        </div>

        <div
          className={`flex items-center ${
            isActiveSearch ? "w-full" : "w-fit"
          } px-3 border border-secondary-1000 rounded-lg justify-end h-full`}
        >
          <div
            className={`${
              isActiveSearch
                ? "opacity-100 w-full flex justify-between items-center"
                : "opacity-0 w-0 overflow-hidden"
            } flex transition-all duration-200 ease-in-out`}
          >
            <input
              type="text"
              className={`flex flex-row-reverse py-1.5 px-2 border-none focus:outline-none focus:ring-transparent  transition-opacity duration-500 ease-in-out
                ${
                  isActiveSearch
                    ? "opacity-100 w-full items-center"
                    : "opacity-0 w-0"
                }
                `}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder="Search..."
            />

            <button
              className={`transition-opacity duration-200 ease-in-out ${
                isActiveSearch
                  ? "opacity-100 w-fit items-center"
                  : "opacity-0 w-0"
              }`}
              onClick={() => {
                setIsActiveSearch(false);
                setSearchTerm("");
              }}
            >
              <span className="sr-only">Clear search</span>
              <XMarkIcon className="h-5 w-5 text-secondary-400" />
            </button>
          </div>

          <button
            onClick={() => setIsActiveSearch(!isActiveSearch)}
            className={` ${isActiveSearch ? "opacity-0 w-0" : "w-5"}`}
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      <RecurringTasks recurringTasks={tasks} />

      {/* Delete Modal */}
      <GenericModal
        title={"Delete task"}
        item={selectedTask}
        handleFunction={handleDeleteTask}
        showModal={showModalState.deleteTask}
        setShowModal={() => updateShowModalState("deleteTask", false)}
        warningText={"This action will delete all associated log entries and cannot be undone."}
      />

      {/* Create Modal */}
      <Modal
        open={showModalState.createTask}
        setOpen={() => updateShowModalState("createTask", false)}
        title="Schedule Task"
        subtitle="Get started by filling in the information below to schedule a new recurring task"
        content={<RecurringTaskForm />}
      />

      <Modal
        open={showModalState.viewTask}
        setOpen={handleCloseViewModal}
        title={isEditing ? "Update Recurring Task" : "View Recurring Task"}
        subtitle={
          isEditing
            ? "Update the information below to modify this recurring task"
            : "View the details of this recurring task"
        }
        content={
          <div className="flex flex-1 flex-col flex-grow h-full overflow-hidden bg-primary">
            {isEditing ? (
              <RecurringTaskForm />
            ) : (
              <RecurringTaskPreview
                taskData={selectedTask}
                onEdit={() => setIsEditing(true)}
                onDelete={() => updateShowModalState("deleteTask", true)}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default TaskScheduler;
